import moment from 'moment';

export const changeGraphDates = ({ graph, startDate }) => {

  const today = moment()
  const diff = moment(startDate).diff(today, 'days')

  const keys = Object.keys(graph.data.params)
  const firstDay = firstDateInMock(graph)
  const offset = offsetCalc({ min: firstDay, startDate })

  for(let i = 0; i < keys.length; i++) {
    let param = graph.data.params[keys[i]]

    for(let j = 0; j < param.length; j++) {
      let newDate = updateDate({ dt: param[j].dt, offset })
      param[j].dt = moment(newDate).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
    }
  }
  graph.data.lastDate = moment(updateDate({ dt: graph.data.lastDate, offset })).format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')

  // Limito la gráfica de la demo a 31 días desde la startDate
  if (diff === -31) {
    graph.data.prev = false
  } else if ((diff < -31)){
    graph.data = {}
    graph.data.prev = false
  }

  return { ...graph }
}

const offsetCalc = ({ min, startDate }) => {
  return moment(startDate).diff(min, 'days')
}

const updateDate = ({ dt, offset }) => {
  if(offset > 0){
    return moment(dt).utc().add(offset, 'days')
  } else if(offset < 0){
    offset *= -1
    return moment(dt).utc().subtract(offset, 'days')
  }
  return moment(dt).utc().subtract(1, 'days')
}

const firstDateInMock = ( data ) => {
  let firstDate = null
  const keys = Object.keys(data.data.params)

  for(let i = 0; i < keys.length; i++){
    let param = data.data.params[keys[i]]

    for(let j = 0; j < param.length; j++){
      let mockDate = param[j].dt
      if(firstDate === null || moment(mockDate).isBefore( firstDate ) ){
        firstDate = mockDate
      }
    }
  }

  return firstDate
}
