import i18n from 'Core/services/language.service';
import User from 'Auth/models/User';
import CONSTANTS from 'Core/constant';
import { capitalize } from 'Core/utils/utils';
export default {
  formatPrice(value, fix = 4) {
    if(value === undefined || value === null) return;

    let price = value.toFixed(fix);
    price = price.toString();
    price = price.replace(".",",");
    return price;
  },

  getMonthText(month) {
    const monthText = i18n.global.t(`server.months.${parseInt(month,10)}`);
    return capitalize(monthText);
  },

  temperature(value) {
    const user = User.query().first();
    let number;

    if(user.units === CONSTANTS.TEMP_UNITS.CELSIUS) {
      number = Number.parseFloat(value).toFixed(1);
    } else {
      number = value;
    }

    return `${number.toString()}º${user.getUnitAbbr}`
  },

  roundTemp(value){
    const valueNum = parseFloat(value);

    return Math.round(valueNum);
  },

  formatValue(value) {
    if(value === null) return 'N/D';

    if(value === undefined || !Number.isFinite(value)) {
      return '--';
    }
    let output = value.toFixed(2);

    output = output.toString();

    output = output.replace(".",",");

    return output;
  },

  rounded(value) {
    if(typeof value === 'string') {
      value = parseFloat(value);
    }

    return Math.round((value + Number.EPSILON) * 100) / 100;
  },

  percentage(value) {
    return `${value.toString()} %`
  },

  voltage(value) {
    const number = value.toFixed(1);
    return `${number.toString()} V`
  },

  energy(value, units) {
    const number = value.toFixed(2);
    return units?  `${number.toString()} ${units}` : `${number.toString()}`;
  },

  instantPower(value) {
    const number = (value / 1000).toFixed(2);
    return `${number.toString()} kW`;
  },

  machineToWebTemp(value) {
    return `${value/10}ºC`
  },

  minutes(value) {
    return `${value.toString()}m`
  },

  withoutBrackets: value => {
    if (!value) return '';
    let string = value.toString();
    string = string.replaceAll(',',', ');
    return string.replace(/\[.*?\]/g, '');
  },

  hourStringFormat(value) {
    const user = User.query().first();
    return !user.ampm ? zfill(value.toString(),2) : value
  },

  minuteStringFormat(value) {
    return zfill(value.toString(),2);
  },

  AMPMStringFormat(value) {
    return value ? 'AM' : 'PM'
  },

  getHour(value) {
    const hour = zfill(value.hour,2);
    const minutes = zfill(value.minutes,2);

    return `${hour}:${minutes}`
  },
}
