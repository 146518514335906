import ApiService from 'Core/services/api.service';
import ThirdPartyApiService from 'Core/services/3rdp.service';
import UnitsError from 'Units/services/errors.service';
import { hasProp } from 'Core/utils/validate.utils';
import User from 'Auth/models/User';
import Installation from 'Units/models/Installation.model';
import cloud2web from 'Units/interfaces/cloud2web.interface';
import auth from 'Auth/constant';
import i18n from 'Core/services/language.service';
import { getModeStringValue, getModeValue } from 'Units/utils/mode.utils';
import { Device } from 'Units/models/DeviceHierarchy';
// import { getDeviceType } from 'Units/utils/device.utils';
import sanitizeWebserver from 'Units/utils/webserver.utils';
import DemoService from 'Core/services/demo.service';
import BluetoothService from 'Core/services/bluetooth.service';
import modelToBle from 'Units/interfaces/modelToBle';
import bleToModel from 'Units/interfaces/bleToModel.interface';
import { getScheduleNumber, getAlias } from 'Units/utils/utils';
import unitsConstants from 'Units/constant';
import coreConstants from 'Core/constant';
import { poll } from 'Core/utils/utils'
import AppError from 'Core/services/errors.service';
import store from 'Core/store/store';

const constants = {...unitsConstants, ...coreConstants};


const UnitsService = {
  /**
   * Comprueba si los datos de un webserver son válidos cuando se reciben por primera vez
   *
   * @param {String} webserverID - Id del grupo
   * @param {String} webserverName - Nombre del webserver
   * @param {Object} webserverData - Datos del webserver
   * @return {Webserver} - Datos formateados y validados del webserver
   */
  async validateWebserver(macBLE, webserverName, webserverData) {
    let webserver;
    try {
       webserver = sanitizeWebserver(macBLE, webserverData, webserverName);
       console.log("VALIDATE WS", webserver);
    } catch (error) {
      console.log(error);
      throw new UnitsError(error);
    }

    console.log(webserver);

    webserver.macBLE = macBLE;

    /**
     * Compruebo que tiene los datos mínimos
     */
    if (!webserver || !webserver.id || !webserver.mac) {
      console.log('Error al validar los datos del webserver con mac BLE', macBLE);
      return null;
    }

    /**
     * Compruebo si el webserver está asociado
     * 1- Todo webserver que no esté asociado tiene el acceso permitido a cualquier usuario
     */
    try {
      // Sólo si nos llega el parámetro link_check en "extrainfo" a 0 obviamos la petición linkstatus
      if(webserver.link_check !== 0) {
        const response = await ApiService.get(`devices/ws/${webserver.mac}/linkstatus`);
        // GroupService.isDeviceAssociated({ mac: webserver.mac });

        if (response.data && response.data.link_status) {
          webserver.isDeviceAssociated = response.data.link_status.isAssociated;
          webserver.isUserAllow = true;
          if (webserver.isDeviceAssociated) {
            webserver.isUserAllow = response.data.link_status.allowed;
          }
        }
        console.log('After linkstatus request: ', webserver);
      }
      return webserver;

    }catch(error) {
      throw new UnitsError(error);
    }
  },

  async linkStatus(webserverID){
    const webserver = {};
    try {
      const response = await ApiService.get(`devices/ws/${webserverID}/linkstatus`);

      // console.log("link status data", response.data);

      if (response.data && response.data.link_status) {
        webserver.isDeviceAssociated = response.data.link_status.isAssociated;
        webserver.isUserAllow = true;
        if (webserver.isDeviceAssociated) {
          webserver.isUserAllow = response.data.link_status.allowed;
        }
        if(response.data.status) {
          webserver.isConnected = response.data.status.isConnected;
        }
      }

      console.log('After linkstatus request: ', webserver);

      return webserver;

    } catch (error) {
      console.log("Link status error Service",error);
      throw new UnitsError(error)
    }
  },

  async checkPin(webserverID, pin) {
    try {
      //
      // Petición al backend para crear la installation
      //
     await ApiService.get(`devices/ws/${webserverID}/checkpin?pin=${pin}`);

    } catch ( error ) {
      if (error.response && error.response.status === 429) {
        throw new UnitsError('maxPinRetries')
      } else {
        throw new UnitsError('notAuthorized');
      }
    }
  },

  async setAlias(webserverID, alias, installationID, pin) {
    try {
      //
      // Petición al backend para crear la installation
      //
      let queryString = ''
      if (installationID) {
        queryString = `installationId=${installationID}`
      }

      if (pin) {
        queryString = `pin=${pin}`
      }

      await ApiService.patch(`devices/ws/${webserverID}/alias?${queryString}`, { alias });

    } catch ( error ) {
      throw new UnitsError('notAuthorized');
    }
  },

  /**
   *  Creates a new installation associated to a user. Webserver must be connected to the cloud
   *
   * @param {String} mac - MAC Address of the Webserver
   * @param {String} name - Metainfo. The name of the installation
   * @param {Object} coords - lat y lng in number
   * @param {Number} icon - Icon associated to the installation
   * @param {Number} color - Color associated to the installation
   */
  async addNewInstallation(mac, name, googlePlaceId, icon = 1, color = 1){

    const user = User.query().first();

    //
    // preparo los datos
    //
    const data = {
      mac,
      location: {
        google_place_id: googlePlaceId
      },
      meta: {
        name,
        icon,
        color
      }
    }

    try {
      //
      // Petición al backend para crear la installation
      //
      const response = await ApiService.post(`installations?lang=${user.lang}`, data);

      // console.log(response);

      return response;

    } catch ( error ) {
      // console.log(error);
      throw new UnitsError(error)
    }
  },

  async addWebserver(installationID, mac) {
    //
    // Preparo el dato para el backend
    //
    const data = { mac }
    //
    // Intento realizar la petición
    //
    try {

      await ApiService.post(`installations/${installationID}/ws`,data);

      return true;

    } catch ( error ) {
      // console.log(error);
      throw new UnitsError(error)
    }
  },

  async deleteWebserver(installationID, webserverID){
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    try{

      const response = await ApiService.delete(`${admin}installations/${installationID}/ws/${webserverID}`);

      return response;

    } catch( error ){
      throw new UnitsError(error);
    }
  },

  /**
   * Libera un webserver de una asociación con otro usuario. Si no es admin debe enviar el pin
   *
   * @param {string} webserverID
   * @param {string} pin
   */
  async freeWebserver(webserverID, pin = null){
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    let query = `${admin}devices/ws/${webserverID}/free`;

    if(pin !== null && pin !== '') query += `?pin=${pin}`;

    try{

      await ApiService.delete(query);

    } catch( error ){
      throw new UnitsError(error);
    }
  },

  /**
   * Obtiene todas las instalaciones de un usuario
   *
   * @param {String} filterParam - Opcional: filtro a aplicar por 'city', 'country', 'mac' o 'name'
   * @param {String} filterValue - Req filterParam: El valor del filtro aplicado en el parámentro filterParam
   * @param {String} lang - Opcional: filtro por idioma 'es', 'en', 'de', 'it', 'pt', 'fr'
   * @param {String} items - Opcional: Número de items a retornar (min: 1, max: 10)
   * @param {String} page - Opcional: Page to return of items
   * @param {String} role - Opcional: Si es "admin" podrá obtener instalaciones de toda la base de datos
   * @return {Object} - Datos de la instalación parseados y validados
   * @throws {badParams} - Los datos enviados no son válidos
   */
  async getInstallations(filterParam, filterValue, lang, items, page, role) {
    //
    // Preparo la url de la petición según los parámetros recibidos
    //
    let requestUrl = '';
    if(role === auth.ROLE.SUPER_ADMIN) {
      requestUrl = 'admin/installations'
    } else {
      requestUrl = 'installations';
    }
    //
    // Los parámetros son opcionales para filtrar o paginar. Voy construyendo la url según los que reciba
    //
    if(filterParam || filterValue|| lang || items || page) requestUrl += "?";
    if(filterParam && filterValue)
      requestUrl += `filterParam=${filterParam}&filterValue=${filterValue}`;
    if(lang) requestUrl += `&lang=${lang}`;
    if(items) requestUrl += `&items=${items}`;
    if(page) requestUrl += `&page=${page}`;

    //
    // Realizo la petición y transformo los datos
    //
    try {
      const installations = [];
      let response;
      if (store.getters.getIsDemo) {
        response = await DemoService.getInstallations(filterParam, filterValue);
      } else {
        response = await ApiService.get(requestUrl);
      }
      let position = 0;
      //
      // Si tengo instalaciones pendientes las agrego al usuario
      //
      // if(response.data.pendingInstallations > 0) {
      if(response.data) {
        const user = User.query().first();
        if(user) {
          user.pendingInstallations = response.data.pendingInstallations;
          user.hasInstallations = response.data.hasInstallations;
          user.totalInstallations = response.data.total;
          user.updateUser();
        }
      }
      // }
      if(response.data && response.data.installations){
        response.data.installations.forEach(installationData => {
          //
          // Transformo los datos al formato de nuestro modelo
          //
          const installation = {
            id: installationData.installation_id,
            name: installationData.name,
            icon: installationData.icon,
            color: installationData.color ? installationData.color : 1,
            locationID: installationData.location_id ? installationData.location_id : null,
            city: (installationData.location_text && installationData.location_text.city) ? installationData.location_text.city[i18n.global.locale.value] : null,
            country: (installationData.location_text && installationData.location_text.country) ? installationData.location_text.country[i18n.global.locale.value] : null,
            // isAdmin: installationData.access_type === CONSTANTS.USER_TYPE.ADMIN,
            access_type: installationData.access_type,
            position: position++,
          };

          if(store.getters.getIsDemo) installation.timezone = installationData.timezone;

          installations.push(installation);
        });

        // console.log("INSTALLATIONS", installations);
      }

      return installations;
    } catch (error) {
      console.log(error);
      throw new UnitsError(error);
    }
  },

  async getAllInstallationsList() {
    try{
      let response;

      if(!store.getters.getIsDemo){
        response = await ApiService.get(`installations/list`);
      } else {
        response = await DemoService.getInstallations();
      }

      let installationsList;

      if(response && response.data) {
        installationsList = response.data.installations;
      }

      return installationsList;
    } catch (error) {
      throw new UnitsError(error);
    }
  },

  async getNotConfirmedInstallations() {
    try{
      const response = await ApiService.get(`installations/notconfirmed`);

      let notConfirmedInstallations;

      if(response && response.data) {
        notConfirmedInstallations = response.data.installations;
      }

      return notConfirmedInstallations;
    } catch (error) {
      throw new UnitsError(error);
    }

  },

  /**
   * Obtiene los datos y estructura de una instalación
   *
   * @param installationID - Identificador de la installación
   * @return {Object[Installation]} - Datos de la estructura de la instalación y sus zonas parseados y validados
   */
  async getInstallation(installationID, view = null) {
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //

    const user = User.query().first();
    let installation = Installation.find(installationID);

    const admin = user?.admin_mode === true ? 'admin/' : '';

    try {
      let response;
      let locationData;
      let weatherData;

      if (store.getters.getIsDemo) {
        response = await DemoService.getInstallation(installationID);
        locationData = await DemoService.getLocation(installationID);
        const weather = await DemoService.getWeather(installationID);
        const data = cloud2web.getWeatherData(weather);
        weatherData = cloud2web.formattedWeatherData(data, user.units, user.lang);
      } else if (view) {
        response = await ApiService.get(`${admin}installations/${installationID}?view=${view}`);
      } else {
        let p1; let p2; let p3;
        let result = [];

        // Corrige error al actualizar web de una instalación en modo admin
        if (admin) {
          // pedimos la instalación para obtener el locationId
          p1 = await ApiService.get(`${admin}installations/${installationID}`);
          // Asigno el locationId al objeto installation para construir el objeto locationData con el mismo valor en la key location_id
          installation = {
            locationId: p1 && p1.data ? p1.data.location_id : null
          }
          // Pedimos las otras peticiones en paralelo
          p2 = ApiService.get(`${admin}installations/location/${installation.locationId}?lang=${user.lang}`)
          .catch(err => console.log('Error en location', err)); // Capturo este error para no bloquear la app por falta de location
          p3 = ApiService.get(`weather/${installation.locationId}?aq=true`)
          .catch(err => console.log("Error obteniendo clima", err));

          result = await Promise.all([p2, p3]);
          // Añado la primera petición aislada para que funcione igual que si se hacen las 3 peticiones en paralelo
          result.splice(0, 0, p1)

          // En web si recargo la web en una instalación que no esté en la primera página, entonces no tendré esa instalación en el modelo,
          // para solucionarlo no se piden las peticiones en paralelo, y se hace secuencial para obtener los datos de la installación en este caso
        } else if (!installation) {
          const inst1 = await ApiService.get(`${admin}installations/${installationID}`)
          result.push(inst1)

          const locationID = inst1?.data?.location_id
          installation = {
            locationID
          }

          const inst2 = await ApiService.get(`${admin}installations/location/${installation.locationID}?lang=${user.lang}`)
                .catch(err => console.log('Error en location', err)); // Capturo este error para no bloquear la app por falta de location
          result.push(inst2.data)

          const inst3 = await ApiService.get(`weather/${installation.locationID}?aq=true`)
                .catch(err => console.log("Error obteniendo clima", err));
          result.push(inst3.data)
        } else {
          p1 = ApiService.get(`${admin}installations/${installationID}`);
          p2 = ApiService.get(`${admin}installations/location/${installation.locationID}?lang=${user.lang}`)
                .catch(err => console.log('Error en location', err)); // Capturo este error para no bloquear la app por falta de location
          p3 = ApiService.get(`weather/${installation.locationID}?aq=true`)
                .catch(err => console.log("Error obteniendo clima", err));
          result = await Promise.all([p1, p2, p3]);
        }

        response = result[0];
        //
        // Si falla la petición de locationData ignoramos esta parte y cargamos la instalación sin esa información
        //
        if(result[1] !== undefined && result[1].data){
          locationData = {
            timezone: result[1].data.timezoneId,
            countryCode: result[1].data.country_code,
            city: result[1].data.text.city[i18n.global.locale.value],
            country: result[1].data.text.country[i18n.global.locale.value],
            coords: result[1].data.coords,
            locationID: installation.locationID,
            priceRegion: result[1].data.price_region,
          }
        }

        if(result[2] !== undefined && result[2].data) {
          const data = cloud2web.getWeatherData(result[2].data);
          weatherData = cloud2web.formattedWeatherData(data, user.units, user.lang);
        }


      }

        // locationData = await this.getLocation(response.data.location_id);


      const data = cloud2web.getInstallationData(installationID, response, locationData, user, weatherData);

      return data;

    } catch (error) {
      console.log(error);
      throw new UnitsError(error);
    }
  },

  async getLocation(locationID){
    try {
      //
      // Obtenemos la ruta "admin" en caso de modo admin activo
      //
      const user = User.query().first();

      const admin = user?.admin_mode === true ? 'admin/' : '';

      const response = await ApiService.get(`${admin}installations/location/${locationID}?lang=${user.lang}`);
      const data = {
        timezone: response.data.timezoneId,
        countryCode: response.data.country_code,
        city: response.data.text.city[i18n.global.locale.value],
        country: response.data.text.country[i18n.global.locale.value],
        coords: response.data.coords,
        locationID
      }
      return data
    } catch( error ) {
      throw new UnitsError(error);
    }
  },

  /**
   * Activar o desactivar las programaciones de calendario o semanales
   *
   * @param {String} installationID - ID de instalación
   * @param {boolean} value - true/false para habilitar o deshabilitar las programaciones
   */
  async setSchedulesActivatedStatus(installationID, value){
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    const requestData = {
      activated: value
    }

    try{
      await ApiService.patch(`${admin}installations/${installationID}/schedules/activate`,requestData);
    } catch (error) {
      console.log(error);
      throw new UnitsError(error);
    }
  },

  /**
   *
   * @param {String} installationID Id instalación
   * @param {String} param Nombre del parámetro a modificar. Si es google_place_id será una petición de location
   * @param {String, Number} value Valor del parámetro.
   */
  async editInstallation(installationID, param, value){
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    const requestData = {};
    requestData[param] = value;

    let locationData = {}
    try {
      if (!store.getters.getIsDemo) {
        if( param === 'google_place_id'){
          const  response = await ApiService.patch(`${admin}installations/${installationID}/location?lang=${user.lang}`, requestData);
          locationData = {
            timezone: response.data.timezoneId,
            countryCode: response.data.country_code,
            city: response.data.text.city[i18n.global.locale.value],
            country: response.data.text.country[i18n.global.locale.value],
            coords: response.data.coords,
            locationID: response.data._id,
            priceRegion: response.data.price_region,
          }
          // Actualizo el dato del clima en la nueva ubicación
          const responseData = await this.updateWeather(response, locationData, user)

          if (responseData) {
            locationData.ext_temp = responseData.actualHour.temp[user.units];
            locationData.weather = responseData.actualHour.weather[0].id;
          }

        } else {
          await ApiService.patch(`${admin}installations/${installationID}`, requestData);
        }
      }

      return locationData;


    } catch (error) {
      throw new UnitsError(error);
    }
  },

  async updateWeather(response) {
    let res = false
    return poll({
      fn: async () => {
        res = await ApiService.get(`weather/${response.data._id}`);
      },
      validate: () => {
        if (res) {
          return true
        }
        return false
     },
      interval: 1000,
      maxAttemps: 60
    })
    .then( async () => {
      try {
        if(res) {
          return cloud2web.getWeatherData(res.data);
        }
        return false
      } catch(err) {
        return err
      }
    })
    .catch( err => {
      console.log("Error obteniendo los datos climáticos: ", err);
      throw new AppError(err)
    })
  },

  async setPositionInstallation(installationID, position){
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    try{
      await ApiService.patch(`${admin}installations/${installationID}/position/${position}`)

      return true;

    } catch(error) {
      console.log(error);
      throw new UnitsError(error);
    }
  },

  async setPositionGroup(installationID, groupID, position){
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    try{
      await ApiService.patch(`${admin}installations/${installationID}/group/${groupID}/position/${position}`)

      return true;

    } catch(error) {
      console.log(error);
      throw new UnitsError(error);
    }
  },

  async setPositionDevice(installationID, groupID, deviceID, position){
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    try{
      const response = await ApiService.patch(`${admin}installations/${installationID}/group/${groupID}/device/${deviceID}/position/${position}`)

      const data = cloud2web.getInstallationData(installationID, response, null, user);

      return data;

    } catch(error) {
      console.log(error);
      throw new UnitsError(error);
    }
  },

  async moveDeviceToGroup(installationID, oldGroupID, newGroupId, deviceID, groupName = undefined, position = undefined){
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    let requestData = {};

    if(groupName === undefined ) {
      requestData = {
        groupId: newGroupId
      }

      if(position !== undefined) {
        requestData.position = position;
      }

    } else {
      requestData = {
        newGroup: {
          name: groupName
        }
      }
    }


    try{
      const response = await ApiService.patch(`${admin}installations/${installationID}/group/${oldGroupID}/device/${deviceID}/move`, requestData);

      const data = cloud2web.getInstallationData(installationID, response, null, user);

      return data;

    } catch(error) {
      console.log(error);
      throw new UnitsError(error);
    }
  },

  async deleteInstallation(installationID) {
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    try{
      await ApiService.delete(`${admin}installations/${installationID}`);

      return true;

    } catch( error ){
      throw new UnitsError(error);
    }
  },

  async leaveInstallation(installationID, userID) {
    try{
      await ApiService.delete(`installations/${installationID}/user/${userID}`);

      return true;

    } catch( error ){
      throw new UnitsError(error);
    }
  },

  async editGroup(installationID, groupID, param, value) {
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    const requestData = {};
    requestData[param] = value;
    try {
      if (!store.getters.getIsDemo) {
        await ApiService.patch(`${admin}installations/${installationID}/group/${groupID}`, requestData);
      }

      return true;
    } catch (error) {
      throw new UnitsError(error);
    }
  },

  /**
   * Obtiene todos los webservers perteneciente a una instalación
   *
   * @param {String} installationID - id de la instalación
   * @throws {badParams} - Los datos enviados no son válidos
   * @throws {notAuthorized} - Usuario no autorizado
   * @throws {socketTimeout} - Tiempo de respuesta agotado
   */
  async getWebservers({installationID, page = 0, items = constants.PAGINATION.WEBSERVERS.items_for_page,
    param = null, value = null, aliasPrefix = null, type = null}) {
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    let requestUrl = `${admin}devices/wwss?installation_id=${installationID}`;

    if(param !== null) requestUrl = `${requestUrl}&filterParams[${param}]=${value}`;

    if(type) requestUrl = `${requestUrl}&filterParams[wsType]=${type}`;

    if (aliasPrefix) requestUrl = `${requestUrl}&aliasPrefix=${aliasPrefix}`;

    if(items) requestUrl = `${requestUrl}&items=${items}`;

    if(page) requestUrl = `${requestUrl}&page=${page}`;

    try {
      const response = await ApiService.request(requestUrl, {}, 'get', constants.CONNECT.APP.AZCLOUD_API_V2_URL);

      //
      // obtengo el array de webservers de la respuesta
      //
      const wwssData = response.data ? response.data.wwss : null;
      const totalWebservers = response.data ? response.data.total : 0;
      //
      // Inicializo el array para parsear al formato que espero en el modelo
      //
      const webservers = [];
      //
      // Parseamos los datos al modelo
      //
      wwssData.forEach( ws => {
        const wsModelData = {
          id: ws._id,
          installation_id: installationID,
          mac: ws.config.mac,
          pin: ws.config.pin,
          local_ipv4: ws.status.local_ipv4,
          firmware: ws.config.ws_fw,
          lmachine: ws.config.lmachine_fw,
          type: ws.ws_type,
          alias: getAlias(ws.config.alias, ws.config.mac),
          ws_hw: ws.config.ws_hw,
          aidoo_group: ws.config.wsmmgroup,
          aidooDevices: ws.devices,
          isConnected: ws.status.isConnected
        }
        webservers.push(wsModelData);
      });

      return {
        webservers,
        totalWebservers
      };

    } catch (error){
      throw new UnitsError(error);
    }
  },

  /**
   * Obtiene el estado de un Webserver de sólo lectura, para mostrar en la ventana Settings dentro de una zona
   *
   * @param {String} installationID
   * @param {String} webserverID
   */
  async getWebserverStatus({installationID, webserverID, devices = false} = undefined) {
 //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    let apiUrl = `${admin}devices/ws/${webserverID}/status?installation_id=${installationID}`;

    if(devices) {
      apiUrl += `&devices=1`;
    }

    try {
      let response;

      if (store.getters.getIsDemo) {
        response = await DemoService.getStatus(installationID);
      } else {
        //
        // Obtenemos la ruta "admin" en caso de modo admin activo
        //
        const user = User.query().first();

        const admin = user?.admin_mode === true ? 'admin/' : '';

        let apiUrl = `${admin}devices/ws/${webserverID}/status?installation_id=${installationID}`;

        if(devices) {
          apiUrl += `&devices=1`;
        }

        response = await ApiService.get(apiUrl);
      }

      // console.log("Status WS", response);
      //
      // obtengo el array de webservers de la respuesta
      //
      const wwssData = response.data;

      const data = await cloud2web.getWebserverData({installationID, webserverID, wwssData, devices})

      return data;

    } catch (error){
      throw new UnitsError(error);
    }
  },

  /**
   * Devuelve el estado de una zona
   *
   * @return {Object[Group]} - Datos de las zonas de una instalación parseados y validados
   */
  async getZonesStatus(installationID, userUnits) {
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    try {
      const response = await ApiService.get(`${admin}installations/${installationID}/status`);
      const devices = response.data;

      devices.map(device => {
        //
        // Creo la propiedad id para poder actualizar los modelos automaticamente
        //
        device.id = device.device_id;
        const deviceType = device.device_type;

        //
        // Transformo los datos
        //
        // - Paso los datos de la propiedad Status a la raiz del objeto
        // - Obtengo las temperaturas en la unidad del usuario
        //
        Object.keys(device.status).forEach(key => {
          //
          // Paso los datos del status a la ráiz
          //
          if (device.status[key] !== null && hasProp(device.status[key], 'celsius')) {
            device[key] = device.status[key][userUnits];
          } else if (key === 'mode') {
            device.mode = getModeStringValue(device.status[key], deviceType);
          } else if (key === 'mode_available') {
            device.mode_available = device.status.mode_available.map(modeID => getModeStringValue(modeID, deviceType));
          } else {
            device[key] = device.status[key];
          }
          device.units = userUnits;
        });

        return device;
      });

      return devices;
    } catch (error) {
      throw new UnitsError(error);
    }
  },

  /**
   * Obtiene la localización de una instalación
   *
   * @return {Object[Group]} - Datos del grupo parseados y validados
   */
  // getInstallationLocation(installationID) {
  //   return new Promise(async (resolve, reject) => {
  //     try {
  //       const data = { lang: i18n.global.locale.value };
  //       const response = await ApiService.get(`installations/${installationID}/location`, data);
  //       const location = await validateLocation(response.data);

  //       log.success('UnitsService/getInstallationLocation');
  //       resolve(location);
  //     } catch (error) {
  //       reject(new UnitsError(error));
  //     }
  //   });
  // },

  async setDeviceButtonsOrder(deviceID, installationID, groupID, deviceType, buttonsOrder, numVisibleButtons, allZones = false) {
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';
    let requestUrl = '';

    const requestData = {
      buttonsOrder,
      numVisibleButtons
    }

    if(allZones === true) {
      requestUrl = `${admin}installations/${installationID}/buttons`;
      requestData.deviceType = deviceType;
    } else {
      requestUrl = `${admin}installations/${installationID}/group/${groupID}/device/${deviceID}/buttons`;
    }


    try {
      const response = await ApiService.put(requestUrl, requestData);
      const devicesUpdated = cloud2web.updateButtonsOrder(response.data);
      return devicesUpdated;
    } catch (error) {
      throw new UnitsError(error);
    }
  },

  /**
   * Define el estado de una zona
   *
   * @param {String} zoneID - ID de la zona
   * @param {Object} data - Datos a actualizar
   * @throws {badParams} - Los datos enviados no son válidos
   * @throws {outOfRange} - Los datos enviados salen fuera de rango permitido
   * @throws {userNotExist} - El usuario que hace la petición no es correcto
   */
  async setDeviceStatus(deviceID, installationID, param, value, opts) {
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    const requestData = {
      param,
      value: param !== 'mode' ? value : getModeValue(value),
      installation_id: installationID,
    };

    //
    // Si recibimos opciones en el data las agregamos a la petición
    //
    if (opts) {
      requestData.opts = opts;
    }

    try {

      await ApiService.patch(`${admin}devices/${deviceID}`, requestData);

      return true;
    } catch (error) {
      throw new UnitsError(error);
    }
  },

  /**
   * Define el estado de una zona
   *
   * @param {String} zoneID - ID de la zona
   * @param {Object} data - Datos a actualizar
   * @throws {badParams} - Los datos enviados no son válidos
   * @throws {outOfRange} - Los datos enviados salen fuera de rango permitido
   * @throws {userNotExist} - El usuario que hace la petición no es correcto
   */
  async setDeviceStatusV2(deviceID, installationID, params, opts) {
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    // Si el parámetro mode es enviado, lo transformamos a su valor numérico
    if(hasProp(params, 'mode') && params.mode !== undefined){
      params.mode = getModeValue(params.mode);
    }

    const requestData = {
      params,
      installationId: installationID,
    };

    //
    // Si recibimos opciones en el data las agregamos a la petición
    //
    if (opts) {
      requestData.opts = opts;
    }

    try {
      await ApiService.request(`${admin}devices/${deviceID}`, requestData, 'patch', constants.CONNECT.APP.AZCLOUD_API_V2_URL);

      return true;
    } catch (error) {
      throw new UnitsError(error);
    }
  },

  /**
   * Actualiza un parámetro de dispositivo asociado con meta información de la instalación en la Cloud
   * Ejemplos (nombre del dispositivo, icono, imagen, etc. )
   *
   * @param {String} deviceID
   * @param {String} installationID
   * @param {String} groupID
   * @param {String} param
   * @param {String} value
   * @throws {badParams} - Los datos enviados no son válidos
   * @throws {notAuthorized} - Usuario no autorizado a hacer cambios en el grupo
   * @throws {userNotIncluded} - El usuario que hace la petición no pertenece a la instalación
   * @throws {groupNotFound} - La id de grupo no partenece a la instalación
   * @throws {deviceNotFound} - La id de dispositivo no partenece a la instalación
   */
  async setDeviceMeta(deviceID, installationID, groupID, param, value){
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    try {
      const dataParam = {};

      dataParam[param] = value;
      if (!store.getters.getIsDemo) {
        await ApiService.patch(`${admin}installations/${installationID}/group/${groupID}/device/${deviceID}`, dataParam);
      }

      return true;
    } catch (error) {
      throw new UnitsError(error);
    }
  },

  /**
   * Actualiza el estado de un grupo
   *
   * @param {String} installationID - ID de la instalación a la que pertenece
   * @param {String} groupID - ID del grupo a actualizar
   * @param {String} data - La acción a realizar en el grupo
   * @throws {badParams} - Los datos enviados no son válidos
   * @throws {notAuthorized} - Usuario no autorizado a hacer cambios en el grupo
   * @throws {userNotIncluded} - El usuario que hace la petición no pertenece a la instalación
   * @throws {groupNotFound} - La id de grupo no partenece a la instalación
   */
    async setGroupStatus({installationID, groupID, data, groupTabSelected = null, isInstallation = false} ) {
      // Obtenemos la ruta "admin" en caso de modo admin activo
      const user = User.query().first();
      const admin = user?.admin_mode === true ? 'admin/' : '';
      const deviceTypes = constants.DEVICES_TYPES_TO_BROADCAST[groupTabSelected] || [];

      try {
        if(!isInstallation) {
          let route = `${admin}installations/${installationID}/group/${groupID}`;
          if(Array.isArray(deviceTypes) && deviceTypes.length > 0) {
            deviceTypes.forEach((deviceType, index) => {
              route += `${index === 0? '?' : '&'}deviceTypes[]=${deviceType}`
            })
          }
          await ApiService.put(route, data);
        } else {
          await ApiService.put(`${admin}installations/${installationID}`, data);
        }
      } catch( error ) {
        throw new UnitsError(error);
      }

    },

    /**
   * Actualiza la configuración de usuario de un grupo en Installation Settings
   *
   * @param {String} installationID - ID de la instalación a la que pertenece
   * @param {String} groupID - ID del grupo a actualizar
   * @param {String} param - Parámetro a actualizar
   * @param {String} value - Valor a actualizar
   * @throws {badParams} - Los datos enviados no son válidos
   * @throws {notAuthorized} - Usuario no autorizado a hacer cambios en el grupo
   * @throws {userNotIncluded} - El usuario que hace la petición no pertenece a la instalación
   * @throws {groupNotFound} - La id de grupo no partenece a la instalación
   */
    async setGroupUserConf(installationID, groupID, param, value) {
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    const data = {
      param,
      value
    }
    // console.log("Datos set group status", data);

      try{
        ApiService.patch(`${admin}installations/${installationID}/group/${groupID}/config`, data);
      } catch( error ) {
        throw new UnitsError(error);
      }

    },


  /**
   * Obtinene la inforación de configuración de un dispositivo
   *
   * @param {String} deviceID - ID del dispositivo
   * @param {String} installationID - ID de la instalación a la que pertenece
   * @param {String} type - Typo de configuración. Puede ser 'all', 'user' o 'advanced'. Por defecto es 'user'
   * @return {Object} - Resultado de la petición
   * @throws {badParams} - Los datos enviados no son válidos
   * @throws {notAuthorized} - El usuario que hace la petición no tiene permisos
   * @throws {deviceNotFound} - El dispositivo no se ha encontrado
   * @throws {socketTimeout} - Se ha superado el tiempo de respuesta para la operación
   */

  async getDeviceConfig(deviceID, installationID, userUnits, type){
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    try {
      //
      // Preparamos la petición GET con los datos mínimos
      //
      let reqUrl = `${admin}devices/${deviceID}/config?installation_id=${installationID}`;
      //
      // Si recibo el type como parámetro, lo agregamos a la petición como parámetro por la url
      //
      if(type) reqUrl += `&type=${type}`;

      let response;

      if (store.getters.getIsDemo) {
        response = await DemoService.getSettings(installationID, deviceID);
      } else {
        response = await ApiService.get(reqUrl);
      }

      const configParams = cloud2web.parseData(response.data, userUnits);

      return configParams;

    } catch (error) {
      throw new UnitsError(error);
    }
  },

  async getSchedules(installationID, deviceID) {
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    try {
      let response;
      if (store.getters.getIsDemo) {
        response = await DemoService.getSchedules(installationID);
      } else {
        response = await ApiService.get(`${admin}devices/${deviceID}/schedule?installation_id=${installationID}`);
      }

      const schedules = response.data.schedules;
      const schedulesAvailableConf = {}
      const userUnits = await User.query().first().units;


      // const allSystemsNumbers = [];

      // schedules.forEach( sched => {
      //   allSystemsNumbers.push(sched.schedule_number);
      // });

      // const scheduleNumber = getScheduleNumber( allSystemsNumbers.sort() );
      //
      // Añadimos el schedule number al obj de configuración
      //
      // schedulesAvailableConf.schedule_number = scheduleNumber;
      //
      // También añadimos info de la instalación a la que pertenece
      //
      schedulesAvailableConf.installation_id = installationID;

      Object.keys(response.data).forEach( param => {
        if(param === 'schedules') return;

        if(response.data[param] === null){
          schedulesAvailableConf[param] = null;
          return;
        }
        //
        // Si es parámetro de temperatura
        //

        if(hasProp(response.data[param], 'celsius')){
          schedulesAvailableConf[param] = response.data[param][userUnits];
        } else {
          schedulesAvailableConf[param] = response.data[param];
        }
      });

      return {
        schedules,
        schedulesAvailableConf
      };
    } catch (error) {
      console.log(error);
      throw new UnitsError(error);
    }
  },

  async saveSchedule(installationID, deviceID, data, opts = null){
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    let requestData = {};
    if(opts !== null){
      requestData = { schedule: data, opts }
    } else {
      requestData = { schedule: data }
    }

    try{
      let response;
      if (store.getters.getIsDemo) {
        response = {};
        response.data = {};
      } else {
        response = await ApiService.post(`${admin}devices/${deviceID}/schedule?installation_id=${installationID}`, requestData);
      }

      return response.data;

    } catch( error ){
      console.log(error);
      throw new UnitsError(error);
    }

  },

  async deleteSchedule(installationID, deviceID, schedNum) {
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    try{
      let response;
      if (store.getters.getIsDemo) {
        response = {};
        response.data = {};
      } else {
        response = await ApiService.delete(`${admin}devices/${deviceID}/schedule/${schedNum}?installation_id=${installationID}`);
      }
      // console.log("Delete", response.data);
      return response.data;
    } catch( error ){
      console.log(error);
      throw new UnitsError(error);
    }
  },

  /**
   *
   * @param {*} installationID Id de instalación o macBLE en caso de ser flujo BLE
   * @param {*} isBle Forma de obtener las programaciones: Cloud o BLE
   * @param {*} wsType Tipo de webserver
   * @returns {Object}
   */
  async getInstallationSchedules(installationID, isBle = false, wsType = constants.DEVICE_TYPE.az_ws) {
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    try {
      let response;

      if (store.getters.getIsDemo) {
        response = await DemoService.getSchedules(installationID);
      } else if(!isBle){
        response = await ApiService.get(`${admin}installations/${installationID}/schedules`);
      } else {
        // NOTA: Aquí installationID será la macBLE que se le pase del WS
        response = await BluetoothService.getWsSchedules(installationID)
      }

      const schedules = !isBle ? response.data : response.sched;
      const maxSchedules = response.max_sched_num;

      let data = [];
      const userUnits = await User.query().first().units;

      // console.log(" ---------- GET SCHEDULES ------------", schedules);

      if(!isBle && schedules && schedules.length > 0) {

        data = cloud2web.getSchedules(schedules, userUnits);

      } else if(schedules && schedules.length > 0){
        //
        // EN BLE, obtenemos los datos del modelo a partir de lo obtenido por BLE con la interfaz
        //
        data = await bleToModel.getSchedules(schedules, wsType);
      }

      return {
        data,
        maxSchedules
      };

    } catch( error ) {
      throw new UnitsError(error);
    }
  },

  async saveInstallationSchedule(installationID, data, opts = null) {
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    let requestData = {};
    let responseData = {};
    if(opts !== null){
      requestData = { schedule: data, opts }
    } else {
      requestData = { schedule: data }
    }

    try{
      let response;
      //
      // La respuesta me devuelve la ID de la nueva scedule.
      //
      if (store.getters.getIsDemo) {
        data._id = `scheduleNew${Math.random()}`;
        response = { data };
      } else {
        response = await ApiService.post(`${admin}installations/${installationID}/schedules`, requestData);
      }

      responseData = data; // Guardamos todos los datos enviados
      responseData.id = response.data._id; // y le agregamos la id para actualizar el modelo.


      return responseData;

    } catch( error ){
      console.log(error);
      throw new UnitsError(error);
    }
  },

  async saveBleSchedule(deviceID, data, schedConf, opts = null, wsType = constants.DEVICE_TYPE.az_ws, scheduleID = null) {
    // let responseData = {};

    // Parseamos los datos a los parámetros del sistema para enviar por BLE
    const bleData = modelToBle.schedule(data, schedConf, wsType, opts);

    console.log("DATOS BLE PARA SISTEMA", bleData);
    if(scheduleID === null) {
      // Generamos la ID de la schedule en el primer hueco disponible (newSchedule)
      data._id = getScheduleNumber(data.schedulesIds);
    } else {
      // Si estamos editando, usamos la scheduleID recibida como parámetro
      data._id = scheduleID
    }

    const zones = [];
     if(data && data.device_ids && data.device_ids.length > 0) {
        for(let i=0; i < data.device_ids.length; i++){
          const device = Device.find(data.device_ids[i]);

          if(zones.length > 0) {
            zones.forEach(item => {
              if(item.systemid !== undefined) {
                if(item.systemid === device.system_number) {
                  if(item.zone_numbers !== undefined) {
                    item.zone_numbers.push(device.zone_number)
                  } else {
                    item.zone_numbers = [ device.zone_number]
                  }
                }
              }
            })
          } else {
            zones.push({
              systemid: device.system_number,
              zone_numbers: [device.zone_number]
            })
          }

        }
    }

      console.log("OBJECT ZONES", zones);
    //
    // Creamos la programación en el formato para enviar a BLE
    //
    const sched = {
      schedule_number: data._id,
      name: data.name,
      type: data.type,
      prog_enabled: data.prog_enabled,
      zones,
      conf: {
        ...bleData.conf,
        exec: bleData.params
      }
    }

    console.log("SCHED PARA BLE", sched);

    try {
      const response = await BluetoothService.saveWsSchedule(deviceID, sched);
      const schedules = response.sched;
      const maxSchedules = response.max_sched_num;

      const updatedData = await bleToModel.getSchedules(schedules, wsType);

      return {
        updatedData,
        maxSchedules
      }
    } catch (error) {
      console.log(error);
      throw new UnitsError(error);
    }

    //
    // los datos que tengo que devolver para almacenar en el modelo
    // tienen que tener los parámetros de "Model" (los mismos que enviamos)
    //
    // const response = { data };
    // await BluetoothService.setParams(deviceID, params, )

    // responseData = data; // Guardamos todos los datos enviados
    // responseData.id = response.data._id; // y le agregamos la id para actualizar el modelo.


    // return responseData;
  },

  async editInstallationSchedule(installationID, scheduleID, data, opts = null) {
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    let requestData = {};
    if(opts !== null){
      requestData = { schedule: data, opts }
    } else {
      requestData = { schedule: data }
    }

    try{
      let response;

      if (store.getters.getIsDemo) {
        response = { data };
      } else {
        response = await ApiService.patch(`${admin}installations/${installationID}/schedule/${scheduleID}`, requestData);
      }

      return response.data;

    } catch( error ){
      console.log(error);
      throw new UnitsError(error);
    }
  },

  async deleteInstallationSchedule(installationID, scheduleID) {
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    try{
      let response;

      if (store.getters.getIsDemo) {
        response = {};
        response.data = {};
      } else {
        response = await ApiService.delete(`${admin}installations/${installationID}/schedule/${scheduleID}`);
      }
      // console.log("Delete", response.data);
      return response.data;
    } catch( error ){
      console.log(error);
      throw new UnitsError(error);
    }
  },


  /**
   * Acción para actualizar un servidor
   *
   * @param {String} webserverID
   * @param {String} installationID
   */
  async detectSystems(webserverID, installationID) {
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    try {
       await ApiService.patch(`${admin}devices/ws/${webserverID}/discovery?installation_id=${installationID}`);

       return true;
    } catch (error) {
      throw new UnitsError(error);
    }

  },

  async completeThirdPartyAssociation(bearerToken, project, state, code) {

    ThirdPartyApiService.setHeader(bearerToken);

    const data = {
      code,
      state
    }

    try {
      const response = await ThirdPartyApiService.post(`3rdp/${project}/authorize`, data);

      return response.status >= 200 && response.status < 300;

    } catch( error ) {
      console.log(error);
      throw new UnitsError(error);
    }


  },

  /**
   * SCENES
   */
  async launchScene(installationID, sceneID, action = "start") {

    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    const data = {
      action
    }

    try{
      if(!store.getters.getIsDemo) {
        await ApiService.put(`${admin}installations/${installationID}/scene/${sceneID}`, data);
      } else {
        console.log(`Demo mode ! actvitaScene(${action})`)
      }
    } catch( error ) {
      throw new UnitsError(error);
    }

  },

  async getScene(installationID, sceneID) {
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    console.log(installationID, sceneID);

    const admin = user?.admin_mode === true ? 'admin/' : '';

    try{
      if(!store.getters.getIsDemo) {
        const response = await ApiService.get(`${admin}installations/${installationID}/scene/${sceneID}`);

        const data = cloud2web.getSceneData(response.data, user.units);

        return data;
      }

      const response = await DemoService.getScene(sceneID);

      const data = cloud2web.getSceneData(response.data, user.units);

      console.log("RESPONSE_demo", data);

      return data;


    } catch( error ) {
      throw new UnitsError(error);
    }
  },

  async setPositionScene(installationID, sceneID, position) {
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    try{
      if(!store.getters.getIsDemo) {
        await ApiService.patch(`${admin}installations/${installationID}/scene/${sceneID}/position/${position}`);

        return true;
      }

      console.log(`Demo mode!`)
      return true;

    } catch( error ) {
      throw new UnitsError(error);
    }
  },

  async updateScene(installationID, sceneID, data) {
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    try{
      if(!store.getters.getIsDemo) {
        const response = await ApiService.patch(`${admin}installations/${installationID}/scene/${sceneID}`, data);

        const responseData = cloud2web.getSceneData(response.data, user.units);

        return responseData;
      }

      console.log(`Demo mode!`)
      return true;

    } catch( error ) {
      throw new UnitsError(error);
    }
  },

  async createScene(installationID, data) {
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    try{
      if(!store.getters.getIsDemo) {
        const response = await ApiService.post(`${admin}installations/${installationID}/scenes`, data);

        const responseData = cloud2web.getSceneData(response.data, user.units);

        return responseData;
      }

      console.log(`Demo mode!`)
      const responseDemoData = cloud2web.getSceneData(data, user.units);
      console.log("DEMO_SCENE_CREATE", responseDemoData);
      return responseDemoData;

    } catch( error ) {
      throw new UnitsError(error);
    }
  },

  async deleteScene(installationID, sceneID) {
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    try{
      if(!store.getters.getIsDemo) {
        await ApiService.delete(`${admin}installations/${installationID}/scene/${sceneID}`);
      }

      console.log(`Demo mode!`)
      return true;

    } catch( error ) {
      throw new UnitsError(error);
    }
  },

  //
  // Upgrade version WS
  //

  /**
   * Devuelve la información de las versiones disponibles y texto para actualizar un webserver
   *
   * @param {String} deviceId - Mac del webserver
   * @return {Object} - El objeto con la información de las versiones y texto para actualizar el webserver
   */
   async getDeviceVersion(deviceId, installationId) {
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    let requestUrl = ''

    if (user?.admin_mode === true) {
      requestUrl = `${admin}devices/ws/${deviceId}/versions/latest`;
    } else {
      requestUrl = `devices/ws/${deviceId}/versions/latest?installation_id=${installationId}`;
    }


    try {
      // const response = await ApiService.get(requestUrl);
      const response = await ApiService.request(requestUrl, {}, 'get', constants.CONNECT.APP.AZCLOUD_API_V2_URL);
      // console.log('In getDeviceVersion: ', response.data);
      return response.data

    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  /**
   * Actualiza un webserver a la versión especifica
   *
   * @param {String} deviceId - Id del webserver a actualizar
   * @return {Object} - El objeto vacío
   */
   async upgradeDeviceVersion(deviceId, installationId) {
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    let requestUrl = ''

    if (user?.admin_mode === true) {
      requestUrl = `${admin}devices/ws/${deviceId}/upgrade/latest`;
    } else {
      requestUrl = `devices/ws/${deviceId}/upgrade/latest?installation_id=${installationId}`;
    }

    try {
      // const response = await ApiService.patch(requestUrl);
      const response = await ApiService.request(requestUrl, {}, 'patch', constants.CONNECT.APP.AZCLOUD_API_V2_URL);
      // console.log('In updateDeviceVersion: ', response.data);
      return response.data
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  /**
   * Obtiene el estado de un Webserver de sólo lectura, para mostrar el alias en Airtools Ble
   *
   * @param {String} installationID
   * @param {String} webserverID
   */
   async getCloudAlias(webserverID, pin) {
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //

    let apiUrl = `devices/ws/${webserverID}/status?`;

    if(pin) {
      apiUrl += `pin=${pin}`;
    }

    try {
      const response = await ApiService.get(apiUrl);

      // console.log("getCloudAlias BLE WS", response);
      //
      // obtengo el array de webservers de la respuesta
      //
      const wwssData = response.data;

      const data = await cloud2web.getWebserverBLEData(webserverID, wwssData)

      return data;

    } catch (error){
      // console.log(error)
      if (error.response.status === 403) {
        throw new AppError('aliasNotAvailable');
      }
      return error
    }
  },

  /**
   * Copia la configuración del webserver a otros dispositivos de la misma topología
   *
   * @param {String} installationId - Id de la instalación del webserver que vamos a copiar la configuración
   * @param {String} sourceWsId - Id del webserver que vamos a copiar la configuración
   * @param {Array} selectedDevices - Dispositivos exluidos para copiar la configuración
   * @param {Boolean} selectAll - Dispositivos exluidos para copiar la configuración si selectAll es true
   * @param {Array} excludedWsIds - Dispositivos exluidos para copiar la configuración si selectAll es true
   * @return {Object} - El objeto vacío
   */
  async setDeviceSettings(installationId, deviceId, sourceWsId, destinationWsIds = [], all = null, excludeWsIds = []) {
    //
    // Obtenemos la ruta "admin" en caso de modo admin activo
    //
    const user = User.query().first();

    const admin = user?.admin_mode === true ? 'admin/' : '';

    let requestUrl = ''

    if (user?.admin_mode === true) {
      requestUrl = `${admin}devices/${deviceId}/config/share`;
    } else {
      requestUrl = `devices/${deviceId}/config/share`;
    }

    let requestData = {};
    if(all){
      requestData = {
        installationId,
        sourceWsId,
        all,
        excludeWsIds
      }
    } else {
      requestData = {
        installationId,
        sourceWsId,
        all,
        destinationWsIds
      }
    }

    try {
      // console.log(requestData, requestUrl)
      const response = await ApiService.request(requestUrl, requestData, 'patch', constants.CONNECT.APP.AZCLOUD_API_V2_URL);
      // console.log('In setDeviceSettings: ', response);
      return response
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  async setDataSharing({deviceId, installationId, value}) {
    // Obtenemos la ruta "admin" en caso de modo admin activo
    const user = User.query().first();
    const admin = user?.admin_mode === true ? 'admin/' : '';

    const requestUrl = `${admin}devices/${deviceId}/cloud-config?installationId=${installationId}`;

    const requestData = { changes: {data_sharing_enabled: value } };

    try {
      const response = await ApiService.request(requestUrl, requestData, 'patch', constants.CONNECT.APP.AZCLOUD_API_V2_URL);

      return response
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  async setWebserverConfig({ webserverId, installation_id, params, values, isBle }) {
    // Obtenemos la ruta "admin" en caso de modo admin activo
    const user = User.query().first();
    const admin = user?.admin_mode === true ? 'admin' : '';

    const requestUrl = `${admin}devices/ws/${webserverId}/config`;

    try {
      let response;
      const state = params.reduce((acc, param, index) => {
        acc[param] = values[index];
        return acc;
      }, {});


      if(isBle) {
        const respSet = await BluetoothService.setWsState({deviceID: webserverId, state });
        response = cloud2web.getWebserverData({ webserverId, wwssData: respSet, devices: true, isBle});
      } else {
        for (const [param, value] of Object.entries(state)) {
          const requestData = {
            installation_id,
            param,
            value
          }
          response = await ApiService.request(requestUrl, requestData, 'patch');
        }
      }

      return response;
    } catch (error) {
      console.log(error);
      throw error;
    }
  }

};


export default UnitsService;
