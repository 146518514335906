

const getIsBiometricAvailable = state => state.isBiometricAvailable;
const getIsBiometricAuthOpen = state => state.isBiometricAuthOpen;
const getBiometricMode = state => state.biometricMode;
const getBiometricType = state => state.biometricType;
const getIsSkinActive = state => state.isSkinActive;
const getIsNotificationServiceBroken = state => state.isNotificationServiceBroken;

export default {
  getIsBiometricAvailable,
  getIsBiometricAuthOpen,
  getBiometricMode,
  getBiometricType,
  getIsSkinActive,
  getIsNotificationServiceBroken
}
