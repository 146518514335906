import User from '../models/User';
import { computed } from 'vue';
import constants from 'Auth/constant';
import store from 'Core/store/store';

export const useUser = () => {
  const user = computed(() => User.query().first());
  const lang = computed(() => user?.value?.lang);
  const sundayFirst = computed(() => user?.value?.sundayFirst);
  const ampm = computed(() => user?.value?.ampm);
  const units = computed(() => user?.value?.units);
  const hasInstallations = computed(() => user?.value?.installations === true);
  const pendingInstallations = computed(() => user?.value?.pendingInstallations);
  const noHaptic = computed(() => user?.value?.noHaptic);
  const totalInstallations = computed(() => user?.value?.totalInstallations);
  const isAdmin = computed(() => user?.value?.role === constants.ROLE.SUPER_ADMIN );
  const hasNewInboxNotifications = computed(() => user?.value?.hasNewInboxNotifications);
  // ******************************************************
  //-- NOTIFICATIONS
  //**************************************************** */
  const isNotificationServiceBroken = computed(() => store.getters.getIsNotificationServiceBroken);

  // Methods
  const getUserConfigNotifications = async () => {
    if(!user?.value) return;

    return user.value.getUserConfigNotifications();
  }

  const setUserConfigNotifications = async ({groups, pushEnabled}) => {
    if(!user?.value) return;

    await user.value.setUserConfigNotifications({groups, pushEnabled});
  }

  const getUserNotifications = async ({page = 1, limit}) => {
    return await user?.value?.getUserNotifications({lang: lang?.value, page, limit});
  }

  const updateInboxNotifications = async (hasNewInboxNotifications) => {
    if(!user?.value) return;

    user?.value?.updateInboxNotifications(hasNewInboxNotifications);
  }


  const deleteUserNotifications = async ({notificationIds}) => {
    if(!user?.value) return;

    return await user?.value?.deleteUserNotifications({notificationIds});
  }

  const markAsRead = async ({notificationIds, isRead = true}) => {
    if(!user?.value) return;

    await user?.value?.markAsRead({notificationIds, isRead} );
  }

  return {
    ampm,
    hasInstallations,
    isAdmin,
    lang,
    noHaptic,
    pendingInstallations,
    sundayFirst,
    totalInstallations,
    units,
    user,
    hasNewInboxNotifications,
    updateInboxNotifications,
    getUserNotifications,
    getUserConfigNotifications,
    setUserConfigNotifications,
    deleteUserNotifications,
    isNotificationServiceBroken,
    markAsRead
  }
}
