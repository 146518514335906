import { Model } from '@vuex-orm/core';
import UnitsService from 'Units/services/units.service';
import WidgetsService from 'Units/services/widgets.service';
import TariffService from 'Units/services/tariff.service';
import UnitsError from 'Units/services/errors.service';
import CONSTANTS from 'Units/constant';
import { getAvailableTempsRanges, getAvailableCommon } from 'Units/utils/getAvailable';
import { hasProps } from 'Core/utils/validate.utils';
import log from 'Core/services/log.service';
import SocketService from 'Core/services/socket.service';
import StorageService from 'Core/services/storage.service';
import cloud2web from 'Units/interfaces/cloud2web.interface';
import BluetoothService from 'Core/services/bluetooth.service';
import moment from 'moment-timezone';
// import RollbarService from 'Core/services/Rollbar.service';
import Group from './Group.model';
import Webserver from './Webserver.model';
import { Device } from './DeviceHierarchy';
import Schedule from './Schedule.model';
import Command from './Command.model';
import Scene from './Scene.model';
import store from 'Core/store/store';
import Tariff from './Tariff.model';
import Period from './Period.model';

/**
 * @typedef Installation
 *
 */
 export default class Installation extends Model {

  static entity = 'installations';

  /** *************************************************************
   * CAMPOS
   ************************************************************** */
  static fields() {
    return {
      id: this.attr(null).nullable(),
      name: this.string(''),
      icon: this.number(null).nullable(),
      color: this.number(null).nullable(),
      locationID: this.string(''),
      coords: this.attr(null).nullable(),
      city: this.string(undefined).nullable(),
      country: this.string(undefined).nullable(),
      countryCode: this.string(undefined).nullable(),
      currency_tariff: this.attr(undefined).nullable(),
      confirmed_date: this.string(undefined).nullable(),
      added_at: this.string(undefined).nullable(),
      access_type: this.string(null).nullable(),
      position: this.number(null).nullable(),
      groups: this.hasMany(Group, 'installation_id'),
      webservers: this.hasMany(Webserver, 'installation_id'),
      schedulesWeek_ws_ids: this.attr(undefined).nullable(), // IDs de webserver que admiten programación semanal
      schedulesCalendar_ws_ids: this.attr(undefined).nullable(), // IDs de webserver que admiten programación de calendario
      schedulesAcs_ws_ids: this.attr(undefined).nullable(),
      schedulesVmc_ws_ids: this.attr(undefined).nullable(),
      schedulesRelay_ws_ids: this.attr(undefined).nullable(),
      schedulesActivated: this.boolean(undefined).nullable(), // Indica si están habilitadas las programaciones
      maxSchedNum: this.number(undefined).nullable(), // Máximo número de programaciones permitidas (BLE)
      tariffAvailables: this.attr(undefined).nullable(), // Tarifas disponibles [Regulada, Fija, Custom]
      timezone: this.string(undefined).nullable(),
      warnings: this.attr(undefined).nullable(),
      widgets: this.attr(undefined).nullable(),
      weather: this.attr(undefined).nullable(),
      errors: this.attr(undefined).nullable(),
      priceRegion: this.string(null).nullable(),
      totalWebservers: this.number(undefined).nullable(),
      totalFilteredWebservers: this.number(undefined).nullable()
    };
  }

  /**
   * Actualiza el modelo (sin enviar al servidor)
   *
   * @param {String} param
   * @param {*} value
   */
  updateParam = async (param, value) => {
    const installationID = this.id;
    const data = {};
    data[param] = value;

    console.log(`SetParam: ${param}, ${value}`);

    await Installation.update({
      where: installationID,
      data
    });

    log.success(`Editado ${param}`);
  }

  /**
   * Actualiza el modelo tras guardar los datos en el servidor (backend)
   *
   * @param {String} param
   * @param {*} value
   */
  saveParam = async (param, value) => {
    const installationID = this.id;
    const data = {};

    data[param] = value;

    if(!store.getters.getIsDemo) {
      await UnitsService.editInstallation(installationID, param, value);
    }

    await Installation.update({
      where: installationID,
      data
    });

    log.success(`SaveParam: ${param}, ${value}`);

  }

  /**
   * Actualiza y almacena la localización de una instalación
   *
   * @param {String} city - Nombre de la ciudad para actualizar el modelo (no es necesario enviar al backend)
   * @param {*} coords - Coordenadas para el backend
   */
  updateLocation = async googlePlaceId => {

    const installationID = this.id;
    let data = {}
    if(!store.getters.getIsDemo) {
      data = await UnitsService.editInstallation(installationID, 'google_place_id', googlePlaceId);
    }

    await Installation.update({
      where: installationID,
      data
    });

    log.success(`SetLocation: ${data.city}, ${JSON.stringify(data.coords)}`);

    return data;
  }

  get getName() {
    return this.name;
  }

  get isEmpty() {
    return this.groups.length === 0 && this.webservers.length === 0;
  }

  get isAdmin() {
    return this.access_type === CONSTANTS.USER_TYPE.ADMIN;
  }

  get isAdvanced() {
    return this.access_type === CONSTANTS.USER_TYPE.ADVANCED;
  }

  get isBasic() {
    return this.access_type === CONSTANTS.USER_TYPE.BASIC;
  }

  get allZones() {
    let zones = [];

    this.groups.forEach(group => {
      zones = [...zones, ...group.zones];
    });

    return zones;
  }

  get hasWeekSchedules() {
    if (!Array.isArray(this.schedulesWeek_ws_ids)) return false;

    return this.schedulesWeek_ws_ids.length > 0
  }

  get hasCalendarSchedules() {
    if (!Array.isArray(this.schedulesCalendar_ws_ids)) return false;

    return this.schedulesCalendar_ws_ids.length > 0
  }

  get hasSchedules() {
    return this.hasCalendarSchedules || this.hasWeekSchedules || this.hasAcsSchedules || this.hasVmcSchedules
  }

  get hasAcsSchedules() {
    if (!Array.isArray(this.schedulesAcs_ws_ids)) return false;

    return this.schedulesAcs_ws_ids.length > 0
  }

  get hasVmcSchedules() {
    if (!Array.isArray(this.schedulesVmc_ws_ids)) return false;

    return this.schedulesVmc_ws_ids.length > 0
  }

  get hasRelaySchedules() {
    if (!Array.isArray(this.schedulesRelay_ws_ids)) return false;

    return this.schedulesRelay_ws_ids.length > 0
  }

  get hasWeatherWidget() {
    const widgetKeys = Object.keys(this.widgets);

    if(widgetKeys.includes('weather') && Object.keys(this.widgets.weather).length > 0) return true;

    return false;
  }

  get hasElectricPriceWidget() {
    const widgetKeys = Object.keys(this.widgets);

    if(widgetKeys.includes('price') && Object.keys(this.widgets.price).length > 0) return true;

    return false;
  }

  get hasElectricalConsumptionWidget() {
    const widgetKeys = Object.keys(this.widgets);

    if(widgetKeys.includes('consumption') && Object.keys(this.widgets.consumption).length > 0) return true;

    return false;
  }

  get hasEcowattWidget() {
    const widgetKeys = Object.keys(this.widgets);

    return widgetKeys.includes('ecowatt');
  }


  async getAvailableCommon() {

    try {
      let devices = [];

      if(this.isBasic){
        devices = Device.query().where('installation_id', this.id)
        .where((_record, query) => {
          query.where('device_type','az_zones')
          .orWhere('device_type','aidoo')
          .orWhere('device_type', CONSTANTS.DEVICE_TYPE.aidoo_it)
        })
        .withAll().get();
      } else {
        devices = Device.query().where('installation_id', this.id)
        .where((_record, query) => {
          query.where('device_type','az_system')
          .orWhere('device_type','aidoo')
          .orWhere('device_type', CONSTANTS.DEVICE_TYPE.aidoo_it)
        })
        .withAll().get();
      }


      const availableCommon = await getAvailableCommon(devices);

      return availableCommon;
    } catch (err) {
      return err;
    }



  }

  // getAvailableModes(zones) {
  //   if (!zones) zones = this.allZones;
  //   const defaultModes = [
  //     'auto',
  //     'cool',
  //     'heat',
  //     'fan',
  //     'dry',
  //     'stop',
  //     'auto',
  //     'emergencyHeat'
  //   ];
  //   const availableOptions = getAvailablesOption('mode_available', defaultModes, zones);

  //   if (!availableOptions) return null; // Si no hay ninuna zona con modos disponibles
  //   if (!availableOptions.length) return ['cool', 'heat'];
  //   return availableOptions;
  // }

  getAvailableRange(zones) {
    if (!zones) zones = this.allZones;

    const tempRanges = getAvailableTempsRanges(zones);

    return tempRanges;
  }



  /** *************************************************************
   * LIFECYCLE HOOKS
   ************************************************************** */

  static afterCreate(model) {
    //
    // Compruebo si he recibido los datos mínimos del usuario para que la aplicación funcione
    //
    const validate = hasProps(model, ['id', 'name', 'locationID']);

    if (validate.length) throw new UnitsError('invalidInstallationData', `Faltan los datos "${validate.join()}" de la instalación`);
  }

  /** *************************************************************
   * ACCIONES
   ************************************************************** */

  /**
   * Obtiene todas las instalaciones de un usuario
   *
   * @param {string} filterParam - filtro para buscar instalaciones por el parámetro indicado
   * @param {string} filterValue - filtro para buscar instalaciones según el valor indicado
   * @param {string} lang -
   * @param {number} items - Número de items máximos por página a mostrar (limitamos a 9 por estilo)
   * @param {number} page - Página a devolver si hay más instalaciones del número de items a mostrar
   * @param {String} role - Opcional: Si es "admin" podrá obtener instalaciones de toda la base de datos
   */
  static getInstallations = async (filterParam = null, filterValue = null, lang = null, items = null, page = null, role) => {
    if(items === null) {
      items = CONSTANTS.PAGINATION.INSTALLATIONS.items_for_page // Si no especifico máximo de items, fijo a 9 (para grid de 3x3)
    }
    const installations = await UnitsService.getInstallations(
      filterParam,
      filterValue,
      lang,
      items,
      page,
      role);

    await this.create({ data: installations });

    log.success('GetInstallations');
  };


  static getAllInstallationsList = async () => {
    const installations = await UnitsService.getAllInstallationsList();

    log.info('getAllInstallationsList');

    return installations;
  };

  static getNotConfirmedInstallations = async () => {
    const notConfirmed = await UnitsService.getNotConfirmedInstallations();

    log.info('GetNotConfirmedInstallations');

    return notConfirmed;
  };

  /**
   *
   * @param {*} installationID
   */
   static getWebservers = async (installationID, page, items) => {
      const {webservers, totalWebservers} = await UnitsService.getWebservers({installationID, page, items});

      // Actualizamos el número de webservers totales de la instalación
      await Installation.update({
        id: installationID,
        totalWebservers
      });

      return webservers;
   }

  /**
   * Obtiene todos los datos de las zonas de una instalación
   *
   * @param {String} installationID - ID de la instalación
   */
  static getInstallation = async (installationID, view = null) => {
    // console.log('getInstallation', installationID)
    // const installationName = await this.find(installationID).name;
    //
    // Obtengo datos de la instalación y su arquitectura de dispositivos
    const installation = await UnitsService.getInstallation(installationID, view);

    //
    // Añado los diferentes dispositivos a sus correspondientes modelos
    //
    // NOTA: Es muy importante el orden en que se cargan en el modelo. Primero "devices", luego "grupos", etc.
    // Hay que seguir el orden jerárquico ascendente del modelo definido. Si tenemos cargada instalaciones sin dispositivos pueden ocurrir errores
    //
    await Device.insertOrUpdate({ data: installation.devices });
    await Group.insertOrUpdate({ data: installation.groups });
    // await System.insertOrUpdate({ data: Object.values(installation.systems) });
    await Webserver.insertOrUpdate({ data: Object.values(installation.webservers) });
    // Guardamos las escenas
    await Scene.insertOrUpdate({data: installation.scenes});
    // Guardamos los comandos de las escenas
    await Command.insertOrUpdate({data: installation.commands});

    //
    // Actualizo los datos de la instalación
    //
    installation.data.id = installationID;
    await Installation.insertOrUpdate({data: installation.data });

    log.success(`GetInstallation data of ${installationID}`);
    // RollbarService.info(`GetInstallation data of ${installationID}`)

    return true;
  };

  static storeVisualizationSetting = (option, view = 'main') => {
    if(option === CONSTANTS.VISUALIZATION.ORDER || store.getters.getIsDemo) return;

    const item = `${view}_visualization`
    StorageService.setItem(item, option);

    log.success(`Visualization ${option} successful stored`);

  };

  static getVisualizationSetting = (view = 'main') => {
    const item = `${view}_visualization`
    const option = StorageService.getItem(item);

    return option;
  };

  getLocation = async () => {

    if(!store.getters.getIsDemo){
      const data = await UnitsService.getLocation(this.locationID);
      // console.log( data );


      this.$update({data});
      await Installation.update({
        where: this.id,
        data
      });
    }
  };

  /**
   * WIDGET: Precio Eléctrico
   * Devuelve la información sobre el precio eléctrico y tarifas en una zona horaria
   * @param {Date} dateParam - fecha sobre la que obtener la info
   * @param {String} region - Región
   * @return {Object}
   */
  getPriceInfo = async (dateParam = undefined, region, tariffId)=> {
    const countryCode = this.countryCode;
    let date;

    if(dateParam !== undefined && dateParam !== null){
      date = moment(dateParam).tz(this.timezone).format("YYYY-MM-DD")
    } else if (this.timezone){
      date = moment().tz(this.timezone).format("YYYY-MM-DD")
    } else {
      date = moment().format("YYYY-MM-DD")
    }

    try {
      let regulatedTariffId = this.widgets.price?.regulated_tariff_id;

      const response = await WidgetsService.getPricesInfo({installationID: this.id, countryCode, timezone: this.timezone, region, date, tariffId, regulatedTariffId });

      if(!response) return {prices:[]};

      Installation.insertOrUpdate({
        data: {
          id: this.id,
          currency_tariff: response.currency
        }
      })

      const data = {
        date: response.date,
        ratesName: response.ratesName,
        currency: response.currency,
        maxPrice: response.maxPrice,
        minPrice: response.minPrice,
        actualPrice: response.actualPrice.value,
        actualPeriod: response.actualPeriod,
        actualDate: response.actualPrice.dt,
        maxPriceValue: response.maxPrice.value,
        minPriceValue: response.minPrice.value,
        timezone: response.timezone,
        priceRate: response.actualPrice.period_id,
        prices: response.prices,
        period_legend: response.period_legend,
        hasNext: response.hasNext,
        hasPrev: response.hasPrev
      }

      return data;
    } catch (error) {}
  };

  getAvailablesTariffs = async() => {
    const resp = await WidgetsService.getAvailablesTariffs({installationId: this.id});

    Installation.insertOrUpdate({
      data: {
        id: this.id,
        tariffAvailables: resp
      }
    });

    return resp;
  }

  getTariffData = async (tariffId, basicMode = false) => {
    let response;

    if(!store.getters.getIsDemo){
      response = await TariffService.getTariffData({installationID: this.id, tariffId: tariffId, basicMode});

      Period.deleteAll();
      Tariff.deleteAll();

    } else {
      response = { currency: {code: 'EUR', symbol: '€'} };
    }

    Installation.insertOrUpdate({
      data: {
        id: this.id,
        currency_tariff: response.currency
      }
    })

    // Nota, si hay periods definido en la respuesta se acttualizará con la siguiente línea
    // el model Period con los datos de response.periods
    return response;
  };

  getConsumptionElectricInfo = async (startDate, range) => {
    const resp = await WidgetsService.getConsumptionElectricInfo(this.id, startDate, range);

    return resp;
  }

  /*
   * Devuelve el precio de un café en función de la id de una moneda.
  */
  getCoffeeCupPrice = async({ currencyCode }) => {
    const resp = await WidgetsService.getCoffeeCupPrice({ currencyCode });

    return resp;
  }

  /**
   * WIDGET: Precio Clima
   * Devuelve la información sobre el clima y calidad de aire de la instalación
   */
  getWeatherInfo = async (units, lang, aqInfo = true) => {

    const response = await WidgetsService.getWeatherInfo(this.id, this.locationID, aqInfo);
    const data = cloud2web.formattedWeatherData(response, units, lang);

    const updateData = {
      weather: data
    }

    await Installation.update({
      where: this.id,
      data:updateData
    });

    return data;
  };


  /**
   * WIDGET: Ecowatt
   * Devuelve la información sobre Ecowatt
   */
  getEcowattInfo = async ({startdate, query}) => {
    const response = await WidgetsService.getEcowattInfo({startdate, query});
    return response;
  };

  /**
   * Actualiza un parámetro de todas las zonas de una instalación
   *
   * @param {String} action - La acción a realizar en el grupo
   * @param {String,Number} value - La información a modificar {param: value}
   */
  setInstallationStatus = async ({ data }) => {
    // Realizo la petición al backend
    if(!store.getters.getIsDemo){
      await UnitsService.setGroupStatus({ installationID: this.id, groupID: null, data, isInstallation: true });
    } else {
      const groups = Group.query().where('installation_id', this.id).withAll().get();

      groups.forEach(async group => {
        await group.setGroupStatus({ data });
      });
    }

    log.info(`SetInstallationStatus ${this.name}: ${JSON.stringify(data)}`);

    return true;
  };

  /**
   * Indica la nueva posición de ordenación de la instalación
   *
   * @param {number} installationID - Id de la instalación
   * @param {number} position - Posición de la instalación
   */
  static setPositionInstallation = async (installationID, position) => {
    if(!store.getters.getIsDemo){
      await UnitsService.setPositionInstallation(installationID, position);
    }

    log.info(`SetInstallationPosition ${this.name} to position ${position}`);

  };

  // SCHEDULES: Métodos de programaciones de instalación o sistemas.

  static getSchedules = async (installationID, macBLE = null, wsType = 'ws_az') => {
    // Pasamos la macBLE si es ble.
    // Por otro lado si tenemos la macBLE entonces el segundo parámetro debe ser true para hacer la petición ble
    const response = await UnitsService.getInstallationSchedules(macBLE || installationID, !!macBLE, wsType);

    // console.log(response);

    const data = response.data; // Datos de las schedules
    const maxSchedNum = response.maxSchedNum ? response.maxSchedNum : CONSTANTS.SCHEDULES.MAX_SCHEDULES_NUM;

    await Schedule.insertOrUpdate({
      data
    });


    Installation.insertOrUpdate({
      data: {
        id: installationID,
        maxSchedNum
      }
    });

    return true;
  };

  static setSchedulesActivatedStatus = async (installationID, value) => {
    await UnitsService.setSchedulesActivatedStatus(installationID, value);
    //
    // Si todo ha ido bien actualizo el modelo con el nuevo valor
    //
    const data = {
      schedulesActivated: value
    }
    await Installation.update({
      where: installationID,
      data
    });

    return true;
  };

  static saveSchedule = async (installationID, data, opts = null, isBle = false, wsType = 'ws_az', scheduleConf) => {
    // let newSchedule;
    if(!isBle) {

      // En cloud la respuesta me devuelve la nueva programación
      const newSchedule = await UnitsService.saveInstallationSchedule(installationID, data, opts);

      console.log("Schedule saved in model",newSchedule);

      Schedule.insertOrUpdate({data: newSchedule});

    } else {

      // En BLE recibo todo el listado de programaciones actualizado
      const response = await UnitsService.saveBleSchedule(installationID, data, scheduleConf, opts, wsType);

      console.log("Schedules updated in model", response);

      const updatedData = response.updatedData;
      const maxSchedNum = response.maxSchedNum;

      Schedule.deleteAll();

      Schedule.create({data: updatedData});

      Installation.insertOrUpdate({
        data: {
          id: installationID,
          maxSchedNum
        }
      });
    }

    log.info(`setScheduleStatus: ${JSON.stringify(data)}`);

    return data;
  };


  static editSchedule = async (installationID, scheduleID, data, opts = null, isBle = false, wsType = 'ws_az', scheduleConf) => {
    let response;
    if(!isBle){
      response = await UnitsService.editInstallationSchedule(installationID, scheduleID, data, opts);
      data.id = scheduleID;

      console.log(response);

      await Schedule.update({data});
    } else {

      // En BLE recibo todo el listado de programaciones actualizado
      response = await UnitsService.saveBleSchedule(installationID, data, scheduleConf, opts, wsType, scheduleID);

      console.log("Schedules updated in model", response);

      const updatedData = response.updatedData;
      const maxSchedNum = response.maxSchedNum;

      Schedule.deleteAll();

      Schedule.create({data: updatedData});

      Installation.insertOrUpdate({
        data: {
          id: installationID,
          maxSchedNum
        }
      });
    }




    log.info(`editScheduleStatus: ${JSON.stringify(data)}`);
  }

  static deleteSchedule = async (installationID, scheduleID) => {
    await UnitsService.deleteInstallationSchedule(installationID, scheduleID);

    Schedule.delete(scheduleID);

    log.success(`deleteSchedule from id: ${installationID}`);

    return true;
  }

  static deleteBleSchedule = async (macBLE, scheduleID) => {
    await BluetoothService.deleteWsSchedule(macBLE, scheduleID);

    await Schedule.delete(scheduleID);

    log.success(`deleteSchedule id: ${scheduleID}`);

    return true;
  }

  static addNewInstallation = async (mac, name, googlePlaceId, icon = 1, color = 1 ) => {
    const response = await UnitsService.addNewInstallation(mac, name, googlePlaceId, icon, color);

    log.success(`AddNewInstallation with id: ${response.data.id}`);

    return true;
  }

  static addWebserver = async (installationID, mac) => {
    await UnitsService.addWebserver(installationID,mac);

    log.success(`AddNewWebserver with mac: ${mac} to installation: ${installationID}`);

    return true;
  }

  static leaveInstallation = async (installationID, userID) => {

    await UnitsService.leaveInstallation(installationID, userID);

    log.success(`Installation ${installationID} leaved successful`);
    Installation.delete(installationID);
    Webserver.delete( record => {
      return record.installation_id === installationID;
    });
    Device.delete( record => {
      return record.installation_id === installationID;
    });
    Group.delete( record => {
      return record.installation_id === installationID;
    });



    return true;
  }

  static deleteInstallation = async installationID => {

    await UnitsService.deleteInstallation(installationID)

    log.success(`Installation ${installationID} deleted successfull`);

    Installation.delete(installationID);
    Webserver.delete( record => {
      return record.installation_id === installationID;
    });
    Device.delete( record => {
      return record.installation_id === installationID;
    });
    Group.delete( record => {
      return record.installation_id === installationID;
    });

    return true;
  }

  //
  // @todo Eliminar los métodos asociados al socket que están en User Model. O decidir si mantenerlos
  //

  /**
   *  Indica al Websocket que empezamos a escuchar eventos de la instalación
   */
  static listenInstallation = async installationID => {
    await SocketService.listenInstallation(installationID);
    return true;
  }

  /**
   * Indica al Websocket que dejamos de escuchar los eventos de la instalación, liberando el tráfico de datos
   */
  static clearListeners = async () => {
    await SocketService.clearListeners();
    return true;
  }

  static completeThirdPartyAssociation = async (bearerToken, project, state, code) => {
    const response = await UnitsService.completeThirdPartyAssociation(bearerToken,project, state, code);

    return response;
  }


}
