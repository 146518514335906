const getLoadingStatus = state =>  state.loading;
const getRenderStatus = state =>  state.renderApp;
const getIsUserAllow = state => state.isUserAllow;
const getExtConfigUrl = state => state.extConfigUrl;
const getIsShowSidenav = state => state.isShowSidenav;
const getIsThereNotch = state => state.isThereNotch;
const getIsLocationActive = state => state.isLocationActive;
const getIsWithoutNotch = state => state.isWithoutNotch;
const getIsDemo = state => state.isDemo;
const getLogo = state => state.logo;
const getSsidSafeFormat = state => state.ssidSafeFormat;
const getLastCoords = state => state.lastCoords;


export default {
  getLoadingStatus,
  getRenderStatus,
  getIsUserAllow,
  getExtConfigUrl,
  getIsShowSidenav,
  getIsThereNotch,
  getIsLocationActive,
  getIsWithoutNotch,
  getIsDemo,
  getLogo,
  getSsidSafeFormat,
  getLastCoords
}
