import mapTranslations from 'Units/utils/mapTranslations';
import {getModeStringValue } from 'Units/utils/mode.utils';
import constants from 'Units/constant';
import { Device } from './DeviceHierarchy';
import { hasWarnings } from '../utils/warnings.utils';



export class VMC extends Device {
  static entity = 'vmc';

  static baseEntity = 'devices';

  /** *************************************************************
   * CAMPOS
   **************************************************************

  Este modelo describe cualquier dispositivo de IAQ: AirQSensor, VMC, Recuperadora, etc...

  * @property {boolean} [acs_sched_available] - si permite programaciones de VMC
  * @property {boolean} [power] - On/Off
  * @property {boolean} [active] - En demanda
  * @property {string} [bypass_position_percent] - Posición del bypass en %
  * @property {string} [autochange_temp_conf] - Modo auto de temperatura
  * @property {string} [autochange_temp_values] - Valores disponibles para autchange_temp_conf ['local', 'exterior]
  * @property {number} [filter_usage_hours] - Horas de uso del filtro
  * @property {number} [filter_usage_percent] - Porcentaje de uso del filtro
  * @property {boolean} [dirty_filter] - Si el filtro está sucio
  * @property {boolean} [recovery_sensors_display] - Si muestra los sensores de recuperación
  * @property {string} [mode] - Modo de funcionamiento
  * @property {Array<string>} [mode_available] - Modos de funcionamiento disponibles
  * @property {number} [speed_conf] - Velocidad de ventilación
  * @property {Array<number>} [speed_values] - Valores de velocidad de ventilación
  * @property {number} [speed_conf_2] - Velocidad de ventilación 2
  * @property {Array<number>} [speed_values_2] - Valores de velocidad de ventilación 2
  * @property {number} [speed_type] - Tipo de velocidad
  * @property {number} [speed_2_type] - Tipo de velocidad 2
  * @property {number} [timer] - Tiempo de funcionamiento
  * @property {Array<number>} [timer_values] - Valores de tiempo de funcionamiento
  * @property {number} [auto_mode] - Define el modo real de funcionamiento del modo auto
  * @property {number} [local_temp] - Temperatura local
  * @property {number} [extract_air_temp] - Temperatura del aire extraído
  * @property {number} [supply_air_temp] - Temperatura del aire suministrado
  * @property {number} [outdoor_air_inlet_temp] - Temperatura del aire exterior
  * @property {boolean} [vmc_sched_available] - si permite programaciones de VMC
  * @property {string} [name] - Nombre del dispositivo

  * @property {Array<{_id: string, data?: Object<string, string>}>} [warnings]
  * @property {Array<{_id: string, data?: Object<string, string>}>} [errors]
  ******************************************************************** */

  static fields() {
    return {
      ...super.fields(),

      // Parámetros de AQ
      airq: this.boolean(undefined).nullable(), // boolean. Si true, entonces es AirQ Sensor o AirQ Box; si no está o es false, entonces es central de purificacion
      aq_sensor: this.boolean(undefined).nullable(),
      aq_sensor_fw: this.string(undefined).nullable(),
      aq_box_fw: this.string(undefined).nullable(),
      aq_priority: this.boolean(undefined).nullable(),
      aq_leds_active: this.boolean(undefined).nullable(),
      aq_active: this.boolean(undefined).nullable(),
      aq_high_conf: this.number(undefined).nullable(),
      aq_low_conf: this.number(undefined).nullable(),
      aq_mode_conf: this.string(undefined).nullable(),
      aq_mode_values: this.attr(undefined).nullable(),
      aq_vent_mode_conf: this.string(undefined).nullable(),
      aq_vent_mode_values: this.attr(undefined).nullable(),
      aq_vent_active: this.boolean(undefined).nullable(),
      aq_present: this.boolean(undefined).nullable(),
      aq_quality: this.string(undefined).nullable(),
      aq_temp_quality: this.string(undefined).nullable(),
      aq_hum_control: this.boolean(undefined).nullable(),
      aq_hum_control_high: this.boolean(undefined).nullable(),
      aq_hum_control_low: this.boolean(undefined).nullable(),
      aq_hum_quality: this.string(undefined).nullable(),
      aq_co2_quality: this.string(undefined).nullable(),
      aq_pm2_5_quality: this.string(undefined).nullable(),
      aq_pm10_quality: this.string(undefined).nullable(),
      aq_tvoc_quality: this.string(undefined).nullable(),
      aq_index: this.number(undefined).nullable(),
      aq_score: this.number(undefined).nullable(),
      aq_co2: this.number(undefined).nullable(),
      aq_fcool: this.boolean(undefined).nullable(),
      aq_fcool_mintemp_diff_conf: this.number(undefined).nullable(),
      aq_fcool_mintemp_diff_values: this.attr(undefined).nullable(),
      aq_fcool_mintemp_sp_conf: this.number(undefined).nullable(),
      aq_fcool_mintemp_sp_range_max: this.number(undefined).nullable(),
      aq_fcool_mintemp_sp_range_min: this.number(undefined).nullable(),
      aq_fcool_mintemp_sp_step: this.number(undefined).nullable(),
      aq_fcool_mintemp_sp_values: this.attr(undefined).nullable(),
      aq_fcool_months: this.attr(undefined).nullable(),
      aq_night_mode_active:this.boolean(undefined).nullable(),
      aq_night_mode_enabled: this.boolean(undefined).nullable(),
      aq_night_mode_start: this.attr(undefined).nullable(),
      aq_night_mode_end: this.attr(undefined).nullable(),
      aq_renew_air: this.boolean(undefined).nullable(),
      aq_renew_days: this.attr(undefined).nullable(),
      aq_renew_interval_conf: this.number(undefined).nullable(),
      aq_renew_interval_values: this.attr(undefined).nullable(),
      aq_renew_work_time_conf: this.number(undefined).nullable(),
      aq_renew_work_time_values: this.attr(undefined).nullable(),
      aqpm1_0: this.number(undefined).nullable(),
      aqpm2_5: this.number(undefined).nullable(),
      aqpm10: this.number(undefined).nullable(),
      aq_tvoc: this.number(undefined).nullable(),
      aq_pressure: this.number(undefined).nullable(),
      aq_pressure_values: this.number(undefined).nullable(),
      aq_heat_delta_conf: this.number(undefined).nullable(),
      aq_heat_delta_values: this.attr(undefined).nullable(),
      aq_cool_delta_conf: this.number(undefined).nullable(),
      aq_cool_delta_values: this.attr(undefined).nullable(),
      aq_min_voltage_conf: this.number(undefined).nullable(),
      aq_min_voltage_values: this.attr(undefined).nullable(),
      aq_max_voltage_conf: this.number(undefined).nullable(),
      aq_max_voltage_values: this.attr(undefined).nullable(),
      aq_hum_weight: this.number(undefined).nullable(),
      aq_co2_weight: this.number(undefined).nullable(),
      aq_tvoc_weight: this.number(undefined).nullable(),
      aq_pm10_weight: this.number(undefined).nullable(),
      aq_pm2_5_weight: this.number(undefined).nullable(),
      aq_temp_weight: this.number(undefined).nullable(),
      aq_hum_high_thres_gm: this.number(undefined).nullable(),
      aq_hum_high_thres_mb: this.number(undefined).nullable(),
      aq_hum_low_thres_gm: this.number(undefined).nullable(),
      aq_hum_low_thres_mb: this.number(undefined).nullable(),
      aq_hum_thres_range_max: this.number(undefined).nullable(),
      aq_hum_thres_range_min: this.number(undefined).nullable(),
      aq_hum_thres_range_step: this.number(undefined).nullable(),
      aq_co2_thres_gm: this.number(undefined).nullable(),
      aq_co2_thres_mb: this.number(undefined).nullable(),
      aq_co2_thres_range_max: this.number(undefined).nullable(),
      aq_co2_thres_range_min: this.number(undefined).nullable(),
      aq_co2_thres_range_step: this.number(undefined).nullable(),
      aq_tvoc_thres_gm: this.number(undefined).nullable(),
      aq_tvoc_thres_mb: this.number(undefined).nullable(),
      aq_tvoc_thres_range_max: this.number(undefined).nullable(),
      aq_tvoc_thres_range_min: this.number(undefined).nullable(),
      aq_tvoc_thres_range_step: this.number(undefined).nullable(),
      aq_pm10_thres_gm: this.number(undefined).nullable(),
      aq_pm10_thres_mb: this.number(undefined).nullable(),
      aq_pm10_thres_range_max: this.number(undefined).nullable(),
      aq_pm10_thres_range_min: this.number(undefined).nullable(),
      aq_pm10_thres_range_step: this.number(undefined).nullable(),
      aq_pm2_5_thres_gm: this.number(undefined).nullable(),
      aq_pm2_5_thres_mb: this.number(undefined).nullable(),
      aq_pm2_5_thres_range_max: this.number(undefined).nullable(),
      aq_pm2_5_thres_range_min: this.number(undefined).nullable(),
      aq_pm2_5_thres_range_step: this.number(undefined).nullable(),
      aq_sensor_reset_factory: this.boolean(undefined).nullable(),
      aq_vmc: this.boolean(undefined).nullable(),
      aq_vent_const: this.boolean(undefined).nullable(),
      // Parámetros de VMC Recovery
      vmc_sched_available: this.boolean(undefined).nullable(), // si permite programaciones de VMC
      isDefaultName: this.boolean(undefined).nullable(),
      name: this.string(''),
      power: this.boolean(undefined).nullable(), // On/Off
      status: this.attr(undefined),
      errors: this.attr(undefined).nullable(),
      humidity: this.number(undefined).nullable(),
      warnings: this.attr(undefined).nullable(),
      recovery_sensors_display: this.boolean(undefined).nullable(),
      mode: this.string(undefined).nullable(),
      mode_available: this.attr(undefined).nullable(),
      speed_conf: this.number(undefined).nullable(),
      speed_values: this.attr(undefined).nullable(),
      speed_conf_2: this.number(undefined).nullable(),
      speed_values_2: this.attr(undefined).nullable(),
      speed_type: this.number(undefined).nullable(),
      speed_2_type: this.number(undefined).nullable(),
      timer: this.attr(undefined).nullable(),
      timer_values: this.attr(undefined).nullable(),
      auto_mode: this.number(undefined).nullable(),
      local_temp: this.number(undefined).nullable(),
      extract_air_temp: this.number(undefined).nullable(),
      supply_air_temp: this.number(undefined).nullable(),
      outdoor_air_inlet_temp: this.number(undefined).nullable(),
      active: this.boolean(true).nullable(),
      bypass_position_percent: this.number(undefined).nullable(),
      autochange_temp_conf: this.string(undefined).nullable(), // Modo auto de temperatura
      autochange_temp_values: this.attr(undefined).nullable(), // ['local', 'exterior]
      filter_usage_hours: this.number(undefined).nullable(), // Horas de uso del filtro
      filter_usage_percent: this.number(undefined).nullable(), // Porcentaje de uso del filtro
      dirty_filter: this.boolean(undefined).nullable(), // Si el filtro está sucio
      vent_demand_type: this.string(undefined).nullable()
    };
  }

  get onlyOnOff() {
    return (this.device_type === constants.DEVICE_TYPE.az_vmc || this.device_type === constants.DEVICE_TYPE.aidoo_vmc) &&
          !this.isRecoveryVmc;
  }

  get isRecoveryVmc() {
    return this.recovery_sensors_display === true;
  }

  get linkDisabled() {
    return this.device_type === constants.DEVICE_TYPE.az_airqsensor && !this.isAirQSensorWithVMC;
  }

  get isAirQSensorWithVMC() {
    return this.device_type === constants.DEVICE_TYPE.az_airqsensor &&
      this.aq_vent_mode_conf !== undefined && this.aq_vent_mode_conf !== null &&
      Array.isArray(this.aq_vent_mode_values) && this.aq_vent_mode_values.length > 0
  }

  get getState() {
    if (!this.loadedData) return 'loading';
    if(this.getError) return 'error';
    if (this.power === false) return 'off';

    if(this.device_type === constants.DEVICE_TYPE.az_airqsensor){
      if(this.aq_quality === constants.AQ_QUALITY_VALUES.good) return constants.AQ_QUALITY_VALUES.good;
      if(this.aq_quality === constants.AQ_QUALITY_VALUES.regular) return constants.AQ_QUALITY_VALUES.regular;
      if(this.aq_quality === constants.AQ_QUALITY_VALUES.bad) return constants.AQ_QUALITY_VALUES.bad;
    }

    return 'airing';
  }



  get hasModesAvailables() {
    return this.mode_available?.length > 0;
  }

  get isModesBlocked() {
    return this.mode_available?.length === 1;
  }

  get getRealMode() {
    const mode = this.mode;



    switch(mode){
      case constants.WEB_MODES.FAN:
        return constants.WEB_MODES.BYPASS;
      case constants.WEB_MODES.BYPASS:
      case constants.WEB_MODES.RECOVERY:
        return mode;
      case constants.WEB_MODES.AUTO:
        if(this.auto_mode !== undefined && this.auto_mode !== null) {
          const realMode = getModeStringValue(this.auto_mode)
          return realMode || constants.WEB_MODES.AUTO;
        }
        return constants.WEB_MODES.AUTO
      default:
        return constants.WEB_MODES.BYPASS;
    }
  }

  get getTemp() {
    let zoneTemp = null;

    zoneTemp =  this.work_temp !== undefined && this.work_temp !== null ? this.work_temp : this.local_temp;

    return zoneTemp;
  }

  get getZoneStateString() {
    const zoneState = this.getState;

    if (zoneState === 'error') return typeof mapTranslations.AZ_DEVICES_STATES.error === 'function' ? mapTranslations.AZ_DEVICES_STATES.error() : '';
    if (!zoneState || zoneState === 'loading') return typeof mapTranslations.AZ_DEVICES_STATES.loading === 'function' ? mapTranslations.AZ_DEVICES_STATES.loading() : '';
    if (zoneState === 'off') return typeof mapTranslations.AZ_DEVICES_STATES.off === 'function' ? mapTranslations.AZ_DEVICES_STATES.off() : '';
    if (zoneState === 'success') return typeof mapTranslations.AZ_DEVICES_STATES.success === 'function' ? mapTranslations.AZ_DEVICES_STATES.success() : '';
    if (this.isAirQSensorWithVMC) {
      // Si es sensor con VMC y conocemos la razón de la demanda
      if (this.vent_demand_type && typeof mapTranslations.AQ_VENT_DEMAND_TYPE[this.vent_demand_type] === 'function') {
        return mapTranslations.AQ_VENT_DEMAND_TYPE[this.vent_demand_type]();
      }
      // Si no tenemos la razón de la demanda, mostramos el estado de la ventilación
      return this.aq_vent_mode_conf === constants.AQ_VENT_MODE_VALUES.on ||
      this.aq_vent_active === true ?
        mapTranslations.AZ_DEVICES_STATES.airing() :
        mapTranslations.AZ_DEVICES_STATES.off();
    }

    let message = '';

    if(typeof this.active === 'boolean'){
      message = `${typeof mapTranslations.AZ_DEVICES_STATES[zoneState] === 'function' ? mapTranslations.AZ_DEVICES_STATES[zoneState]() : ''}`;
    }

    return message;
  }

  /**
  * Indica si la unidad tiene algún warning en el array
  *
  */
  get hasWarnings() {
    if ((!Array.isArray(this.warnings) || this.warnings.length === 0) &&
      (!Array.isArray(this.errors) || this.errors.length === 0)) return undefined;

    const warnings = this.warnings?.filter( warning => warning.hidden !== true);
    const errors = this.errors?.filter( error => error.hidden !== true);

    let allowedWarnings = 0
    let allowedErrors = 0

    if (warnings) allowedWarnings = hasWarnings(warnings);

    if (errors) allowedErrors = hasWarnings(errors);

    const allowedWarningsErrors = allowedWarnings + allowedErrors

    return (allowedWarningsErrors !== undefined && allowedWarningsErrors !== null && allowedWarningsErrors > 0);
  }


  /**
   * AIRTOOLS section - VMC
   */

  get hasAidooInfo() {

    return (this.manufacturer !== undefined && this.manufacturer !== null && this.manufacturer.text) ||
      (this.units !== undefined && this.units !== null) ||
      (this.is_tai_conf !== undefined && this.is_tai_conf !== null) ||
      (this.tai_temp !== undefined && this.tai_temp !== null) ||
      (this.local_temp !== undefined && this.local_temp !== null) ||
      (this.work_temp !== undefined && this.work_temp !== null) ||
      (this.return_temp !== undefined && this.return_temp !== null) ||
      (this.exch_heat_temp_iu !== undefined && this.exch_heat_temp_iu !== null) ||
      (this.gas_pipe_temp_iu !== undefined && this.gas_pipe_temp_iu !== null) ||
      (this.exp_valv_ui !== undefined && this.exp_valv_ui !== null) ||
      (this.cool_battery_temp !== undefined && this.cool_battery_temp !== null) ||
      (this.heat_battery_temp !== undefined && this.heat_battery_temp !== null) ||
      (this.consumption_ue !== undefined && this.consumption_ue !== null) ||
      (this.ext_temp !== undefined && this.ext_temp !== null) ||
      (this.exch_heat_temp_ue !== undefined && this.exch_heat_temp_ue !== null) ||
      (this.disch_comp_temp_ue !== undefined && this.disch_comp_temp_ue !== null) ||
      (this.exp_valv_ue !== undefined && this.exp_valv_ue !== null) ||
      (this.pe_ue !== undefined && this.pe_ue !== null) ||
      (this.pc_ue !== undefined && this.pc_ue !== null)


  }


  get hasAidooConfig() {

    return (this.window_sensor_conf !== undefined && this.window_sensor_conf !== null &&
      Array.isArray(this.window_sensor_values) && this.window_sensor_values.length > 0 )||
      (this.presence_sensor_conf !== undefined && this.presence_sensor_conf !== null &&
      Array.isArray(this.presence_sensor_values) && this.presence_sensor_values.length > 0) ||
      (this.offset_env_cool_conf !== undefined && this.offset_env_cool_conf !== null &&
      Array.isArray(this.offset_env_cool_values) && this.offset_env_cool_values.length > 0) ||
      (this.offset_env_heat_conf !== undefined && this.offset_env_heat_conf !== null &&
      Array.isArray(this.offset_env_heat_values) && this.offset_env_heat_values.length > 0) ||
      (this.eco_sensor_conf !== undefined && this.eco_sensor_values !== null &&
      Array.isArray(this.eco_sensor_values) && this.eco_sensor_values.length > 0) ||
      (this.m010_cold_constant_ventilation !== undefined && this.m010_cold_constant_ventilation !== null) ||
      (this.m010_heat_constant_ventilation !== undefined && this.m010_heat_constant_ventilation !== null) ||
      (this.basic_mode !== undefined && this.basic_mode !== null) ||
      (this.m010_min_voltage_conf !== undefined && this.m010_min_voltage_conf !== null &&
      Array.isArray(this.m010_min_voltage_values) && this.m010_min_voltage_values.length > 0) ||
      (this.m010_max_voltage_conf !== undefined && this.m010_max_voltage_conf !== null &&
      Array.isArray(this.m010_max_voltage_values) && this.m010_max_voltage_values.length > 0) ||
      (this.dualsp_auto_conf !== undefined && this.dualsp_auto_conf !== null) ||
      (this.autochange_diff_temp_conf !== undefined && this.autochange_diff_temp_conf !== null &&
      Array.isArray(this.autochange_diff_temp_values) && this.autochange_diff_temp_values.length > 0) ||
      (this.autochange_time_conf !== undefined && this.autochange_time_conf !== null &&
      Array.isArray(this.autochange_time_values) && this.autochange_time_values.length > 0) ||
      (this.fallback_conf !== undefined && this.fallback_conf !== null &&
      Array.isArray(this.fallback_values) && this.fallback_values.length > 0) ||
      (this.sp_limits_forced !== undefined && this.sp_limits_forced !== null) ||
      (this.min_cold_setpoint !== undefined && this.min_cold_setpoint !== null &&
      Array.isArray(this.min_cold_setpoint_values) && this.min_cold_setpoint_values.length > 0) ||
      (this.heating_available && this.max_heat_setpoint !== undefined && this.max_heat_setpoint !== null &&
      Array.isArray(this.max_heat_setpoint_values) && this.max_heat_setpoint_values.length > 0) ||
      (this.aq_high_conf !== undefined && this.aq_high_conf !== null &&
      this.range_aq_high_min !== undefined && this.range_aq_high_min !== null &&
      this.range_aq_high_max !== undefined && this.range_aq_high_max !== null) ||
      (this.aq_low_conf !== undefined && this.aq_low_conf !== null &&
      this.range_aq_low_min !== undefined && this.range_aq_low_min !== null &&
      this.range_aq_low_max !== undefined && this.range_aq_low_max !== null) ||
      (this.aq_sensor !== undefined && this.aq_sensor !== null && this.aq_sensor !== false)
  }

  get hasAqSensorConfig() {
    return this.aq_sensor !== undefined && this.aq_sensor !== null ||
    this.aq_vmc !== undefined && this.aq_vmc !== null ||
    this.aq_vent_const !== undefined && this.aq_vent_const !== null ||
    this.aq_vmc === true && this.aq_max_voltage_conf !== null && this.aq_max_voltage_conf !== undefined ||
    this.aq_vmc === true && this.aq_min_voltage_conf !== null && this.aq_min_voltage_conf !== undefined
  }

  //
  // Total. Si se muestra alguna de las secciones se muestra la pestaña de Ajustes
  //
  get hasAirtoolsSettings() {
    return this.hasAidooConfig || this.hasAqSensorConfig
  }

}


export default VMC;
