const setLoadingStatus = (context, status) => {
  context.commit('SET_LOADING_STATUS', status)
};
const setRenderStatus = (context, status) => {
  context.commit('SET_RENDER_STATUS', status)
};
const setIsUserAllowStatus = (context, status) => {
  context.commit('SET_ALLOW_STATUS', status)
};
const setExtConfigUrl = (context, status) => {
  context.commit('SET_EXT_CONFIG_URL', status)
};
const setIsThereNotch = (context, isThereNotch) => {
  context.commit('SET_IS_THERE_NOTCH', isThereNotch);
};
const setIsLocationActive = (context, isLocationActive) => {
  context.commit('SET_IS_LOCATION_ACTIVE', isLocationActive);
};
const setIsWithoutNotch = (context, isWithoutNotch) => {
  context.commit('SET_IS_WITHOUT_NOTCH', isWithoutNotch);
};
const setIsDemo = (context, setIsDemo) => {
  context.commit('SET_IS_DEMO', setIsDemo);
};
const setLogo = (context, logo) => {
  context.commit('SET_LOGO', logo);
}
const setSsidSafeFormat = (context, ssidSafeFormat) => {
  context.commit('SET_SSID_SAFE_FORMAT', ssidSafeFormat);
};
const setLastCoords = (context, lastCoords) => {
  context.commit('SET_LAST_COORDS', lastCoords);
}

export default {
  setLoadingStatus,
  setRenderStatus,
  setIsUserAllowStatus,
  setExtConfigUrl,
  setIsThereNotch,
  setIsLocationActive,
  setIsWithoutNotch,
  setIsDemo,
  setLogo,
  setSsidSafeFormat,
  setLastCoords
}
