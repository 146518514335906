import { mounted, unmounted } from 'vue';

export const infiniteScroll = {
  mounted(el, binding) {
    const callback = binding.value;
    let lastScrollTop = 0;

    const handleScroll = () => {
      const scrollTop = el.scrollTop;
      const scrollHeight = el.scrollHeight;
      const clientHeight = el.clientHeight;

      // Verificar si el usuario está haciendo scroll hacia abajo
      const isScrollingDown = scrollTop > lastScrollTop;

      if (isScrollingDown && scrollTop + clientHeight >= scrollHeight - 10) {
        callback();
      }

      // Guardamos la posición actual del scroll para futuras comparaciones
      lastScrollTop = scrollTop <= 0 ? 0 : scrollTop;  // Prevenir valores negativos en algunos navegadores
    };

    el.addEventListener('scroll', handleScroll);

    el._onScroll = handleScroll;  // Guardamos la referencia para poder remover el listener más tarde si es necesario
  },
  unmounted(el) {
    el.removeEventListener('scroll', el._onScroll);
  }
};
