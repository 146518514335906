import ApiService from 'Core/services/api.service'
import S3Service from 'Core/services/s3.service'
import CONSTANTS from 'Core/constant';
import i18n from 'Core/services/language.service';
import User from 'Auth/models/User'
import localUserData from 'Auth/services/localUserData.service';
import openAppStore from 'Core/utils/openAppStore.utils'
import PopupGlobal from 'Core/components/PopupGlobal';
import { compareVersions } from 'Core/utils/utils';
import packageInfo from '../../../../package.json';
import store from 'Core/store/store';

const AppManagerService = {
  /**
   *  Creates a new installation associated to a user. Webserver must be connected to the cloud
   *
   * @param {String} mac - MAC Address of the Webserver
   * @param {String} domain - Tipo de comando: ['ble']
   * @param {String} cmd - Comando enviado
   * @param {Object} payload - Datos enviados
   */
   createMetric(mac, domain, cmd, payload = null, coords = undefined){
    //
    // preparo los datos
    //
    const bodyParams = {
      domain,
      ts: new Date(),
      metric: {
        mac,
        cmd,
      }
    }

    if(coords) bodyParams.coords = coords;

    if (payload) {
      bodyParams.metric.payload = payload
    }

    try {
      //
      // Petición al backend para crear la installation
      //
      const response = ApiService.post('app-manager/metrics', bodyParams);

      return response;

    } catch ( error ) {
      console.log(error);
      return error
      // throw new UnitsError(error)
    }
  },

  /**
   * Comprueba si la app está en mantinimiento
   * @return {Boolean} True si está en mantenimiento y false en caso contrario
   */
  async isMaintenance() {
    try {
      await ApiService.get(`app-manager/status`)
    } catch(error) {
      if (error?.response?.status === 503 && error?.response?.data?._id === 'maintenanceModeOn') {
        return true
      }
    }

    return false;
  },

  /**
   * Descarga JSON de la integración de medidores
   * @return {Object} Objecto JSON con las integraciones posibles y sus medidores. String con eel tipo de error en caso contrario.
   */
   async getMetersConf() {

    try {
      const resp = await ApiService.get(`app-manager/resources?domain=common&resId=meters_conf.json`)
      // console.log(resp)
      const downloadURI = resp?.data[0]?.resUri
      const response = await S3Service.get(`${downloadURI}`)
      // console.log(response)
      return response?.data
    } catch(error) {
      console.log(error)
      if (error?.response?.status === 409 || error?.response.status === 500) {
        return error?.response?.data?._id
      }
    }

    return true
  },

  /**
   * Comprueba si hay que actualizar la app
   */
  async hasUpdateApp() {
    const isMobile = CONSTANTS.IS_MOBILE;

    if (isMobile && !store.getters.getIsDemo) {
      try {
        let lastVersion = false
        const responseUpdateApp = await ApiService.get(`app-manager/check-version?platform=${isMobile}&version=${packageInfo.version}`)
        // console.log('response check App status: ', responseUpdateApp)

        if (responseUpdateApp?.data?.lastVersion) {
          lastVersion = compareVersions(responseUpdateApp?.data?.lastVersion, packageInfo.version)
          // console.log('lastVersion:', lastVersion)
        }
        //
        // Inicializamos el servicio y preparamos el usuario para
        // almacenar la versión mostrada en local storage
        //
        localUserData.init();
        const user = User.query().first();

        // Si existe usuario en el modelo, compruebo si se ha mostrado la nueva versión con su email
        // Fix: si el user no existe en modelo no compruebo versión para que no de error de javascript
        // y deje la vista de la App en blanco
        if (user) {
          localUserData.checkUser(user?.email);
          const showAppUpdate = localUserData.getShowedVersion(user.email, responseUpdateApp?.data?.lastVersion)

          if (responseUpdateApp?.data?.force){
            PopupGlobal.notification({
              closeOnClick: false,
              src: 'onBoard/widgets.svg',
              title: i18n.global.t('home.invited_title'),
              message: `<div style="color: var(--color-content); max-width: 320px; margin: 0 auto; margin-bottom: 1.5rem; font-size: 1.44rem; font-weight:bold; text-align: center;">
                          ${i18n.global.t('home.updateApp.title')}
                      </div>`,
              otherMessage: i18n.global.t('home.updateApp.text'),
              // buttons: false,
              acceptBtnText: i18n.global.t('buttons.update'),
            })
            .then(() => {
              console.log('Redirect to app store', isMobile)
              openAppStore()
            })
          } else if (lastVersion > 0 && responseUpdateApp?.data?.lastVersion && !showAppUpdate){
            PopupGlobal.create({
              src: 'onBoard/widgets.svg',
              title: i18n.global.t('home.invited_title'),
              message: `<div style="color: var(--color-content); max-width: 320px; margin: 0 auto; margin-bottom: 1.5rem; font-size: 1.44rem; font-weight:bold; text-align: center;">
                          ${i18n.global.t('home.updateApp.title')}
                          <br><br>
                          ${responseUpdateApp?.data?.lastVersion}
                        </div>`,
              otherMessage: responseUpdateApp?.data?.notes[i18n.global.locale.value],
              buttons: true,
              // cancelBtn: false,
              acceptBtnText: i18n.global.t('buttons.update'),
              cancelBtnText: i18n.global.t('buttons.cancel'),
            }).then(() => {
              // Redirecciono al store de la app
              openAppStore()
            })
            .catch(() => {
              console.log('Hide')
            })
            // Guardamos la version mostrada en el email del storage
            localUserData.setShowedVersion(user.email, responseUpdateApp?.data?.lastVersion);
          }
        }
      } catch(err) {
        console.log(err)
        if (CONSTANTS.IS_MOBILE) {
          window.addEventListener('deviceready', async () => {
            // Comprobamos: Si ha habido un error en alguna petición por backendDown
            // if (reason === 'backendDown') {
            //   this.$router.push({name: 'error', query: { reason: 'backendDown' } }).catch(() => {})
            // }

            // Check if mobile has output to interntet
            // eslint-disable-next-line no-undef
            await WifiWizard2.isConnectedToInternet().then(() => {
              console.log('Tiene internet, seguimos la ejecución')
            }).catch(error => {
              console.log('En aap.manager.service.js => No tiene salida a internet. no envío al login, lo envío a la página de error', err)
              // NOTA: si no está soportada la función no hacemos nada de intentos de reconexión
              if(error !== 'Not supported' && error !== 'WIFI_NOT_ENABLED') {
                return this.$router.push({name: 'error', query:  { reason: 'notConnectedToInternet' } }).catch(() => {})
              }
              return false
            })
          });
        }
      }
    }
  },

  /**
   * Obtiene el mapa modbus de la marca, modelo y referencia pasadas por parámetro
   * @param {String} compatibilityProtocol - Protocolo compatible
   * @return {String} Identificador de la marca
   */
  async getUniversalModbusManufacturers(compatibilityProtocol, mac) {
    try {
      const apiUrl = `app-manager/manufacturers?compatibilityProtocol=${compatibilityProtocol}&ws_id=${mac}`

      const response = await ApiService.get(apiUrl)
      // console.log(response?.data)
      return response?.data?.brands
    } catch(error) {
      console.log(error)
      return error
    }
  },

  /**
   * Obtiene el mapa modbus de la marca, modelo y referencia pasadas por parámetro
   * @param {String} compatibilityProtocol - Protocolo compatible
   * @param {String} brandId - Identificador de la mara
   * @return {Array} Array con las referencias compatibles
   */
  async getUniversalModbusReferences(compatibilityProtocol, brandId, mac) {
    try {
      const apiUrl = `app-manager/references?compatibilityProtocol=${compatibilityProtocol}&brandId=${brandId}&ws_id=${mac}`

      const response = await ApiService.get(apiUrl)
      // console.log(response)
      return response?.data?.models
    } catch(error) {
      console.log(error)
      return error
    }
  },

  /**
   * Obtiene el mapa modbus de la marca, modelo y referencia pasadas por parámetro
   * @param {String} brandId - Identificador de la mara
   * @param {String} machineVersion - Versión de la máquina
   * @param {String} modelId - Identificador del modelo
   * @param {String} modbusMapCode - Código del mapa modbus
   * @return {Object} Objeto con el mapa modbus
   */
  async getUniversalModbusMap(brandId, machineVersion, modelId, modbusMapCode, mac) {
    try {
      let apiUrl = 'app-manager/mmapping?'
      if(brandId) {
        apiUrl += `brandId=${brandId}&`;
      }
      if(machineVersion) {
        apiUrl += `machineVersion=${machineVersion}&`;
      }
      if(modelId) {
        apiUrl += `modelId=${modelId}&`;
      }
      if(modbusMapCode) {
        apiUrl += `modbusMapCode=${modbusMapCode}&`;
      }
      if(mac) {
        apiUrl += `ws_id=${mac}`;
      }
      const response = await ApiService.get(apiUrl)
      // console.log(response)
      return response
    } catch(error) {
      console.log(error)
      throw error
    }
  },
}

export default AppManagerService;
