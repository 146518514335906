import i18n from 'Core/services/language.service';


  const getWheatherStatus = {
    200: {
      icon: 'chance-storm',
      icon_night: 'chance-storm-night',
      status: 'thunderstorm',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.thunderLightRain')
    },
    201: {
      icon: 'chance-storm',
      icon_night: 'chance-storm-night',
      status: 'thunderstorm',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.thunderRain')
    },
    202: {
      icon: 'thunderstorm',
      status: 'thunderstorm',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.thunderHeavyRain')
    },
    210: {
      icon: 'thunderstorm',
      status: 'thunderstorm',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.thunderlight')
    },
    211: {
      icon: 'thunderstorm',
      status: 'thunderstorm',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.thunder')
    },
    212: {
      icon: 'thunderstorm',
      status: 'thunderstorm',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.thunderHeavy')
    },
    221: {
      icon: 'thunderstorm',
      status: 'thunderstorm',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.thunderRagged')
    },
    230: {
      icon: 'thunderstorm',
      status: 'thunderstorm',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.thunderlightDrizzle')
    },
    231: {
      icon: 'thunderstorm',
      status: 'thunderstorm',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.thunderDrizzle')
    },
    232: {
      icon: 'thunderstorm',
      status: 'thunderstorm',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.thunderHeavyDrizzle')
    },
    300: {
      icon: 'light-rain',
      status: 'drizzel',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.drizzleLight')
    },
    301: {
      icon: 'light-rain',
      status: 'drizzel',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.drizzle')
    },
    302: {
      icon: 'medium-rain',
      status: 'drizzel',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.drizzleHeavy')
    },
    310: {
      icon: 'light-rain',
      status: 'drizzel',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.drizzleLightRain')
    },
    311: {
      icon: 'light-rain',
      status: 'drizzel',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.drizzleRain')
    },
    312: {
      icon: 'medium-rain',
      status: 'drizzel',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.drizzleHeavyRain')
    },
    313: {
      icon: 'medium-rain',
      status: 'drizzel',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.DrizzleShowerRain')
    },
    314: {
      icon: 'rain',
      status: 'drizzel',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.drizzleHeavyShowerRain')
    },
    321: {
      icon: 'medium-rain',
      status: 'drizzel',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.drizzleShower')
    },
    500: {
      icon: 'chance-light-rain',
      icon_night: 'chance-light-rain-night',
      status: 'rain',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.rainLight')
    },
    501: {
      icon: 'chance-rain',
      icon_night: 'chance-rain-night',
      status: 'rain',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.rainModerate')
    },
    502: {
      icon: 'rain',
      status: 'rain',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.rainHeavy')
    },
    503: {
      icon: 'rain',
      status: 'rain',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.rainVeryHeavy')
    },
    504: {
      icon: 'rain',
      status: 'rain',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.rainExtreme')
    },
    511: {
      icon: 'snow',
      status: 'rain',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.rainFreezing')
    },
    520: {
      icon: 'rain',
      status: 'rain',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.rainLightShower')
    },
    521: {
      icon: 'rain',
      status: 'rain',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.rainShower')
    },
    522: {
      icon: 'rain',
      status: 'rain',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.rainHeavyShower')
    },
    531: {
      icon: 'rain',
      status: 'rain',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.rainRaggedShower')
    },
    600: {
      icon: 'light-snow',
      status: 'snow',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.snowLight')
    },
    601: {
      icon: 'snow',
      status: 'snow',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.snow')
    },
    602: {
      icon: 'snow',
      status: 'snow',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.snowHeavy')
    },
    611: {
      icon: 'sleet',
      status: 'snow',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.snowSleet')
    },
    612: {
      icon: 'sleet',
      status: 'snow',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.snowLightShowerSleet')
    },
    613: {
      icon: 'sleet',
      status: 'snow',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.snowShowerSleet')
    },
    615: {
      icon: 'snow',
      status: 'snow',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.snowLightRain')
    },
    616: {
      icon: 'snow',
      status: 'snow',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.snowRain')
    },
    620: {
      icon: 'snow',
      status: 'snow',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.snowLightShower')
    },
    621: {
      icon: 'snow',
      status: 'snow',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.snowShower')
    },
    622: {
      icon: 'snow',
      status: 'snow',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.snowHeavyShower')
    },
    701: {
      icon: 'hazy',
      status: 'atmosphere',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.mist')
    },
    711: {
      icon: 'hazy',
      status: 'atmosphere',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.smoke')
    },
    721: {
      icon: 'hazy',
      status: 'atmosphere',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.haze')
    },
    731: {
      icon: 'hazy',
      status: 'atmosphere',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.dustSand')
    },
    741: {
      icon: 'hazy',
      status: 'atmosphere',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.dust')
    },
    751: {
      icon: 'fog',
      status: 'atmosphere',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.fog')
    },
    761: {
      icon: 'hazy',
      status: 'atmosphere',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.sand')
    },
    762: {
      icon: 'hazy',
      status: 'atmosphere',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.ash')
    },
    771: {
      icon: 'hazy',
      status: 'atmosphere',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.squall')
    },
    781: {
      icon: 'tornado',
      status: 'atmosphere',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.tornado')
    },
    800: {
      icon: 'clear-day',
      icon_night: 'clear-night',
      status: 'clear',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.clear')
    },
    801: {
      icon: 'partly-cloudy-day',
      icon_night: 'partly-cloudy-night',
      status: 'clouds',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.cloudsFew')
    },
    802: {
      icon: 'partly-cloudy-day',
      icon_night: 'partly-cloudy-night',
      status: 'clouds',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.cloudsScattered')
    },
    803: {
      icon: 'partly-cloudy-day',
      icon_night: 'partly-cloudy-night',
      status: 'clouds',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.cloudsBroken')
    },
    804: {
      icon: 'cloudy',
      status: 'clouds',
      desc: () =>  i18n.global.t('weather.descriptionActual.description.cloudsOvercast')
    },
  }

  export default getWheatherStatus;
