

const UPDATE_IS_BIOMETRIC_AVAILABLE = (state, status) => {
  state.isBiometricAvailable = status;
}

const UPDATE_IS_BIOMETRIC_AUTH_OPEN = (state, status) => {
  state.isBiometricAuthOpen = status;
}

const UPDATE_BIOMETRIC_MODE = (state, status) => {
  state.biometricMode = status;
}

const UPDATE_BIOMETRIC_TYPE = (state, type) => {
  state.biometricType = type;
}

const UPDATE_IS_SKIN_ACTIVE = (state, status) => {
  state.isSkinActive = status;

}

const UPDATE_IS_NOTIFICATION_SERVICE_BROKEN = (state, status) => {
  state.isNotificationServiceBroken = status;
}

export default {
  UPDATE_IS_BIOMETRIC_AVAILABLE,
  UPDATE_IS_BIOMETRIC_AUTH_OPEN,
  UPDATE_BIOMETRIC_MODE,
  UPDATE_BIOMETRIC_TYPE,
  UPDATE_IS_SKIN_ACTIVE,
  UPDATE_IS_NOTIFICATION_SERVICE_BROKEN
}
