const SET_LOADING_STATUS = (state, status) => {
  state.loading = status
};
const SET_RENDER_STATUS = (state, status) => {
  state.renderApp = status
};
const SET_ALLOW_STATUS = (state, status) => {
  state.isUserAllow = status
};
const SET_EXT_CONFIG_URL = (state, status) => {
  state.extConfigUrl = status
};
const TOGGLE_SIDENAV = (state) => {
  state.isShowSidenav = !state.isShowSidenav;
};
const SET_IS_THERE_NOTCH = (state, isThereNotch) => {
  state.isThereNotch = isThereNotch;
};
const SET_IS_LOCATION_ACTIVE = (state, isLocationActive) => {
  state.isLocationActive = isLocationActive;
};
const SET_IS_WITHOUT_NOTCH = (state, isWithoutNotch) => {
  state.isWithoutNotch = isWithoutNotch;
};
const SET_IS_DEMO = (state, isDemo) => {
  state.isDemo = isDemo;
};
const SET_LOGO = (state, logo) => {
  state.logo = logo;
}
const SET_SSID_SAFE_FORMAT = (state, ssidSafeFormat) => {
  state.ssidSafeFormat = ssidSafeFormat;
};
const SET_LAST_COORDS = (state, lastCoords) => {
  state.lastCoords = lastCoords;
}

export default {
  SET_LOADING_STATUS,
  SET_RENDER_STATUS,
  SET_ALLOW_STATUS,
  SET_EXT_CONFIG_URL,
  TOGGLE_SIDENAV,
  SET_IS_THERE_NOTCH,
  SET_IS_LOCATION_ACTIVE,
  SET_IS_WITHOUT_NOTCH,
  SET_IS_DEMO,
  SET_LOGO,
  SET_SSID_SAFE_FORMAT,
  SET_LAST_COORDS
}
