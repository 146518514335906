/**
 * Params que deben inicializarse y mantenerse estables tras reset de Device en BLE
 */
const AVAILABLES_DEVICE_STABLE_FIELDS = [
      'id',
      'type',
      'name',
      'system_number',
      'zone_number',
      'group_id',
      'webserver_id',
      'device_type',
      'machine_units',
      'new_virtual_zones_allowed',
      'units',
      'macBLE',
      'ws_sched_available',
      'ws_sched_calendar_available',
      'ws_sched_param_indep',
      'acs_sched_available',
      'aq_sensor',
      'aq_present',
      'aq_hum_thres_range_min',
      'aq_hum_thres_range_max',
      'aq_hum_thres_range_step',
      'aq_co2_thres_range_min',
      'aq_co2_thres_range_max',
      'aq_co2_thres_range_step',
      'aq_pm2_5_thres_range_min',
      'aq_pm2_5_thres_range_max',
      'aq_pm2_5_thres_range_step',
      'aq_pm10_thres_range_min',
      'aq_pm10_thres_range_max',
      'aq_pm10_thres_range_step',
      'aq_tvoc_thres_range_min',
      'aq_tvoc_thres_range_max',
      'aq_tvoc_thres_range_step',
      'phase_reverse',
      'device_name',
      'energy_measurement_enabled',
    ]

const deviceInterface = {

  deviceBleStableParams: {
    /**
     *
     * @param {Object} dataObject
     */
    validate: dataObject => {

      for(let i=0; i < AVAILABLES_DEVICE_STABLE_FIELDS.length; i++){
        if(!Object.prototype.hasOwnProperty.call(dataObject, AVAILABLES_DEVICE_STABLE_FIELDS[i])){
          throw new Error(`Param not included in 'deviceBleStableParams': ${AVAILABLES_DEVICE_STABLE_FIELDS[i]}`);
        }
      }

      return true;
    }
  }
}

export default deviceInterface;
