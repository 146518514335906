
import CONSTANTS from 'Units/constant';
import CORE from 'Core/constant';
import AppError from 'Core/services/errors.service';
import log from 'Core/services/log.service';
// import { isASCII } from 'Core/utils/utils';
import { isValidSSID } from 'Core/utils/utils';
import { hexToText, bytesToHex } from 'Core/utils/utils';



class ApiBleService  {

  constructor() {
    this.bleVersion = 0;
    this.previusPackage = null
    this.attempsReadBuffer = 0
    this.attempsConnectingToWrite = 0
    this.deviceType = null
    this.mtu = null
  }

  clearPackageBuffer = () => {
    this.previusPackage = null;
  }

  /*
  * Check if bluetooth is Enabled
  *
  * @return {Promise}           - Promise object with result of check if device bluetooth is enabled
  * @throws {bluetoothDisabled} - Device bluetooth is Disabled
  */
  isBluetoothEnabled = () => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      window.ble.isEnabled(
        () => resolve(true),
        () => {
          reject(new AppError('bluetoothDisabled', 'Device bluetooth is Disabled'));
        }
      );
    });
  }

  /*
 * Check if location is Enabled
 *
 * iOS device activate by default the location when the bluetooth is activated
 *
 * @return {Promise}              - Promise object with result of check if device location is enabled
 * @throws {LocationDisabled}     - Device location is Disabled
 * @throws {errorLocationEnabled} - Error trying to check if the device location is Enabled
 */
isLocationEnabled = () => {
  return new Promise((resolve, reject) => {
    // eslint-disable-next-line
    // if (window.device.platform === 'iOS') {
      // console.log('Check isLocationEnabled in iOS', )
      // resolve(true);
      // return;
    // }

    // eslint-disable-next-line
    cordova.plugins.diagnostic.isLocationEnabled(
      enabled => {
        if (enabled) {
          resolve(true);
        } else {
          reject(new AppError('locationDisabled', 'Device location is Disabled'));
        }
      },
      error => {
        reject(new AppError('errorLocationEnabled', error));
      }
      );
    });
  }

    /*
  * Check if location is Available
  *
  * iOS device activate by default the location when the bluetooth is activated
  *
  * @return {Promise}                - Promise object with result of check if device location is available
  * @throws {LocationNotAvailable}   - Device location is not Available
  * @throws {errorLocationAvailable} - Error trying to check if the device location is Available
  * @throws {errorAskActiveLocation} - Error trying to ask the user if activate the location
  */
  isLocationAvailable = () => {
    return new Promise((resolve, reject) => {
      // eslint-disable-next-line
      // if (window.device.platform === 'iOS') {
        // console.log('Check isLocationAvailable in iOS', )
        // resolve(true);
        // return;
      // }

      // eslint-disable-next-line
      cordova.plugins.diagnostic.isLocationAvailable(
        available => {
          if (available) {
            resolve(true);
          } else {
            // Ask the user if he want available location
            // eslint-disable-next-line
            cordova.plugins.diagnostic.requestLocationAuthorization(
              status => {
                if (
                  // eslint-disable-next-line
                  status === cordova.plugins.diagnostic.permissionStatus.GRANTED
                ) {
                  resolve(true);
                } else {
                  reject(new AppError('locationNotAvailable', 'Device location isn´t Available'));
                }
              },
              error => {
                reject(new AppError('errorAskActiveLocation', error));
              }
            );
          }
        },
        error => {
          reject(new AppError('errorLocationAvailable', error));
        }
      );
    });
  }

  /*
  * Search devices by bluetooth
  *
  * The succees method is called every time a new device is founded
  *
  * @constant {DEVICES_PREFIXES}      - String with prefix of device to filter devices found
  * @constant {MIN_LEVEL_SIGNAL}      - Minimum level of the signal, avoid showing devices too far away that can fail in communications
  * @constant {MAX_TIME_SCAN_DEVICES} - Device search time
  * @param  {function}                - Callback function when a new device is found
  * @return {Promise}                 - Promise object with each device founded
  * @throws {errorStartScan}          - Error trying to scan devices by bluetooth
  * @throws {errorStopScan}           - Error trying to stop scan devices by bluetooth
  */
  scanDevicesBluetooth = (callback, devicePrefixes) => {
    return new Promise((resolve, reject) => {
      window.ble.startScan(
        [],
        device => {
          if (
            device.name &&
            devicePrefixes.some(prefix => device.name.startsWith(prefix)) &&
            device.rssi > CONSTANTS.BLUETOOTH.MIN_LEVEL_SIGNAL[CORE.IS_MOBILE]
          ) {
            // Change name property to fix conflict when associate device
            console.log(device);
            device.deviceName = device.name;
            device.name = '';
            callback(device);
          }
        },
        () => {
          log.error('Error intentando buscar dispositivos');
          reject(new AppError('errorStartScan', 'Error trying to scan devices by bluetooth'));
        }
      );

      /*
      * Stop scan devices after time
      */
      setTimeout(() => {
        window.ble.stopScan(
          () => {
            resolve(true);
          },
          () => {
            log.error('Error intentando parar la busqueda de dispositivos');
            // prettier-ignore
            reject(new AppError('errorStopScan', 'Error trying to try stop scan devices by bluetooth'));
          }
        );
      }, CONSTANTS.BLUETOOTH.MAX_TIME_SCAN_DEVICES);
    });
  }

  /*
  * Search devices by bluetooth
  *
  * The succees method is called every time a new device is founded
  *
  * @constant {DEVICES_PREFIXES}      - String with prefix of device to filter devices found
  * @constant {MIN_LEVEL_SIGNAL}      - Minimum level of the signal, avoid showing devices too far away that can fail in communications
  * @constant {MAX_TIME_SCAN_DEVICES} - Device search time
  * @param  {function}                - Callback function when a new device is found
  * @return {Promise}                 - Promise object with each device founded
  * @throws {errorStartScan}          - Error trying to scan devices by bluetooth
  * @throws {errorStopScan}           - Error trying to stop scan devices by bluetooth
  */
  searchDevice = deviceName => {
    return new Promise((resolve, reject) => {
      let isDeviceFound = false;

      window.ble.startScan(
        [],
        device => {
          if (device.name && device.name === deviceName && device.rssi > CONSTANTS.BLUETOOTH.MIN_LEVEL_SIGNAL[CORE.IS_MOBILE]) {
            isDeviceFound = true;
            resolve(device.id);
          }
        },
        () => {
          reject(new AppError('errorStartScan', 'Error trying to scan devices by bluetooth'));
        }
      );

      /*
      * Stop scan devices after time
      */
      setTimeout(() => {
        window.ble.stopScan(
          () => {
            if (isDeviceFound) {
              resolve(true);
            } else {
              console.log("Error en 'api.ble.service > searchDevice [1]'")
              // prettier-ignore
              reject(new AppError('unableConnectDevice', 'Unable connect to device'));
            }
          },
          () => {
            console.log("Error en 'api.ble.service > searchDevice [2]'")
            // prettier-ignore
            reject(new AppError('unableConnectDevice', 'Unable connect to device'));
          }
        );
      }, CONSTANTS.BLUETOOTH.MAX_TIME_SCAN_DEVICES);
    });
  }

  /*
  * Connect to device
  *
  * @param  {strint}           - Device id
  * @return {Promise}          - Promise with result of connect device
  * @throws {unableConnectDevice} - Unable connect to device
  */
  connectDevice = deviceID => {
    return new Promise((resolve, reject) => {
      window.ble.isConnected(
        deviceID,
        () => {
          // console.log(" **** Device already connected ****");
          resolve(true)
        },
        async () => {
          //
          // Si no se ejecuta la conexión, forzamos desconexión e intentamos
          // reconectar de nuevo a los 15 seg.
          //
          const retry = setTimeout(async () => {

            this.disconnectDevice(deviceID);
            // return this.connectDevice(deviceID);
            reject(new AppError('unableConnectDevice', 'Unable connect to device'));
          },15000);

          console.log("connectDevice", deviceID);

          await window.ble.connect(
            deviceID,
            async () => {
              clearTimeout(retry); // Si hemos conectado cancelamos el reintento
              // Si hemos perdido la conexión y volvemos a conectar, solicitamos el MTU si es necesario
              if(this.deviceType && window.device.platform === 'Android' && (CONSTANTS.BLUETOOTH.DEVICE_TYPES_FORCE_REQUESTMTU_BUFFERSIZE.includes(this.deviceType) || this.mtu === true)) {
                try {
                  await this.requestMtu(deviceID);
                } catch (error) {
                  console.log("error requesting mtu", error);
                }
              }
              resolve(true);
            },
            () => {
              clearTimeout(retry); // Si ocurre un error cancelamos el reintento (?)
              console.log("Error en 'api.ble.service > connectDevice'")
              reject(new AppError('unableConnectDevice', 'Unable connect to device'));
            }
          );
        }
      );
    });
  }

  requestMtu = deviceID => {
    return new Promise((resolve, reject) => {
      console.log("requestMTU")
      window.ble.requestMtu(deviceID, CONSTANTS.BLUETOOTH.REQUEST_MTU_BUFFER_SIZE, // <- NOTA: forzamos el mtu al máximo que soporta Android (actualmente 517 bytes)
      mtu => {
        console.log("ok mtu", mtu);
        if(this.mtu !== true) this.mtu = true;
        resolve(true);
      },
      error => {
        this.mtu = null;
        reject(error);
      });
    });
  }

  /*
  * Convert String of HEX to string UTF8
  *
  * @param  {String}        - String in HEX
  * @return {JSON}               - JSON with data from device
  * @throws {errorBytesToString} - Error when try pass bytes to string
  */
  bytesToStringUTF8 = string => {

    // const string = String.fromCharCode.apply(null, buffer);
    let decodedString;
    try {
      // decodedString = decodeURIComponent(string.replace(/[0-9a-f]{2}/g, '%$&'));
      decodedString = hexToText(string, 'utf-8'); // <- Utilizamos función de utilidades para convertir HEX a UTF8 En comandos BLE siempre vendrán en UTF8
    } catch( err ) {
      throw new AppError('URImalformed');
    }
    return decodedString;
  }

  /*
  * Convert ArrayBuffer to string
  *
  * @param  {ArrayBuffer}        - Array Buffer with data from device
  * @return {JSON}               - JSON with data from device
  * @throws {errorBytesToString} - Error when try pass bytes to string
  */
  bytesToString = buffer => {

    const string = String.fromCharCode.apply(null, buffer);
    const clearString = string.replace(/\n/g, '');
    return clearString;
    // resolve(JSON.parse(clearString));

  }

  /**
   * Convert data to ArrayBuffer
   * @param {string} string
   */
  stringToBytes = string => {

    const array = new Uint8Array(string.length);

    for (let i = 0, l = string.length; i < l; i += 1) {
      array[i] = string.charCodeAt(i);
    }

    return array.buffer;

  }

  /**
   *
   * @param {string} deviceID
   * @param {string} command
   * @param {string} cmdName - Nombre del comando enviado
   * @returns {Promise<boolean>}
   */
  sendCommand = (deviceID, command) => {

    this.previusPackage = command;
    let timeoutExceded = false;

    // console.log("Writing BLE", command, deviceID);
    const startingWrite = Date.now();

    //
    // EXLUSIÓN de comandos que permiten reintentos.
    // Si existe algún comando que da problemas con reintento, como el cmd: "connect" de
    // primeras versiones de WS, que bloquean hilo de ejecución tras la escritura y lanzan
    // un error 14 en nuestra librería BLE.
    //
    let writeRetry = true;

    for(let i=0; i < CONSTANTS.BLUETOOTH.RETRY_EXCLUDE_CMD.length; i++) {
      const cmd = CONSTANTS.BLUETOOTH.RETRY_EXCLUDE_CMD[i];
      if(command.includes(cmd)) {
        writeRetry = false;
        break;
      }

    }
    // --- Flujo normal de ejecución ---
    console.log("Starting write", new Date(startingWrite))
    return new Promise((resolve, reject) => {

      const dataBuffer = this.stringToBytes(command);

      // Lanzamos un error a los 5 segundos si no recibimos ningún
      // callback por parte de la escritura (El comando escritura se bloquea)

      const timeout = setTimeout(() => {
        if(writeRetry) {
          timeoutExceded = true;
          return reject(new AppError('timeout', 'Error en la escritura de datos. Agotado tiempo de espera.'));
        }
      }, 15000);



      // window.ble.writeWithoutResponse(
      window.ble.write(
        deviceID,
        CONSTANTS.BLUETOOTH.SERVICE_UUID,
        CONSTANTS.BLUETOOTH.CHARACTERISTIC_UUID,
        dataBuffer,
        () => {
          if(timeoutExceded) return;
          console.log("Finish write", (Date.now() - startingWrite) / 1000);
          clearTimeout(timeout);
          resolve(true)
        },
        // error callback
        async error => {
          console.log("Error en writeDevice", error);

          if(timeoutExceded) return;
          clearTimeout(timeout);
          //
          // NOTA: El bloqueo del hilo por parte del WS para procesar el "cmd":"connect" provoca un error 14
          // que debemos ignorar y continuar la ejecución y lectura de la respuesta.
          // - La librería BLE nos devuelve error 14 en este caso.
          // - En iOS el error 14 se devuelve como "Unlikely error"
          // - En webservers sin líneas que corrigen error de escritura ble en iOS, podemos obtener también error "Peripheral disconnected"
          // - Queremos manejarlo en caso de comandos controlados (los de BLUETOOTH.RETRY_EXCLUDE_CMD )
          //
          if(error === 14 ||
            error === "Unlikely error." ||
            (error === "Peripheral disconnected" && window.device.platform === 'iOS') ||
             error?.errorMessage === 'Peripheral Disconnected')
            {
              console.log("Error no crítico. Continuamos ejecución")
              resolve(true);
            } else {
              await this.disconnectDevice(deviceID);
              reject(new AppError('errorGetDeviceInfo'));
            }
        }
      );
    });
  }

  writeBLE = (deviceID, dataBuffer) => {
    return new Promise((resolve, reject) => {

      console.log("Writing BLE", deviceID, dataBuffer);
      const startingWrite = Date.now();
      // Lanzamos un error a los 5 segundos si no recibimos ningún
      // callback por parte de la escritura (El comando escritura se bloquea)

      // const timeout = setTimeout(() => {
      //   if(writeRetry) {
      //     reject(new AppError('unableConnectDevice', 'Error en la escritura de datos. Agotado tiempo de espera.'));
      //   }
      // }, 5000);

      window.ble.write(
        deviceID,
        CONSTANTS.BLUETOOTH.SERVICE_UUID,
        CONSTANTS.BLUETOOTH.CHARACTERISTIC_UUID,
        dataBuffer,
        () => {
          console.log("Finish write", (Date.now() - startingWrite) / 1000);
          // clearTimeout(timeout);
          resolve(true)
        },
        // error callback
        async error => {
          console.log("Error en writeDevice", error);
          // clearTimeout(timeout);
          //
          // NOTA: El bloqueo del hilo por parte del WS para procesar el "cmd":"connect" provoca un error 14
          // que debemos ignorar y continuar la ejecución y lectura de la respuesta.
          // - La librería BLE nos devuelve error 14 en este caso.
          // - En iOS el error 14 se devuelve como "Unlikely error"
          // - En webservers sin líneas que corrigen error de escritura ble en iOS, podemos obtener también error "Peripheral disconnected"
          // - Queremos manejarlo en caso de comandos controlados (los de BLUETOOTH.RETRY_EXCLUDE_CMD )
          //
          if(error === 14 ||
            error === "Unlikely error." ||
            (error === "Peripheral disconnected" && window.device.platform === 'iOS') ||
             error?.errorMessage === 'Peripheral Disconnected')
            {
              console.log("Error no crítico. Continuamos ejecución")
              resolve(true);
            } else {
              await this.disconnectDevice(deviceID);
              reject(new AppError('errorGetDeviceInfo'));
            }
        }
      );

    })
  }

  /**
   * Realiza una lectura de lo último que tiene el buffer del dispositivo.
   * (Sin comprobar si pertenece a una petición anterior)
   *
   * @param {deviceID} deviceID
   * @returns {Promise}
   */
  readDeviceBuffer = deviceID => {
    const startDate = Date.now();
    console.log("Starting read in readDeviceBuffer", new Date(startDate));
    let timeoutExceded = false;

    //
    // Read without token
    //
    return new Promise((resolve, reject) => {
      //
      // Lanzamos un error a los 20 segundos si no recibimos ningún
      // callback por parte de la lectura (El comando lectura se bloquea)
      //
      // IMPORTANTE: No bajar el tiempo de lanzamiento del error por bloqueo en lectura!!
      // Algunos comandos como "cmd":"connect", que ignora confirmación de escritura y tarda
      // en recibir lectura podrían fallar si lanzamos el error demasiado pronto.
      //
      const timeout = setTimeout(() => {
        timeoutExceded = true;
        return reject(new AppError('timeout', 'Error en la lectura de datos. Agotado tiempo de espera.'));
      }, 20000);


      window.ble.read(
        deviceID,
        CONSTANTS.BLUETOOTH.SERVICE_UUID,
        CONSTANTS.BLUETOOTH.CHARACTERISTIC_UUID,
        data => {
          if(timeoutExceded) return;
          clearTimeout(timeout);
          this.attempsReadBuffer = 0;
          // const buffer = new Uint8Array(data).buffer
          // console.log('BLE init DATA ', data);
          // console.log('bytes_LENGTH: ', data.byteLength);
          // console.log('IS_MTU: ', this.mtu);
          const rawData = new Uint8Array(data).slice(0, CONSTANTS.BLUETOOTH.BUFFER_SIZE);
          // console.log('second: ', this.buf2hex(rawData))
          // Si el dispositivo devuelve
          if(rawData.length <= 1 && rawData[0] === 0) {
            return reject(new AppError('commandBleNotSupported'));
          }
          // console.log('Bytes read: ', rawData)
          // const deviceData = this.bytesToString(rawData).trim();
          // const hexData = this.buf2hex(rawData);
          const hexData = bytesToHex(rawData);
          // console.log("HEX_DATA: ",hexData);
          let deviceData
          try {
            deviceData = this.bytesToStringUTF8(hexData).trim();
          } catch (err) {
            return reject(err);
          }

          //
          // Si la ble version es menor de 3 hacemos la comprobación de carácteres especiales para evitar basura que pueda venir
          // del buffer y reintentar.
          //
          if(this.bleVersion < 3) {
            // NOTA! La validación la hacemos menos restrictiva, basta con que tengamos cualquier carácter en el json que permita un SSID válido.
            // Así incluímos los json que vengan con SSID o con nombres de Zona con carácteres permitidos.
            if(isValidSSID(deviceData)) {
            // if( isASCII(deviceData)) {
              return resolve(deviceData);
            }

            console.error('Error: la respuesta incluye carácteres no válidos', deviceData);
            return reject(new AppError('errorGetDeviceInfo'));

          }

          return resolve(deviceData);



        },
        async error => {
          console.log("Error en readDeviceBuffer", error);
          if(timeoutExceded) return;
          clearTimeout(timeout);
          //
          // Si tengo error al leer el buffer pruebo a comprobar estado de conexión ble
          // y devuelvo el estado actual del buffer para volver a hacer otro intento.
          //
          if(this.attempsReadBuffer <= CONSTANTS.BLUETOOTH.MAX_READ_BUFFER_ATTEMPS) {
            try {
              await this.isBluetoothEnabled();
              await this.isLocationEnabled();
              await this.isLocationAvailable();
              await this.connectDevice(deviceID);
              this.attempsReadBuffer++;
              console.log("Intento lectura", this.attempsReadBuffer);
              resolve(this.previusPackage);
            } catch( errorConnection ) {
              console.log("Error tratando de reconectar dispositivo en readBuffer", errorConnection);
              // reject(errorConnection)
              return reject(new AppError('errorGetDeviceInfo', errorConnection))
            }

          } else {
            this.attempsReadBuffer = 0;
          }

          return reject(new AppError('errorGetDeviceInfo', error));
          // reject(error);
        }
      );
    });
  }

  buf2hex = buffer => { // buffer is an ArrayBuffer
    return [...new Uint8Array(buffer)]
        .map(x => x.toString(16).padStart(2, '0'))
        .join('');
  }

  /*
  * Read characteristic from device by Bluetooth
  *
  * @param  {String}          - Device ID
  * @return {Promise}         - Result of read device
  * @throws {errorReadDevice} - Error when try read from device
  */
  readPackage = async deviceID =>  {
    try {
      //
      // Leemos el buffer del dispositivo
      //
      const deviceData = await this.readDeviceBuffer(deviceID)

      console.log("DEVICE INFO (lectura): ", deviceData);

      //
      // Si el buffer contiene la información del command enviado, entonces no ha habido cambios
      //
      if( deviceData === this.previusPackage) {
        console.log("No changes in buffer");

        // Si no se produce cambio en buffer en un comando next lanzamos error para reintentar todo el proceso.
        // NOTA: El comando next debe producir un cambio en buffer en cada petición, si no ocurre ha habido un error en lectura
        if(deviceData === '{"next": {} }') {
          throw new AppError('noChangesInBuffer')
        }
        const resp = {
          packageData: deviceData,
          isValid: false
        }
        return resp;
      }
      const resp = {
        packageData: deviceData,
        isValid: true,
      }
      return resp

    } catch( error ){
      console.log("Error en readPackage", error)
      throw error;
    }
  }

  /**
  * Lee la respuesta completa de un dispositivo tras emitir un comando.
  * Este método se encarga de procesar todos los paquetes de 512 bytes recibidos
  * (en caso de haberlos) y devolver ya un JSON bien formado.
  *
  * @param {String} deviceID  Mac BLE del dispositivo
  * @returns
  */
  readData = (deviceID, timeoutDuration = CONSTANTS.BLUETOOTH.MAX_TIME_GET_DATA) => {

    let buffer = '';
    let resp = null;
    // Tiempo entre lecturas del buffer dependiente de si la petición es de duración extendida o no
    const SLEEP_TIME = timeoutDuration === CONSTANTS.BLUETOOTH.MAX_TIME_GET_DATA_EXTENDED ? CONSTANTS.BLUETOOTH.SLEEP_TIME_BETWEEN_REQUEST_EXTENDED : CONSTANTS.BLUETOOTH.SLEEP_TIME_BETWEEN_REQUEST;

    return new Promise ( async (resolve, reject) => {
      //
      // Inicializamos un control de timeout del bucle
      //
      let breakLoop = false;

      // const timeout = setTimeout(() => {
      //   breakLoop = true;
      // }, timeoutDuration);

      setTimeout(() => {
        breakLoop = true;
      }, timeoutDuration);

      const read = async attemps => {
        // eslint-disable-next-line no-await-in-loop
        let nextPackage = null
        try{

          // if(attemps >= CONSTANTS.BLUETOOTH.MAX_NEXT_REQUEST_ATTEMPS){
          //   return reject(new AppError('timeout'))
          // }

          nextPackage = await this.readPackage(deviceID);

          // console.log("Valor de newPackage, tras lectura:", nextPackage);




          if (!nextPackage.isValid) {
            console.log("nextPackage no válido", this.previusPackage);
            console.log("Intento (readData): ",attemps);
            if(!breakLoop) {
              return setTimeout(async () => {
                await read(attemps + 1);
              }, SLEEP_TIME);
            }
          }
          else {
            // console.log("buffer (previo)", buffer);
            //
            // Si el paquete es válido y viene con nueva información, lo agregamos al buffer
            //
            buffer += nextPackage.packageData;

            // console.log("buffer (actual)", buffer);
            // attemps = 0;
            //
            // Si el buffer no es un JSON bien formado, la siguiente línea lanzará un error
            //
            resp = JSON.parse(buffer);

            // Y limpiamos el timeout y los intentos del bucle para otra vuelta
            // clearTimeout(timeout);
            log.success(`JSON completo. Respuesta obtenida: ${JSON.stringify(resp)}`);
            return resolve(resp);
          }
          //
          // Si no es válido el paquete, ni ha habido un error en la lectura o de falta de datos
          //
          return reject(new AppError('timeout'));

        } catch ( err ) {

          // console.log("Error en readData", err);

          // err => SyntaxError: Unexpected end of JSON input
          if(err.name === 'SyntaxError') {
            console.log("NEXT_PACKAGE_DATA:", nextPackage.packageData.trim());
            if(nextPackage.packageData === '') {
              // Si el buffer se queda como estaba y ya no me devuelve nada con el JSON mal formado
              // lanzamos error para reiniciar todo el proceso.
              console.log("Error formando JSON a partir del buffer. No llegan más datos en packageData");
              return reject(new AppError('noChangesInBuffer'));
            }
            console.log("JSON incompleto", buffer);
            console.log("En intento ", attemps);

            //
            // Le indicamos al dispositivos que nos envíe el siguiente paquete de datos
            // NOTA: El comando next sólo está disponible a partir de la versión 1.0
            // console.log("BLE Version", this.bleVersion);
            if(this.bleVersion > 0) {
              await this.sendNextCommand(deviceID);
            } else {
              buffer = ''; // Si no soporta "next" limpiamos el buffer para evitar error en la siguiente lectura
            }

            if(!breakLoop) {
              return setTimeout(async () => {
                await read(attemps + 1);
              }, CONSTANTS.BLUETOOTH.SLEEP_TIME_BETWEEN_REQUEST);
            }
          }

          if(err.name === 'commandBleNotSupported' && buffer === '' ||
            err.name === 'URImalformed' ||
            err.name === 'noChangesInBuffer') {
            return reject(err);
          }

          // return reject(new AppError('errorGetDeviceInfo', 'Error in read data'));
          return reject(new AppError('timeout', 'Agotado tiempo get data'));
          // return reject(err);

        }
      };

      setTimeout(async () => {
        await read(1);
      }, CONSTANTS.BLUETOOTH.SLEEP_TIME_BETWEEN_REQUEST);

      // if (attemps === CONSTANTS.MAX_NEXT_REQUEST_ATTEMPS) {
      //   reject(new UnitsError('maxAttempsWritingBle', 'Se ha excedido el máximo número de intentos'));
      // }
    });
  }

  /**
   * Envía el comando para solicitar el próximo paquete de datos
   *
   * @param {String} deviceID
   */
  sendNextCommand = async deviceID => {
    const command = `{"next": {} }`;

    log.info(`Launching command: next`);

    await this.writeToDevice(deviceID, command);
  }

  writeToDevice = async (deviceID, command) => {
    //
    // Limpiamos el estado del paquete anterior para esperar el nuevo comando
    //
    // TODO: Mejorar este flujo. Mejor leer el estado previo del buffer antes de ejecutar el comando
    //
    this.clearPackageBuffer();
    await this.isBluetoothEnabled();
    await this.isLocationEnabled();
    await this.isLocationAvailable();
    try {
      await this.connectDevice(deviceID);
      if(this.attempsConnectingToWrite > 0) this.attempsConnectingToWrite = 0;
    } catch (err) {
      console.log("Error connecting device in writting", err);
      this.attempsConnectingToWrite++;
      if(this.attempsConnectingToWrite > 5) {
        this.attempsConnectingToWrite = 0;
        throw err;
      } else {
        return this.writeToDevice(deviceID, command);
      }

    }
    // Cuando se implemente en ws lectura y escritura simultánea, deberíamos
    // controlar el estado del buffer antes de leer (en lugar de limpiarlo sin más)
    //  ↓
    this.previusPackage = command;

     await this.sendCommand(deviceID, command);

  }

  findDevice = async deviceName => {
    await this.isBluetoothEnabled();
    await this.isLocationEnabled();
    await this.isLocationAvailable();
    const deviceID = await this.searchDevice(deviceName);
    // await this.writeToDevice(deviceID, '{"cmd":"info"}');
    return deviceID;
  }

/*
 * Disconnect bluetooth device
 *
 * @param  {string}                - ID of device
 * @return {boolean}               - Return true if device is disconnected
 * @throws  {errorDisconnectDevice} - Failed to disconnect device
 */
// prettier-ignore
disconnectDevice = async deviceID => {
  return new Promise((resolve, reject) => {
    console.log("Disconnect device BLE");
    if(!window.ble) return;
    window.ble.isConnected(deviceID, () => {
      window.ble.disconnect(
        deviceID,
        () => resolve(true),
        () => {
          reject( new AppError('errorDisconnectDevice', 'Failed to disconnect device'));
        },
      );
    });
    });
  };
}

export default ApiBleService;
