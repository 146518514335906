
export const useIcons = () => {
  const getIcon = (iconName) => {
    try {
      const icon = require(`@/assets/icons/svg/${iconName}.svg`);
      return icon;
    } catch (error) {
      console.log(`Icon ${iconName} not found`);
      return null;
    }
  }

  return { getIcon };
};
