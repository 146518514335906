import { Device } from './DeviceHierarchy';

export class Clamp extends Device {
  static entity = 'clamps';

  static baseEntity = 'devices';

  /** *************************************************************
   * CAMPOS
   ************************************************************** */
  static fields() {
    return {
      ...super.fields(),
      ec_fw: this.string(null).nullable(),
      connection_date: this.attr(null),
      disconnection_date: this.attr(null),
      isConnected: this.boolean(false),
      serialnum_meter: this.string(undefined).nullable(),
      energy_hour_latest: this.number(undefined).nullable(),
      energy_hour_latest_date: this.string(undefined).nullable(),
      name: this.string(undefined).nullable(),
      power_measurement_enabled: this.boolean(false),
      power_p1: this.number(undefined).nullable(),
      power_p2: this.number(undefined).nullable(),
      power_p3: this.number(undefined).nullable(),
      energy_measurement_enabled: this.boolean(false),
      ws_connected: this.boolean(undefined).nullable(),
      power_total: this.attr(undefined).nullable(),
      phase_reverse: this.boolean(undefined).nullable(),
      total: this.number(undefined).nullable(),
      total_cost: this.number(undefined).nullable(),
      current_total: this.number(undefined).nullable(),
    };
  }

  get getState() {
    if(!this.isConnected) return 'error';

    return 'orange';
  }
}

export default Clamp;
