const state = {
  loading: false,
  renderApp: false,
  isShowSidenav: false,
  isUserAllow: false,
  extConfigUrl: '',
  isThereNotch: false,
  isLocationActive: false,
  isWithoutNotch: false,
  isDemo: false,
  ssidSafeFormat: false,
  logo:{  light: 'airzone.svg',  dark: 'airzoneWhite.svg',},
  lastCoords: null,
}

export default state;
