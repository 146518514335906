import { NOTIFICATIONS_ICON_MAP } from "Auth/constant";

export const useNotification = () => {
  const iconSizeMap = {
    'cloud-weather': 0.8,
    'update-old': 0.7,
    'saving': 0.9,
    'groups': 0.65,
    'warning': 0.65,
    'megaphone': 0.9,
  }

  const createBubbleNotification = (elementHTML, styles) => {
    if(!elementHTML) return;
    let bubbleDiv = elementHTML.querySelector(".bubble-notification")

    if(!bubbleDiv){
      bubbleDiv = document.createElement('div');
      elementHTML.classList.add('notification-wrapper');
      bubbleDiv.classList.add('bubble-notification');

      if(styles) {
        for( const styleKey in styles) {
          bubbleDiv.style[styleKey] = styles[styleKey];
        }
      }

      if(elementHTML) elementHTML.append(bubbleDiv);
    }

  }

  const removeBubbleNotification = (elementHTML) => {
    if(!elementHTML) return;

    const bubbleDiv = elementHTML.querySelector('.bubble-notification');

    if(bubbleDiv) {
      // Se añade display none a bubbleDiv para evitar que se muestre antes de que se elimine
      bubbleDiv.style.display = 'none'
      setTimeout(() => {
        bubbleDiv.remove();
        elementHTML.classList.remove('notification-wrapper');
      }, 0);
    }
  }

  const getNotificationIcon = (group) => {
    return NOTIFICATIONS_ICON_MAP[group];
  }

  return {
    createBubbleNotification,
    removeBubbleNotification,
    getNotificationIcon,
    iconSizeMap
  }
}
