import { mounted, updated } from 'vue';

export const cleanhtml = {
  mounted(el, binding) {
    // Crea un elemento div temporal para procesar el HTML
    const div = document.createElement('div')
    div.innerHTML = binding.value

    // Establece el contenido del elemento a solo texto
    el.textContent = div.textContent || div.innerText || ''
  },
  updated(el, binding) {
    // Vuelve a limpiar el HTML cuando el contenido cambie
    const div = document.createElement('div')
    div.innerHTML = binding.value
    el.textContent = div.textContent || div.innerText || ''
  }
}
