/* eslint-disable camelcase */
/**
* @typedef {import('Units/models/DeviceHierarchy').Device} Device
*/

import { getDeviceType } from 'Units/utils/device.utils';
import { hasProp } from 'Core/utils/validate.utils';
import User from 'Auth/models/User';
import { getBleDeviceId } from 'Units/utils/utils';
import { DataInterface } from '@airzone/data-interface';
import i18n from 'Core/services/language.service';
import constants from 'Units/constant';
import DEVICE_INTERFACE from 'Units/interfaces/device.interface';
import { Device } from 'Units/models/DeviceHierarchy';
import { parseNumber, parseBoolean } from 'Core/utils/sanitaze.utils';
import cloud2web from './cloud2web.interface';


const bleToModel = {

  /**
   * Recibe un array de devices con formato BLE y devuelve los datos
   * formateados para almacenar en el modelo de Device
   *
   * @param {String} macBLE
   * @param {Object} devices
   * @param {String} webserverID
   * @returns
   */
  getDevices: (macBLE, devices, webserverID) => {
    const devicesData = [];

    console.log("devices----> ", devices);
    if(devices && devices.length > 0) {
      devices.forEach( device => {

        const deviceType = device.device_type;

        // Si es ACS ignoramos el device
        if(deviceType === 'az_acs') return;

        const machine_units = device.units || 0;

        let dataParsed = {};

        if(device.params !== undefined) {

          const bleModel = {}
          // Obteniendo la lista de parámetros (si extiste)
          const params = device.params;

          const user = User.query().first();

          if(Object.keys(params).length > 0)  {
            let ifDevices;

            if(device.device_type === constants.DEVICE_TYPE.aidoo ||
              device.device_type === constants.DEVICE_TYPE.aidoo_it) {
              ifDevices = DataInterface.Device(constants.WS_TYPE.ws_aidoo, deviceType);
            } else {
              ifDevices = DataInterface.Device(constants.WS_TYPE.ws_az, deviceType);
            }

            Object.assign(bleModel, {
              ...params,
              units: machine_units
            })

            const ifResponse = ifDevices.generateBLEAdvConf({
              zone_id: device.zoneid,
              system_id: device.systemid,
              state: bleModel
            });

            console.log("data interface response ->", ifResponse?.adv_conf);

            dataParsed = cloud2web.parseData(ifResponse.adv_conf, user.units)

            console.log("IF RESPONSE IN BLE TO MODEL", dataParsed);

            // Almacenamos el modelo de ble
            // dataParsed.bleModel = bleModel;

            // El modelo backend será almacenado a parte, ya que es prácticamente igual que
            // el frontend, pero guarda información de las unidades en fah y celsius, así como
            // algunas difrenecias en la forma de almacenar los modos, etc.
            dataParsed.backendModel = ifResponse?.adv_conf
          }
        } else {
          dataParsed.backendModel = {}
        }

        // NOTA: Revisar 'device.interface.js' , debe tener los mismos params
        const data = {
          id: getBleDeviceId(device?.device_type, device?.systemid, device?.zoneid),
          type: getDeviceType(deviceType),
          name: device?.name ? device.name : '',
          system_number: device?.systemid,
          zone_number: device?.zoneid,
          group_id: device?.systemid,
          webserver_id: webserverID,
          device_type: device?.device_type,
          device_semantic_type: device?.device_type ? constants.DEVICE_TO_SEMANTIC_TYPE[device.device_type] : undefined,
          machine_units,
          master_conf: device?.master_conf !== undefined && device?.master_conf !== null ? device?.master_conf : undefined,
          units: User.query().first().units,
          macBLE,
          new_virtual_zones_allowed: device?.new_virtual_zones_allowed,
          virtual_zone: device?.virtual_zone,
          is_virtual: device?.is_virtual,
          ws_sched_available: device?.ws_sched_available,
          ws_sched_calendar_available: device?.ws_sched_calendar_available,
          ws_sched_param_indep: device?.ws_sched_param_indep,
          wizard_available: device?.wizard_available ? true : undefined,
          acs_sched_available: device?.acs_sched_available,
          valves_per_zone_limit: device?.valves_per_zone_limit,
          airqbox_num_ionizers_conf: device.airqbox_num_ionizers_conf,
          airqbox_num_ionizers_values: device.airqbox_num_ionizers_values,
          aq_work_sensor_conf: device.aq_work_sensor_conf,
          aq_work_sensor_values: device.aq_work_sensor_values,
          aq_sensor: device.aq_sensor,
          aq_present: device.aq_present,
          aq_hum_thres_range_min: device.aq_hum_thres_range_min,
          aq_hum_thres_range_max: device.aq_hum_thres_range_max,
          aq_hum_thres_range_step: device.aq_hum_thres_range_step,
          aq_co2_thres_range_min: device.aq_co2_thres_range_min,
          aq_co2_thres_range_max: device.aq_co2_thres_range_max,
          aq_co2_thres_range_step: device.aq_co2_thres_range_step,
          aq_pm2_5_thres_range_min: device.aq_pm2_5_thres_range_min,
          aq_pm2_5_thres_range_max: device.aq_pm2_5_thres_range_max,
          aq_pm2_5_thres_range_step: device.aq_pm2_5_thres_range_step,
          aq_pm10_thres_range_min: device.aq_pm10_thres_range_min,
          aq_pm10_thres_range_max: device.aq_pm10_thres_range_max,
          aq_pm10_thres_range_step: device.aq_pm10_thres_range_step,
          aq_tvoc_thres_range_min: device.aq_tvoc_thres_range_min,
          aq_tvoc_thres_range_max: device.aq_tvoc_thres_range_max,
          aq_tvoc_thres_range_step: device.aq_tvoc_thres_range_step,
          phase_reverse: device.phase_reverse,
          energy_measurement_enabled: device.energy_measurement_enabled,
          device_name: device.device_name,
          ...dataParsed
        }

        // Verificamos que tenemos declarados los parámetros necesarios
        try {
          DEVICE_INTERFACE.deviceBleStableParams.validate(data);
        } catch (error) {
          console.error(error);
        }

        if(hasProp(device,'zoneid')){
          data.zone_number = device.zoneid
        }
        devicesData.push(data);
      });
    }

    return devicesData;
  },

  // getSystemOutputs: inputsData => {
  //   console.log("BLE_TO_MODEL: GET_SYSTEM_INPUTS", inputsData);
  //   const outputsData = {}
  //   Object.keys(inputsData).forEach(key => {

  //     outputsData[key] = [];

  //     for(let i = 1; i <= 8; i++) {
  //       const found = inputsData[key].find(out => i === out.id)
  //       if(found) {
  //         outputsData[key].push(found);
  //       } else {
  //         outputsData[key].push({
  //           id: i,
  //           zone_number: null,
  //           type: null,
  //           disabled: true,
  //         })
  //       }
  //     }
  //   })
  //   console.log("BLE_TO_MODEL: GET_SYSTEM_OUTPUTS", outputsData);
  //   return outputsData;
  // },

  getWsCloudState: (data, wsType) => {
    const ifWebserver = DataInterface.Webserver(wsType);

    const dataParsed = ifWebserver.generateBLEAdvConf(data);

    return dataParsed;
  },

  getSchedules: async (schedules, wsType, opts = undefined) => {
    const userUnits = await User.query().first().units;

    const ifWebserver = DataInterface.Webserver(wsType);

    const data = [];



    if(opts === undefined){
      opts = {
        units: userUnits
      }
    }

    schedules.forEach( (sched, index) => {
      let startConf = {}
      if(hasProp(sched, 'conf') && hasProp(sched.conf, 'exec')){
        // console.log("extrayendo start_conf a partir de exec (ws Interface)", sched.conf.exec);
        startConf = ifWebserver.InstallationSchedBLE2Web(sched.conf.exec, opts);

        Object.keys(sched.conf).forEach( key => {
          if(key !== 'exec') {
            startConf[key] = sched.conf[key];
          }
        })

      }

      const devicesIds = [];

      if(sched.zones && sched.zones.length > 0) {
        sched.zones.forEach( zone => {
          const systemId = zone.systemid;
          if(zone.zone_numbers && zone.zone_numbers.length > 0) {
            zone.zone_numbers.forEach( zoneNumber => {
              devicesIds.push(getBleDeviceId(constants.DEVICE_TYPE.az_zone, systemId, zoneNumber));
            })
          }
        })
      }

      const modelSched = {
        id: sched.schedule_number,
        device_ids: devicesIds,
        type: sched.type,
        prog_enabled: sched.prog_enabled,
        schedule_number: sched.schedule_number,
        units: userUnits,
        start_conf: startConf,
        days: sched.conf && sched.conf.days ? sched.conf.days : undefined,
        name: sched.name ? sched.name : `${i18n.global.t('schedules.default')} ${index + 1}` // TODO: Cambiar prog por traducciones
      };

      data.push(modelSched);
    });

    return data;


  },

  getScheduleConfBLE: async (macBLE, webserverID, devices) => {

    // Preparamos los arrays de ws_ids con algún tipo de programación para crear el modelo installation
    const schedulesWeek_ws_ids = [];
    const schedulesCalendar_ws_ids = [];
    const schedulesAcs_ws_ids = [];
    const schedulesVmc_ws_ids = [];
    const schedulesRelay_ws_ids = [];

    if(!devices || devices.length === 0) return null;

    const groupData = [];

    // Para las programaciones BLE, guardamos los sistemas como grupos de una instalación
    let groupNumber = 1;
    let position = 1;

    for(let i=0; i < devices.length; i++) {
      // console.log("Each device", devices[i]);
      // Creamos los grupos en el modelo a partir de SYSTEM o ACS (en BLE no tenemos los grupos de Cloud)
      if(devices[i].type === constants.MODEL_TYPE.SYSTEM ||
        devices[i].type === constants.MODEL_TYPE.ACS) {
        const group = {
          id: devices[i].system_number,
          installation_id: devices[i].webserver_id,
          name: devices[i].name ? devices[i].name : `${i18n.global.t('installations.group')} ${groupNumber}`,
          position
        }

        if(!devices[i].name) groupNumber++
        position++;

        groupData.push(group);
      }
      // Rellenamos los arrays de programaciones disponibles
      if(devices[i].ws_sched_available && devices[i].ws_sched_available === true) {
        schedulesWeek_ws_ids.push(webserverID)
      }

      if(devices[i].ws_sched_calendar_available && devices[i].ws_sched_calendar_available === true) {
        schedulesCalendar_ws_ids.push(webserverID)
      }

      if(devices[i].acs_sched_available && devices[i].acs_sched_available === true) {
        schedulesAcs_ws_ids.push(webserverID)
      }

      if(devices[i].vmc_sched_available && devices[i].vmc_sched_available === true) {
        schedulesVmc_ws_ids.push(webserverID)
      }

      // Obtenemos ScheduleConf de cada device[i] de tipo ACS o System (o que tenga el flag programaciones)
      if(devices[i].ws_sched_available || devices[i].ws_sched_calendar_available && devices[i].acs_sched_available) {
        // eslint-disable-next-line no-await-in-loop
        await Device.getBleSchedulueConf(devices[i].id)
      }
    }




    // Creamos una instalación (simbólicamente) para las vistas de creación de programaciones
    const installationData = {
      id: webserverID,
      access_type: constants.USER_TYPE.ADMIN,
      schedulesWeek_ws_ids: [...new Set(schedulesWeek_ws_ids)],
      schedulesCalendar_ws_ids: [...new Set(schedulesCalendar_ws_ids)],
      schedulesVmc_ws_ids: [...new Set(schedulesVmc_ws_ids)],
      schedulesRelay_ws_ids: [...new Set(schedulesRelay_ws_ids)]
    }

    return {
      groupData,
      installationData
    }


  },

  getAidooPROInitialState: (macBLE, webserverID) => {

     const data = [
        // {type: 'SYSTEM', system_number: 1, zone_number: 0, webserver_id: webserverID, macBLE, units: User.query().first().units, device_type: constants.DEVICE_TYPE.aidoo_it },
        {type: constants.MODEL_TYPE.ZONE, system_number: 1, zone_number: 1, webserver_id: webserverID, macBLE, units: User.query().first().units, device_type: constants.DEVICE_TYPE.aidoo_it },
        // {type: 'CCP', system_number: 0, zone_number: 0, webserver_id: webserverID, macBLE, units: User.query().first().units, device_type: constants.DEVICE_TYPE.aidoo_it }
      ]

    return data;
  },



  getWebserverType(code) {
    if(constants.WS_CODE_TO_TYPE[code] !== undefined) {
      return constants.WS_CODE_TO_TYPE[code].type;
    }

    // Por defecto devolvermos AIRZONE
    return constants.DEVICE_TYPE.az_ws

  },

  getData(data, type, opts) {
    let ifDevices;
    if(type === constants.DEVICE_TYPE.aidoo ||
      type === constants.DEVICE_TYPE.aidoo_it) {
      ifDevices = DataInterface.Device(constants.WS_TYPE.ws_aidoo, type);
    } else {
      ifDevices = DataInterface.Device(constants.WS_TYPE.ws_az, type);
    }

    const ifResponse = ifDevices.generateBLEAdvConf({
      ...opts,
      state: data
    });

    console.log("data interface response ->", ifResponse?.adv_conf);
    return ifResponse?.adv_conf
  },

  getServicesBle(webserverData) {
    const webserver = {};

    if(hasProp(webserverData, 'services') && hasProp(webserverData.services, 'lapi')){
      webserver.lapi = parseNumber(webserverData.services.lapi);
    }
    if(hasProp(webserverData, 'services') && hasProp(webserverData.services, 'venstar')){
      webserver.venstar = parseNumber(webserverData.services.venstar);
    }
    if(hasProp(webserverData, 'services') && hasProp(webserverData.services, 'bacnetip')){
      webserver.bacnetip = parseNumber(webserverData.services.bacnetip);
    }
    if(hasProp(webserverData, 'services') && hasProp(webserverData.services, 'lutron')){
      webserver.lutron = parseNumber(webserverData.services.lutron);
    }
    if(hasProp(webserverData, 'services') && hasProp(webserverData.services, 'lutronhwqsx')){
      webserver.lutronhwqsx = parseNumber(webserverData.services.lutronhwqsx);
    }
    if(hasProp(webserverData, 'services') && hasProp(webserverData.services, 'control4')){
      webserver.control4 = parseNumber(webserverData.services.control4);
    }
    if(hasProp(webserverData, 'services') && hasProp(webserverData.services, 'slavemodbustcp')){
      webserver.slavemodbustcp = parseNumber(webserverData.services.slavemodbustcp);
    }
    if(hasProp(webserverData, 'services') && hasProp(webserverData.services, 'slavemodbus')){
      webserver.slavemodbus = parseNumber(webserverData.services.slavemodbus);
    }
    if(hasProp(webserverData, 'services') && hasProp(webserverData.services, 'bacnetmstp')){
      webserver.bacnetmstp = parseNumber(webserverData.services.bacnetmstp);
    }
    if(hasProp(webserverData, 'services') && hasProp(webserverData.services, 'mdns_server')){
      webserver.mdns_server = parseNumber(webserverData.services.mdns_server);
    }
    if(hasProp(webserverData, 'services') && hasProp(webserverData.services, 'pelican')){
      webserver.pelican = parseNumber(webserverData.services.pelican);
    }
    if(hasProp(webserverData, 'services') && hasProp(webserverData.services, 'hvacsim')){
      webserver.hvacsim = parseBoolean(webserverData.services.hvacsim);
    }
    if(hasProp(webserverData, 'services') && hasProp(webserverData.services, 'az_mqtt')){
      webserver.az_mqtt = parseBoolean(webserverData.services.az_mqtt);
    }
    if(hasProp(webserverData, 'services') && hasProp(webserverData.services, 'remotegtwip')){
      webserver.remotegtwip = parseBoolean(webserverData.services.remotegtwip);
    }
    if(hasProp(webserverData, 'services') && hasProp(webserverData.services, 'remotegtwrtu')){
      webserver.remotegtwrtu = parseBoolean(webserverData.services.remotegtwrtu);
    }

    return webserver;
  },

  resetDeviceBLEStatus: async deviceId => {
    const device = Device.find(deviceId);

    // NOTA: Revisar 'device.interface.js' , debe tener los mismos params
    const data = {
      id: device.id,
      type: device.type,
      name: device.name,
      system_number: device.system_number,
      zone_number: device.zone_number,
      group_id: device.group_id,
      webserver_id: device.webserver_id,
      device_type: device.device_type,
      device_semantic_type: device?.device_type ? constants.DEVICE_TO_SEMANTIC_TYPE[device.device_type] : undefined,
      machine_units: device.machine_units,
      new_virtual_zones_allowed: device?.new_virtual_zones_allowed,
      units: device.units,
      macBLE: device.macBLE,
      ws_sched_available: device.ws_sched_available,
      ws_sched_calendar_available: device.ws_sched_calendar_available,
      ws_sched_param_indep: device.ws_sched_param_indep,
      acs_sched_available: device.acs_sched_available,
      acs_sched_available: device.acs_sched_available,
      aq_sensor: device.aq_sensor,
      aq_present: device.aq_present,
      aq_hum_thres_range_min: device.aq_hum_thres_range_min,
      aq_hum_thres_range_max: device.aq_hum_thres_range_max,
      aq_hum_thres_range_step: device.aq_hum_thres_range_step,
      aq_co2_thres_range_min: device.aq_co2_thres_range_min,
      aq_co2_thres_range_max: device.aq_co2_thres_range_max,
      aq_co2_thres_range_step: device.aq_co2_thres_range_step,
      aq_pm2_5_thres_range_min: device.aq_pm2_5_thres_range_min,
      aq_pm2_5_thres_range_max: device.aq_pm2_5_thres_range_max,
      aq_pm2_5_thres_range_step: device.aq_pm2_5_thres_range_step,
      aq_pm10_thres_range_min: device.aq_pm10_thres_range_min,
      aq_pm10_thres_range_max: device.aq_pm10_thres_range_max,
      aq_pm10_thres_range_step: device.aq_pm10_thres_range_step,
      aq_tvoc_thres_range_min: device.aq_tvoc_thres_range_min,
      aq_tvoc_thres_range_max: device.aq_tvoc_thres_range_max,
      aq_tvoc_thres_range_step: device.aq_tvoc_thres_range_step,
      phase_reverse: device.phase_reverse,
      device_name: device.device_name,
      energy_measurement_enabled: device.energy_measurement_enabled,
    }

    // Verificamos que tenemos declarados los parámetros necesarios
    try {
      DEVICE_INTERFACE.deviceBleStableParams.validate(data);
    } catch (error) {
      console.error(error);
    }

    await Device.insert({data});
  }
}

export default bleToModel;
