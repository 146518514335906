import store from 'Core/store/store';
// Constants
import unitsConstants from 'Units/constant';
import authConstants from 'Auth/constant';

// COMPONENTS
import Dialog from 'Core/components/Dialog';
import Toast from 'Core/components/Toast';
// MODELS
import Installation from 'Units/models/Installation.model';
import User from 'Auth/models/User';
import Webserver from 'Units/models/Webserver.model';
import Tariff from 'Units/models/Tariff.model';
import { Device } from 'Units/models/DeviceHierarchy';
import InvitedUser from 'Units/models/InvitedUser.model';
import Scene from 'Units/models/Scene.model';
import { mergeObjectsDeep } from 'Core/utils/utils';

const CONSTANTS = {...unitsConstants, ...authConstants};

const installationsRoutes = {
  path: '/installations',
  name: 'installations',
  props: true,
  component: () => import('Units/pages/Installations'),
  redirect: {
    name: 'installationsList'
  },
  beforeEnter: async (to, from, next) => {
    const user = User.query().first();
    // NOTA: No espero "await" para no ralentizar. No me importa entrar a las zonas sin conexión al socket
    // El estado de las zonas se actualizará en cuanto esté disponible.
    User.connectSocket();

    if(user.admin_mode === true && !store.getters.getIsDemo) {
      next();
    } else {
      try {
        // Obtenemos las instalaciones para listarlas.
        // Si entro saltando directo a zonas, compruebo que ya tengo el parámetro installationID y la instalación disponible
        const installation = to.params.installationID ? Installation.find(to.params.installationID) : undefined;

        if(to.params.page){
          await Installation.getInstallations(to.query.filterType, to.query.filterValue, user.lang, null, to.params.page);
        } else if(!installation) {
          await Installation.getInstallations();
        }

        next();
      } catch( error ){
        Dialog.error(error);
        Toast.clear();
        next();
      }
    }
  },

  children:[
    {
      path: 'notifications',
      name: 'notificationsList',
      component: () => import('Units/pages/Notifications'),
      props: true,
    },
    {
      path: 'list/:page?/:filterParam?/:filter?',
      name: 'installationsList',
      component: () => import('Units/pages/InstallationsList'),
      props: true,
      beforeEnter: async (to, from, next) => {

        //
        // Si sólo hay una instalación redirijo directamente a ella
        // También debemos comprobar que no tenga invitaciones pendientes
        //
        const user = User.query().first();

        if (user.admin_mode === true && !store.getters.getIsDemo){
          next({name: 'adminInstallationsList', params: {role: user.role}});
        } else {
          try {
            // Toast.loading();
            //
            // Si la ruta no pertenece al flujo de /installations, se pasará por la ruta padre
            // y no es necesario volver a pedir "getInstallations"
            //
            const regex = /^\/installations(?:\/(?=$))?$/i;
            const regexAirtools = /^\/airtools(?:\/(?=$))?$/i;
            const fromIntallationRoute = from.matched.length > 2 ? regex.test(from.matched[2].path) : false;
            const fromAirtoolsRoute = from.matched.length > 2 ? regexAirtools.test(from.matched[2].path) : false;

            if(fromIntallationRoute && !fromAirtoolsRoute){
              if(to.query.filterType && to.query.filterValue) {
                await Installation.getInstallations(to.query.filterType, to.query.filterValue, user.lang, null, to.params.page);
              } else if(to.params.page){
                await Installation.getInstallations(null, null, null, null, to.params.page);
              } else {
                await Installation.getInstallations();
              }
            }


            if(Installation.all().length === 1 && user.pendingInstallations === 0 && !to.params.filter){
                const installation = Installation.query().first();
                // if(from.params.installationID !== undefined
                //   && from.params.installationID !== installation.id) {
                // entonces viene de la vista de instalaciones, pero no de esta instalación!
                // tengo que pedir la info de webserver porque no lo va a pedir el
                await Installation.getInstallation(installation.id);

                await User.listenInstallation(installation.id);
                // Guardando estado global loading antes de recuperar y pintar todos los devices
                if(!store.getters.getIsDemo){
                  store.dispatch('setLoadingStatus', true);
                }

                next({name: 'installation', params: {installationID: installation.id}})
            } else {
              next();
            }
            // Toast.clear();

          } catch (error) {
            Dialog.error(error);
            Toast.clear();
            next();
          }

        }

      }
    },
    {
      path: 'admin-list/:role?/:page?/:filterParam?/:filter?',
      name: 'adminInstallationsList',
      component: () => import('Units/pages/Admin/AdminInstallationsList'),
      props: true,
      beforeEnter: async (to, from, next) => {
        try {
            Toast.loading();

            if(from.name !== 'installations'){
              if(to.params.filter) {
                await Installation.getInstallations(to.params.filterParam, to.params.filter, null, null, to.params.page, to.params.role);
              } else if(to.params.page){
                await Installation.getInstallations(null, null, null, null, to.params.page, to.params.role);
              }
            }

            next();

            Toast.clear();
          } catch (error) {
            Dialog.error(error);
            Toast.clear();
            next();
          }
      }
    },
    {
      path: 'sort',
      name: 'installationsSort',
      component: () => import('Units/pages/InstallationsSort'),
      props: true
    },
    {
      path: ':installationID',
      name: 'installation',
      meta: {
        onInstallation: true
      },
      component: () => import('Units/pages/Installation'),
      beforeEnter: async (to, from, next) => {

        try {

          await Installation.getInstallation(to.params.installationID);
          await User.listenInstallation(to.params.installationID);

          // Guardando estado global loading antes de recuperar y pintar todos los devices
          if(!store.getters.getIsDemo){
            store.dispatch('setLoadingStatus', true);
          }
          next();
        } catch (error) {
          Dialog.error(error);
          next();
        }

      },
      props: true,
      redirect: {
        name: 'zones',
      },
      children: [
        {
          path: 'zones',
          name: 'zones',
          component: () => import('Units/pages/Zones'),
          meta: { position: 1 },
          props: true,
        },
        {
          path: 'sort',
          name: 'sortingZones',
          component: () => import('Units/pages/SortingZones'),
          props: true,
        },
        {
          path: 'schedules',
          name: 'schedules',
          component: () => import('Units/pages/Schedules'),
          meta: { position: 2 },
          props: true,
          redirect: {
            name: 'schedulesMainView'
          },
          children: [
            {
              path: '',
              name: 'schedulesMainView',
              component: () => import('Units/pages/Schedules/SchedulesMainView'),
              props: true,
              redirect: {
                name: 'listedSchedules'
              },
              children: [
                {
                  path: 'week',
                  name: 'weekSchedules',
                  component: () => import('Units/pages/Schedules/WeekSchedules'),
                  props: true,
                  beforeEnter: async (to, from, next) => {

                    try {

                      const installation = await Installation.find(to.params.installationID);

                      if(installation.isBasic) {
                        next({name: 'listedSchedules', params: {installationID: installation.id}})
                      } else {
                        next();
                      }
                    } catch(error) {
                      console.log(error);
                    }
                  }
                },
                {
                  path: 'listed',
                  name: 'listedSchedules',
                  component: () => import('Units/pages/Schedules/ListedSchedules'),
                  props: true
                },
              ]
            },
            {
              path: 'edit/:scheduleID?',
              name: 'editSchedule',
              component: () => import('Units/pages/Schedules/EditSchedule'),
              props: true,
              redirect: {
                name: 'editWeekSchedule'
              },
              children: [
                {
                  path: 'weekSchedule',
                  name: 'editWeekSchedule',
                  component: () => import('Units/pages/Schedules/EditSchedule/EditWeekSchedule'),
                  props: true
                },
                {
                  path: 'calendarSchedule',
                  name: 'editCalendarSchedule',
                  component: () => import('Units/pages/Schedules/EditSchedule/EditCalendarSchedule'),
                  props: true
                },
                {
                  path: 'acsSchedule',
                  name: 'editAcsSchedule',
                  component: () => import('Units/pages/Schedules/EditSchedule/EditAcsSchedule'),
                  props: true
                },
                {
                  path: 'vmcSchedule',
                  name: 'editVmcSchedule',
                  component: () => import('Units/pages/Schedules/EditSchedule/EditVmcSchedule'),
                  props: true
                },
                {
                  path: 'relaySchedule',
                  name: 'editRelaySchedule',
                  component: () => import('Units/pages/Schedules/EditSchedule/EditRelaySchedule'),
                  props: true
                },
              ]
            },
          ],

          beforeEnter: async (to, from, next) => {
            try {

              await Installation.getSchedules(to.params.installationID);

              next()
            } catch(error) {
              console.log(error);
            }
          }
        },
        {
          path: 'settings',
          name: 'installationSettings',
          component: () => import('Units/pages/InstallationSettings'),
          meta: { position: 4 },
          redirect: {
            name: 'installationSettingsMain'
          },
          props: true,
          beforeEnter: async (to, from, next) => {
            const user = User.query().first();

            const userSettings = await User.getUserSettings(user.id);
            const installationUserSettings = userSettings?.installations?.find(installation => installation.id === to.params.installationID);
            const installationSettingsVisited = installationUserSettings.bubbleNotification?.installationSettingsVisited;

            if(installationUserSettings && !installationSettingsVisited) {
              const newData = {
                id: user.id,
                installations: [
                  { id: to.params.installationID,
                    bubbleNotification: { installationSettingsVisited: true },
                    ...installationUserSettings
                  }
                ]
              };

              const dataMerged = mergeObjectsDeep(userSettings, newData);
              await User.setUserSettings(user.id, dataMerged);
            }

            try {
              // Si no tengo la instalación la intento recuperar
              let installation;
              if(to.params.installationID){
                installation = Installation.find(to.params.installationID);

              } else {
                next({name: 'installations'})
              }

              if(!installation){
                await Installation.getInstallations()
                // Obtengo los datos de la installation
                await Installation.getInstallation(to.params.installationID);
              }
              else if (installation.access_type === null){
                // Si no he obtenido el tipo de acceso de usuario, obtengo los datos completos de la instalación
                await Installation.getInstallation(to.params.installationID);
              }

              if(installation.access_type === CONSTANTS.USER_TYPE.ADMIN
                || user.admin_mode === true){
                await InvitedUser.getAll(to.params.installationID);
              }

              if(installation.country === undefined || installation.city === undefined) {
                await installation.getLocation();
              }

              next();
            } catch (error) {
              console.log(error);
              Dialog.error(error);
              next();
            }

          },
          children: [
            {
              path: '',
              name: 'installationSettingsMain',
              component: () => import('Units/pages/InstallationSettings/InstallationSettingsMain'),
              meta: { position: 4 },
              props: true,
            },
            {
              path: 'group/:groupID',
              name: 'installationSettingsGroup',
              component: () => import('Units/pages/InstallationSettings/InstallationSettingsGroup'),
              meta: { position: 4 },
              props: true,
            },
            {
              path: 'zone/:zoneID/ws/:webserverID',
              name: 'installationSettingsZone',
              component: () => import('Units/pages/InstallationSettings/InstallationSettingsZone'),
              meta: { position: 4 },
              props: true,
              beforeEnter: async (to, from, next) => {
                try {
                  await Webserver.getWebserverStatus({installationID: to.params.installationID, webserverID: to.params.webserverID});
                  next();
                } catch (error) {
                  console.log(error);
                  Dialog.error(error)
                  next();
                }
              },
            },
            {
              path: 'invite',
              name: 'inviteUser',
              component: () => import('Units/pages/InstallationSettings/InviteUser'),
              meta: { position: 4 },
              props: true,
            },
            {
              path: 'invite/:userID/edit',
              name: 'editInviteUser',
              component: () => import('Units/pages/InstallationSettings/EditInviteUser'),
              meta: { position: 4 },
              props: true,
            },
          ]
        },
        {
          path: 'zone/:zoneID',
          name: 'zone',
          component: () => import('Units/pages/Zone'),
          props: true,
        },
        {
          path: 'zone/:zoneID',
          name: 'zoneSchedules',
          component: () => import('Units/pages/ZoneSchedules'),
          props: true,
          beforeEnter: async (to, from, next) => {
            const installationID = to.params.installationID;
            const zoneID = to.params.zoneID;

            try {
              const response = await Device.getSchedules(installationID, zoneID);

              console.log(response);

              next();

            } catch (error) {
              console.log(error);
              Dialog.error(error);
              Toast.clear();
              if(from.name !== null){
                next({name: from.name });
              } else {
                next({name: 'installations'});
              }
            }

          },
          redirect: {
            name: 'zoneSchedulesList'
          },
          children: [
            {
              path: 'list',
              name: 'zoneSchedulesList',
              props: true,
              component: () => import('Units/pages/ZoneSchedules/ZoneSchedulesList')
            },
            {
              path: 'edit/:scheduleID?',
              name: 'zoneScheduleEdit',
              props: true,
              component: () => import('Units/pages/ZoneSchedules/ZoneScheduleEdit')
            }
          ]
        },
        {
          path: 'scenes',
          name: 'scenes',
          component: () => import('Units/pages/Scenes'),
          props: true,
          redirect: {
            name: 'scenesMenu'
          },
          children: [
            {
              path: 'menu',
              name: 'scenesMenu',
              props: true,
              component: () => import('Units/pages/Scenes/ScenesMenu')
            },
            {
              path: 'edit/:sceneID?',
              name: 'editScenes',
              component: () => import('Units/pages/Scenes/EditScenes'),
              props: true,
              redirect: {
                name: 'editScenesMain',
              },
              beforeEnter: async (to, from, next) => {
                if(to.params.sceneID === undefined) {
                  next();
                } else {
                  try {
                    await Scene.getScene(to.params.installationID, to.params.sceneID);
                    next();
                  } catch (error) {
                    console.log(error);
                    Dialog.error(error);
                    Toast.clear();
                    next({name: 'scenesMenu'});
                  }
                }
              },
              children: [
                {
                  path: 'main',
                  component: () => import('Units/pages/Scenes/EditScenes/EditScenesMain'),
                  name: 'editScenesMain',
                  props: true,
                },
                {
                  path: 'devices',
                  component: () => import('Units/pages/Scenes/EditScenes/SelectSceneDevices'),
                  name: 'selectSceneDevices',
                  props: true
                },
                {
                  path: 'actions',
                  component: () => import('Units/pages/Scenes/EditScenes/EditSceneActions'),
                  name: 'editSceneActions',
                  props: true
                }

              ]
            },
          ]
        },
        {
          path: 'zoneSettings/:zoneID',
          name: 'zoneSettings',
          component: () => import('Units/pages/ZoneSettings'),
          props: true,
          beforeEnter: async (to, from, next) => {
            try {
              //
              // Obtengo los datos del configuración avanzada de usuario
              //
              await Device.getConfig(to.params.zoneID, to.params.installationID,'user');
              next();
            } catch (error) {
              Dialog.error(error)
              Toast.clear();
              if(from.name !== null){
                next({name: from.name });
              } else {
                next({name: 'installations'});
              }
            }
          },
        },
        {
          path: 'zone/:zoneID/graphics/comfort',
          name: 'zoneGraphicComfort',
          component: () => import('Units/pages/ZoneGraphics/Comfort'),
          props: true,
        },
        {
          path: 'zone/:zoneID/graphics/airq',
          name: 'zoneGraphicAirQ',
          component: () => import('Units/pages/ZoneGraphics/AirQ'),
          props: true,
        },
        {
          path: 'EC',
          name: 'installationElectricalConsumption',
          component: () => import('Units/pages/ElectricalConsumption'),
          props: true,
          children: [
            {
              path: ':deviceID',
              name: 'installationElectricalConsumptionDevice',
              component: () => import('Units/pages/ElectricalConsumption'),
              props: true,
            }
          ]
        },
        {
          path: 'tariffs',
          name: 'electricPrice',
          component: () => import('Units/pages/ElectricPrice'),
          props: route => ({
            installationID: route.params.installationID,
            inputData: route.params.data ? JSON.parse(route.params.data) : null,
          }),
          beforeEnter: async (to, from, next) => {
            // Pido la información del precio electrico si no la tengo
            // TODO => Guardar en VUEX y comprobar si exite en lugar de pasarlo por params
            if (!to.params.data && to.params.installationID) {
              const installation = Installation.find(to.params.installationID);
              const tariffId = installation?.widgets?.price?.tariff_id;
              const priceData = await installation.getPriceInfo(null, installation.priceRegion, tariffId);

              to.params.data = JSON.stringify(priceData);
            }
            next()
          },
        },
        {
          path: 'tariffs/settings',
          name: 'electricPriceSettings',
          component: () => import('Units/pages/ElectricPrice/ElectricPriceSettings'),
          props: true,
          redirect: {
            name: 'electricPriceSettingsMain'
          },
          beforeEnter: async (to, from, next) => {

            try {
              Toast.loading();
              const user = User.query().first();
              const installation = Installation.find(to.params.installationID);
              const tariffId = installation?.widgets?.price?.tariff_id;

              const userSettings = await User.getUserSettings(user.id);
              const installationUserSettings = userSettings.installations.find(installation => installation.id === to.params.installationID);

              if(installationUserSettings && !installationUserSettings.bubbleNotification?.configElectricPriceVisited) {
                const newData = {
                  id: user.id, installations: [
                    {
                      id: to.params.installationID,
                      bubbleNotification: { installationSettingsVisited: true, configElectricPriceVisited: true,  }
                    }
                  ]
                }
                const dataMerged = mergeObjectsDeep(userSettings, newData);
                await User.setUserSettings(user.id, dataMerged);
              }

              await installation.getAvailablesTariffs();
              let tariffData;

              if(tariffId) {
                tariffData = await installation.getTariffData(tariffId);
                Tariff.insert({ data: tariffData })
              }
              // Guardamos información de la vista anterior para poder volver a ella
              const fromView = from.name;
              to.params.fromView = fromView;

              next();
            } catch (error) {
              Toast.clear();
              if(error.name === 'tariffNotFound'){
                // Guardamos información de la vista anterior para poder volver a ella
                const fromView = from.name;
                to.params.fromView = fromView;
                next();
              } else if(from.name !== null){
                next({name: from.name });
              } else {
                next({name: 'installations'});
              }
            }

          },
          children: [
            {
              path: 'main',
              name: 'electricPriceSettingsMain',
              component: () => import('Units/pages/ElectricPrice/ElectricPriceSettings/ElectricPriceSettingsMain'),
              props: true
            },
            {
              path: 'periods/:periodID?',
              name: 'electricPriceSettingsPeriods',
              component: () => import('Units/pages/ElectricPrice/ElectricPriceSettings/ElectricPriceSettingsPeriod'),
              props: true
            }
          ]
        }
      ],
    },
  ],
}

export default installationsRoutes;
