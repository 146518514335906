import constants from "Units/constant";
import mapTranslations from "Units/utils/mapTranslations";

/**
 * Convierte el tipo de dispositivo recibido en backend al tipo esperado en el modelo Device de Frontend
 *
 * @param {String} type - Tipo recibido desde el backend
 */

const getDeviceType = type => {
  return constants.DEVICE_TO_MODEL_TYPE[type] || constants.MODEL_TYPE.DEVICE;
};

const getTempInUnits = (temps, units = 'celsius') => {
  return temps[units];
};

const getSignalValue = (signal, unit = 'stat_quality') => {

    const parseSignal = parseInt(signal, 10);

    if(unit === 'stat_rssi'){
      switch (true) {
          case parseSignal < constants.COVERAGE.LOW:
            return 1;
          case parseSignal < constants.COVERAGE.MEDIUM:
            return 2;
          case parseSignal < constants.COVERAGE.HIGH:
            return 3;
          default:
            return 4;
        }
    }

    return parseSignal;

}

const getAqStatusColor = state => {
  const colorMap = {
      good: constants.COLORS.aq_good,
      regular: constants.COLORS.aq_regular,
      bad: constants.COLORS.aq_bad,
      night_mode: constants.COLORS.default
  };
  return colorMap[state];
}

const getAqStatusText = state => {
  if(state === undefined) return null;

  return typeof mapTranslations.AQ_QUALITY[state] === 'function' ? mapTranslations.AQ_QUALITY[state]() : '';
}

export { getDeviceType, getTempInUnits, getSignalValue, getAqStatusColor };
