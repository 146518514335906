import CONSTANTS from 'Core/constant';
import i18n from 'Core/services/language.service'
// const zfill = (number, width) => {
//   const numberOutput = Math.abs(number); /* Valor absoluto del número */
//   const length = number.toString().length; /* Largo del número */
//   const zero = "0"; /* String de cero */

//   if (width <= length) {
//     if (number < 0) {
//           return (`-${  numberOutput.toString()}`);
//     }
//     return numberOutput.toString();
//   }

//   if (number < 0) {
//     return (`-${  zero.repeat(width - length)  }${numberOutput.toString()}`);
//   }

//   return ((zero.repeat(width - length)) + numberOutput.toString());

// }

/**
* Obtiene los valores disponibles en un array calculados a partir de un arngo máximo y mínimo
*
* @param {Number} rangeMin - valor mínimo del rango
* @param {Number} rangeMax - valor máximo del rango
* @param {Number} [step] - paso que hay entre cada valor dentro del rango
*/
const getValuesFromRange = (rangeMin, rangeMax, step = 0, user) => {
  const values = [];
  //
  // Si no hemos recibido el paso ponemos por defecto 0,5 en celsius y 1 en fah
  //
  if(step === 0 && user) {
    step = (user.units === CONSTANTS.TEMP_UNITS.CELSIUS) ? 0.5 : 1;
  } else if (typeof step === 'undefined' && user) {
    step = (user.units === CONSTANTS.TEMP_UNITS.CELSIUS) ? 1 : 2;
  } else { // Si no tenemos step ni user aplicamos el valor que tenga o 1 por defecto
    step = step !== 0 ? step : 1
  }

  for(let i= rangeMin; i <= rangeMax; i += step){
    values.push(i);
  }

  return values;
}

const getScheduleNumber = schedulesNumbers => {

  if(!schedulesNumbers || schedulesNumbers.length === 0) return 0;

  for(let i=0; i < schedulesNumbers.length; i++) {

    if(schedulesNumbers[i] === i) {
      console.log("Posición ocupada, paso a la siguiente");
    } else {
      return i;
    }
  }

  return schedulesNumbers.length;

}

const getBleDeviceId = (deviceType, systemNumber, zoneNumber) => {
  if(!zoneNumber) {
    return `${deviceType}$s${systemNumber}`
  }

  return `${deviceType}$s${systemNumber}n${zoneNumber}`

}

const getAlias = (alias, mac) => {

  const macSufix = mac?.substring(12, 17).replace(':', '')

  return alias || `${i18n.global.t('airtools.device')}_${macSufix}`

}

const formatHour = (hour, ampm = null, isAmPm) => {
  hour = Number(hour)
  if (hour === null || hour === undefined) return '--:--';

  if (isAmPm) {
    const isPm = hour >= 12
    const adjustedHour = hour % 12 || 12;
    const formattedHour = adjustedHour.toString().padStart(2, '0');
    return `${formattedHour} ${ampm ? ampm : isPm ? 'PM' : 'AM'}`;
  }
  const formattedHour = hour.toString().padStart(2, '0');

  return `${formattedHour}:00`;
}

const formatHourToSend = (hour, ampm, isAmPm) => {
  let formatedHour = hour
    if(ampm === 'PM' && isAmPm){
      formatedHour = hour + 12
      if(formatedHour === 24) formatedHour = 0
      return formatedHour
    }
  return formatedHour
}

export { getValuesFromRange, getScheduleNumber, getBleDeviceId, getAlias, formatHour, formatHourToSend } ;
