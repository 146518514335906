
const setBiometricAvailable = (context, status) => {
  context.commit('UPDATE_IS_BIOMETRIC_AVAILABLE', status)
}

const setBiometricAuthOpen = (context, status) => {
  context.commit('UPDATE_IS_BIOMETRIC_AUTH_OPEN', status)
}

const setBiometricMode = (context, status) => {
  context.commit('UPDATE_BIOMETRIC_MODE', status)
}

const setBiometricType = (context, type) => {
  context.commit('UPDATE_BIOMETRIC_TYPE', type)
}

const setIsSkinActive = (context, status) => {
  context.commit('UPDATE_IS_SKIN_ACTIVE', status);
}

const setIsNotificationServiceBroken = (context, status) => {
  context.commit('UPDATE_IS_NOTIFICATION_SERVICE_BROKEN', status);
}

export default {
  setBiometricAvailable,
  setBiometricAuthOpen,
  setBiometricMode,
  setBiometricType,
  setIsSkinActive,
  setIsNotificationServiceBroken
}
