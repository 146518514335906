import CONSTANTS from 'Core/constant';
import validator from 'validator';
/*
 * Returns if a value is a string
 *
 * @param {String} - Value to parse
 * @return {Boolean} - Result if value is string
 */
const isString = value => typeof value === 'string' || value instanceof String;

/*
 * Returns if a value is really a number
 *
 * @param {Number} - Value to parse
 * @return {Boolean} - Result if value is number
 */
const isNumber = value => typeof value === 'number' && isFinite(value); // eslint-disable-line

/*
 * Returns if a value is an array
 *
 * @param {Array} - Value to parse
 * @return {Boolean} - Result if value is array
 */
const isArray = value => value && Array.isArray(value);

/*
 * Returns if a value is an object
 *
 * @param {Object} - Value to parse
 * @return {Boolean} - Result if value is object
 */
const isObject = value => value && typeof value === 'object';

/*
 * Returns if a value is null
 *
 * @param {Null} - Value to parse
 * @return {Boolean} - Result if value is null
 */
const isNull = value => value === null;

/*
 * Returns if a value is undefined
 *
 * @param {String} - Value to parse
 * @return {Boolean} - Result if value is undefined
 */
const isUndefined = value => typeof value === 'undefined';

/*
 * Returns if a value is a boolean
 *
 * @param {Booelan} - Value to parse
 * @return {Boolean} - Result if value is boolean
 */
const isBoolean = value => typeof value === 'boolean';

/*
 * Returns if value is a dat
 *
 * @param {Date} - Value to parse
 * @return {Boolean} - Result if value is date
 */
const isDate = value => value instanceof Date;

/*
 * Returns if value has email format
 *
 * @param {string} - Value to check the format
 * @return {Boolean} - Result if value has email format
 */
const isEmail = value =>  validator.isEmail(value);

/*
 * Returns if value has name format
 *
 * @param {string} - Value to check the format
 * @return {Boolean} - Result if value has name format
 */
// const isName = value => typeof value === 'string' && validator.isLength(value, { min: 2, max: 35 });
const isName = value => CONSTANTS.REGEX.NAME.test(value);

const isPassword = value => typeof value === 'string' && validator.isLength(value, { min: 8, max: 64 });
/*
 * Returns if value has name format
 *
 * @param {string} - Value to check the format
 * @return {Boolean} - Result if value has name format
 */
const isZoneName = value => CONSTANTS.REGEX.ZONE_NAME.test(value);


/*
 * Returns if lenght string is greater or equal than number
 *
 * @param {string} - String to check the length
 * @param {number} - Number to compare
 * @return {Boolean} - Result if length string is greater or equal than number
 */
const min = (string, number) => string.length >= number;

/*
 * Returns if lenght string is less or equal tham number
 *
 * @param {string} - String to check the length
 * @param {number} - Number to compare
 * @return {Boolean} - Result if length string is less or equal than number
 */
const max = (string, number) => string.length <= number;

/**
 * Comprueba si un objeto contiene una propiedad
 *
 * @param {Object} - Objecto que queremos comprobar
 * @param {String} - Propiedad que queremos buscar
 * @return {Boolean} - Devuelve si la propiedad existe o no
 */
const hasProp = (obj, prop) => Object.prototype.hasOwnProperty.call(obj, prop);

/**
 * Comprueba si un objeto contiene una propiedad
 *
 * @param {Object} - Objecto que queremos comprobar
 * @param {String} - Propiedad que queremos buscar
 * @return {Boolean} - Devuelve si la propiedad existe o no
 */
const hasProps = (obj, props) => {
  const errors = props.filter(prop => !Object.prototype.hasOwnProperty.call(obj, prop));
  return errors.length ? errors : true;
};

const isValidPrice = price => {
  const regex = /^[0-9]+(?:.[0-9]{1,6})?$/;
  return regex.test(price);
}


const formatIP = (value = '', previousValue = '') => {
  const isDeleting = value.length < previousValue.length;

  if(value[0] === '.')  value = value.slice(1);
  value = value.replace(/[^0-9.]/g, "");

  if(value.includes("..")) value = value.replace(/\.{2,}/g, ".")

  const octets = value.split(".");

  for(let i = 0; i < octets.length; i++){
    if(octets[i].length > 3) {
      octets[i] = octets[i].slice(0,3)
    }
  }

  value = octets.join(".")

  if(!isDeleting && value[value.length - 1] !== "." && octets.length < 4 && value.length < 15 && value.replace(/\./g, "").length % 3 === 0) {
    value += '.'
  }

  value = value.split(".").slice(0, 4).join(".");

  return value
}

/* prettier-ignore */
export {
  isString,
  isNumber,
  isArray,
  isObject,
  isNull,
  isUndefined,
  isBoolean,
  isDate,
  isEmail,
  isName,
  isZoneName,
  min,
  max,
  hasProp,
  hasProps,
  isPassword,
  isValidPrice,
  formatIP
};
