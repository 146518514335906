
let clickCount = 0;
let timer = null;

const handleClick = (binding) => {
  const { time = 5000, clicks = 8, active } = binding.value

  clickCount++;
  if (clickCount === 1) {
    timer = setTimeout(() => {
      clickCount = 0;
    }, time);
  }

  if (clickCount === clicks) {
    clearTimeout(timer);
    clickCount = 0;
    if(typeof active === 'function') active();
  }
};

const clickControl = {
  mounted(el, binding) {
    console.log('Click Control actived');

    const clickHandler = () => handleClick(binding);

    el.addEventListener('click', clickHandler);

    el._clickControlHandler = clickHandler;
  },
  unmounted(el) {
    if (el._clickControlHandler) {
      el.removeEventListener('click', el._clickControlHandler);
      delete el._clickControlHandler;
    }
  },

}

export default clickControl
