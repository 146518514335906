const state = {
  isBiometricAvailable: null,
  isBiometricAuthOpen: null,
  biometricMode: null,
  biometricType: null,
  isSkinActive: false,
  isNotificationServiceBroken: false,
}

export default state;
